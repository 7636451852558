<template>
  <div>
    <div class="bookings-summary-wrapper">
      <div class="bookings-stat-block">
        <label class="label-small"
          ><b>{{ moment(dateRange.startDate.value).format("Do MMMM YYYY") }}</b>
          to
          <b>{{
            moment(dateRange.endDate.value).format("Do MMMM YYYY")
          }}</b></label
        >
        <div class="bookings-stat-wrapper">
          <div class="bookings-stat">
            <unicon name="user"></unicon>
            <div class="booking-stat-info">
              <em>{{ totalSales }}</em>
              <label>Courses Sold</label>
            </div>
          </div>
          <div class="bookings-stat">
            <unicon name="money-insert"></unicon>
            <div class="booking-stat-info">
              <em
                ><span>{{ getCoachCurrency() }}</span
                >{{ parseInt(totalAmount).toLocaleString("en-IN") }}</em
              >
              <label>Total Earnings</label>
            </div>
          </div>
        </div>
      </div>
      <div class="date-selector">
        <FormBuilder class="date-form" :fields="dateRange"></FormBuilder>
        <button type="button" class="btn btn-border" @click="getBookings">
          Search
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FormBuilder from "@/components/form/FormBuilder";

export default {
  name: "BookingsSummary",
  props: {
    dateRange: Object,
    totalSales: Number,
    totalAmount: Number,
  },
  data() {
    return {
      showBackground: false,
      showLoader: false,
    };
  },
  components: {
    FormBuilder,
  },
  methods: {
    getBookings() {
      this.$emit("getBookings");
    },
  },
};
</script>

<style scoped lang="scss">
.date-selector {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-left: 3rem;

  .date-form /deep/ {
    display: flex;
    flex-direction: row;
    align-items: center;

    .field-main-item {
      margin-right: 1rem;
      margin-bottom: 0;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 1px;
    background-color: #cecece;
    height: 70%;
  }
}

.bookings-summary-wrapper {
  display: flex;
  flex-direction: row;
  align-items: bottom;
  margin: -1rem 0 2rem;
  background-color: $whiteColor;
  border: 1px solid darken($whiteColor, 10%);
  padding: 1rem;
  border-radius: 1rem;
}

.label-small {
  display: block;
  font-size: $smallestFontSize;
  color: $greyColor;
  margin-bottom: 1rem;

  b {
    font-weight: $mediumFontWeight;
  }
}

.bookings-stat-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.bookings-stat {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 3rem;

  em {
    display: block;
    font-size: 1.5rem;
    font-weight: $mediumFontWeight;
    color: $blackColor;
    margin-bottom: 0.5rem;

    span {
      font-size: $largeFontSize;
    }
  }

  label {
    font-size: $normalFontSize;
    font-weight: $mediumFontWeight;
    color: $greyColor;
  }

  .unicon {
    margin-right: 1rem;
    /deep/ svg {
      background: lighten($greenColor, 25%);
      fill: darken($darkGreenColor, 10%);
      width: 1rem;
      height: auto;
      padding: 0.5rem;
      border: 1px solid lighten($greenColor, 20%);
      border-radius: 0.5rem;
    }
  }
}

.payments-disclaimer {
  margin: -1rem 0 2.5rem;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  em {
    font-size: 1.25rem;
    margin-right: 0.5rem;
  }
  p {
    font-size: $smallerFontSize;
    color: $greyColor;
    flex: 1;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
    font-size: $smallestFontSize;
    font-weight: $mediumFontWeight;
    border-radius: 0.5rem;
    padding: 0.3rem 0.5rem;
    line-height: $smallestFontSize;
    background-color: lighten($yellowColor, 0%);
    color: darken($yellowColor, 45%);
  }
}

@media screen and (max-width: $mobileWidth) {
  .bookings-summary-wrapper {
    display: block;

    .bookings-stat {
      margin-right: 1rem;
    }

    .date-selector {
      display: block;
      margin-top: 2rem;
      padding-left: 0;

      .date-form {
        width: calc(100% + 1rem);
      }

      .btn {
        display: block;
        width: 100%;
        margin: 0.5rem 0 0;
      }

      &::before {
        display: none;
      }
    }
  }

  .payments-disclaimer {
    display: block;

    em,
    span {
      display: inline-block;
      vertical-align: middle;
    }
    p {
      margin-top: 0.5rem;
    }
  }
}
</style>