<template>
  <div v-if="showComponent" class="course-module">
    <div class="course-video-container">
      <!-- START: Module Header -->
      <div class="module-header">
        <div class="module-info-wrapper">
          <h1 class="module-title">{{ plan.name }}</h1>
          <p>
            <em>{{ plan.modules.length }}</em> <label>modules</label>
          </p>
        </div>
      </div>
      <!-- END: Module Header -->

      <!-- START: Video Container -->
      <div class="video-container">
        <!-- START: Modules Video Player -->
        <ModuleVideoPlayer :module="selectedModule" class="module-player">
        </ModuleVideoPlayer>
        <!-- END: Modules Video Player -->

        <!-- START: Time Left -->
        <div class="access-wrapper">
          <div
            v-if="
              plan.accessSettings != undefined &&
              plan.accessSettings.lifetimeAccess != null &&
              !plan.accessSettings.lifetimeAccess
            "
            class="limited-access"
            :class="plan.accessSettings.lockedPlan ? 'no-access' : ''"
          >
            <div v-if="plan.accessSettings.lockedPlan">
              <unicon name="lock"></unicon>
              <span>You no longer have access</span>
            </div>
            <div v-else>
              <unicon name="unlock-alt"></unicon>
              <span>Plan Access - {{ calculateAccessDays }} days left</span>
            </div>
          </div>
          <div v-else class="limited-access lifetime-access">
            <unicon name="unlock-alt"></unicon>
            <span>Lifetime Access</span>
          </div>
        </div>
        <!-- END: Time Left -->

        <!-- START: Modules List -->
        <ModulesList
          :plan="plan"
          class="modules-tree"
          @moduleSelected="showModule"
        ></ModulesList>
        <!-- END: Modules List -->
      </div>
      <!-- END: Video Container -->
    </div>

    <!-- START: Module Description -->
    <div class="module-description">
      <h3>{{ selectedModule.title }}</h3>
      <div v-html="selectedModule.description"></div>
    </div>
    <!-- END: Modulee Description -->

    <LineLoader :showLoader="showLoader"></LineLoader>
  </div>
</template>

<script>
//Import components
import LineLoader from "@/components/loaders/LineLoader";
import ModulesList from "@/components/student/Course/ModulesList";
import ModuleVideoPlayer from "@/components/student/Course/ModuleVideoPlayer";

export default {
  name: "ClientVideoCourse",
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showComponent: false,
      client: {},
      selectedModuleIndex: 0,
      selectedModule: {},
      showLoader: false,
    };
  },
  async created() {
    if ((await this.authenticateClient("/client/login", false)) == false)
      this.$router.push({
        path: "/client/login",
      });
    this.client = this.$store.state.client.client;
    this.initVideoCourse();
    this.showComponent = true;
  },

  components: {
    LineLoader,
    ModulesList,
    ModuleVideoPlayer,
  },
  computed: {
    calculateAccessDays: function () {
      let expiryDate = this.plan.createdAt;
      let daysLeft = this.plan.accessSettings.days;
      let today = this.moment();

      expiryDate = this.moment(expiryDate);
      expiryDate = expiryDate.add(daysLeft, "days");
      var daysRemaining = expiryDate.diff(today, "days");

      return daysRemaining;
    },
  },
  methods: {
    initVideoCourse() {
      this.selectedModuleIndex = 0;
      this.selectedModule = this.plan.modules[this.selectedModuleIndex];
    },

    showModule(index) {
      this.selectedModuleIndex = index;
      this.selectedModule = this.plan.modules[this.selectedModuleIndex];
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.course-module {
  padding-top: 2rem;
}
.course-video-container {
  position: relative;
  background-color: $whiteColor;
  padding: 1.75rem 2rem;
  border-radius: 1rem;
  border: 1px solid #ebe5d4;
  box-shadow: 0 0 0.7rem -0.15rem rgba(0, 0, 0, 0.15);
  margin: 0 2rem 2rem;
}

.module-header {
  margin-bottom: 1.5rem;
  p {
    text-transform: uppercase;
    font-weight: $boldFontWeight;
    letter-spacing: 0.05rem;
  }
}

.video-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .module-player {
    width: 65%;
    margin-right: 2rem;
  }

  .modules-tree {
    width: calc(35% - 2rem);
  }
}

.module-description {
  padding: 0 5rem 6rem;
  width: 70%;

  * {
    font-size: 0.95rem;
  }

  /deep/ {
    ul {
      list-style-type: disc !important;
      margin-left: 1.5rem;
    }

    ol {
      list-style-type: decimal !important;
      margin-left: 1.5rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: $mediumFontSize;
    }

    li {
      margin-bottom: 0.5rem;
    }
    strong {
      font-weight: bold;
    }
    .ql-indent-1 {
      margin-left: 1.5rem;
    }
  }

  h3 {
    font-family: $headingFont;
    font-size: $mediumFontSize;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
}
.access-wrapper {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}
.limited-access {
  display: none;
  flex-direction: row;
  align-items: center;
  background-color: $yellowColor;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;

  span {
    color: darken($yellowColor, 50%);
    font-weight: $mediumFontWeight;
  }

  .unicon /deep/ {
    svg {
      fill: darken($yellowColor, 50%);
      height: auto;
      width: 1rem;
      margin-right: 0.5rem;
    }
  }

  &.no-access {
    background-color: $redColor;

    span {
      color: lighten($redColor, 50%);
    }

    .unicon /deep/ {
      svg {
        fill: lighten($redColor, 50%);
      }
    }
  }
}

.lifetime-access {
  background-color: $darkGreenColor;

  span {
    color: darken($darkGreenColor, 50%);
  }

  .unicon /deep/ {
    svg {
      fill: darken($darkGreenColor, 50%);
    }
  }
}

//Mobile Styles
@media screen and (max-width: $mobileWidth) {
  .module-header {
    display: none;
  }
  .access-wrapper {
    position: relative;
    top: auto;
    right: auto;
    display: flex;
    text-align: center;
    margin: -1rem 0 1rem;
  }

  .course-video-container {
    margin: 0 1rem 2rem;
    padding: 1rem;
  }

  .video-container {
    display: block;

    .module-player {
      width: calc(100% + 2rem);
      margin: -1rem 0 2rem -1rem;
    }

    .modules-tree {
      width: 100%;
    }
  }

  .module-description {
    width: auto;
    padding: 0 1.5rem 4rem;
  }
}
</style>