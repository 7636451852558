require("dotenv").config();
import axios from "axios";

//Importing Variable mixins
import VariablesMixins from "../mixins/Variables";
const apiURL = VariablesMixins.APIURL;

class BookingService {
  //Service to get coach bookings
  static GetBookings(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/booking/all`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to get coach bookings
  static GetAppointments(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/booking/get-appointments`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to get coach bookings
  static CancelAppointment(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/booking/cancel-appointment`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to get add manual booking
  static AddCourseToStudent(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/booking/add-course-to-student`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to get add student to live course
  static AddLiveCourseToStudent(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/booking/live-course/add-student`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to get bulks add students to live course
  static BulkAddLiveCourseToStudent(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/booking/live-course/bulk-add-student`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }
}

export default BookingService;
