require('dotenv').config();
import axios from 'axios';

//Importing Variable mixins
import VariablesMixins from '../mixins/Variables';
const apiURL = VariablesMixins.APIURL;

class DiyPlanService {

    //Service to get coach workout templates
    static GetPlans() {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.get(`${apiURL}/diy-plans/all`, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

    //Service to get coach workout template
    static GetPlan(planID) {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.post(`${apiURL}/diy-plans/`, {
                                planID: planID
                            }, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

    //Service to get client Plan to manage
    static GetClientPlan(planID) {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.post(`${apiURL}/diy-plans/client-plan/`, {
                                planID: planID
                            }, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

    //Service to delete coach workout template
    static DeletePlan(planID) {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.post(`${apiURL}/diy-plans/delete`, {
                                planID
                            }, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

    //Service to save a new workout template
    static SavePlan(fields) {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        console.log(fields);
                        return await axios.post(`${apiURL}/diy-plans/save`, {
                                fields
                            }, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

    //Service to save a workouts
    static SaveSettings(fields) {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.post(`${apiURL}/diy-plans/settings/save`, {
                                fields
                            }, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

    //Service to save a workouts
    static SaveWorkouts(fields) {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.post(`${apiURL}/diy-plans/workouts/save`, {
                                fields
                            }, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

    //Service to save an FAQ
    static SaveFAQ(fields) {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.post(`${apiURL}/diy-plans/faqs/save`, {
                                fields
                            }, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

    //Service to save an FAQ
    static UpdateFAQs(fields) {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.post(`${apiURL}/diy-plans/faqs/update`, {
                                fields
                            }, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

    //Service to save a Guide
    static SaveGuide(formData) {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.post(`${apiURL}/diy-plans/guide/save`, formData, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

    //Service to save Guides changes
    static UpdateGuides(fields) {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.post(`${apiURL}/diy-plans/guides/update`, {
                                fields
                            }, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

    //Service to save attachments
    static SaveAttachments(formData) {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.post(`${apiURL}/diy-plans/attachments/save`, formData, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

    //Service to assign a workout template to clients
    static AssignPlan(fields) {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.post(`${apiURL}/diy-plans/assign`, {
                                fields
                            }, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                console.log(error);
                                return false;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }
}

export default DiyPlanService;