<template>
  <div class="field-wrapper">
    <div class="field-scheduler-item">
      <div class="days-wrapper">
        <!--START: All Days-->
        <ToggleButton
          class="active-days-toggle"
          :toggleField="selectAllDays"
          toggleName="active-days-toggle"
          @fieldChanged="fieldChanged"
        ></ToggleButton>
        <!--END: All Days-->

        <!--START: Same Time -->
        <div v-if="selectAllDays.value" class="same-time-wrapper">
          <!--START: Days-->
          <div class="active-days">
            <button
              class="btn btn-border active-day"
              :class="{
                active: isEnabledDay(day) && selectAllDays.value & day.isActive,
              }"
              v-for="(day, index) in overallDays.days"
              :key="index"
              @click="toggleDay(index)"
            >
              {{ day.name }}
            </button>
          </div>
          <!--END: Days-->

          <div class="overall-time-selector">
            <label class="time-selector-label">Set Available Hours</label>
            <div
              class="time-slot"
              v-for="(slot, index) in overallDays.timeSlots"
              :key="slot.id"
            >
              <FormBuilder
                class="time-slot-form"
                :fields="slot"
                @fieldChanged="fieldChanged"
                @input="fieldChanged"
              ></FormBuilder>
              <button
                type="button"
                class="btn btn-border btn-icon"
                @click="addOverallTimeSlot(index)"
              >
                Add Slot
              </button>
              <button
                v-if="overallDays.timeSlots.length > 1"
                type="button"
                class="btn btn-border btn-icon"
                @click="removeOverallTimeSlot(index)"
              >
                Remove Slot
              </button>
            </div>
          </div>
        </div>
        <!--END: Same Time -->

        <!--START: Unique Times-->
        <div v-else class="unique-times">
          <label class="time-selector-label">Set Available Hours</label>
          <div
            class="day-item"
            v-for="(day, dayIndex) in uniqueSlots"
            :key="dayIndex"
          >
            <div class="day-info">
              <label>{{ day.day }}</label>
              <ToggleButton
                class="specific-day-toggle"
                :toggleField="day.isActive"
                toggleName="specific-day-toggle"
                @fieldChanged="dayToggled(dayIndex)"
                @input="fieldChanged"
              ></ToggleButton>
            </div>
            <div class="day-slots-wrapper" v-if="day.isActive.value">
              <div
                class="time-slot"
                v-for="(slot, index) in day.timeSlots"
                :key="slot.id"
              >
                <FormBuilder
                  class="time-slot-form"
                  :fields="slot"
                  @fieldChanged="fieldChanged"
                  @input="fieldChanged"
                ></FormBuilder>
                <button
                  type="button"
                  class="btn btn-border btn-icon"
                  @click="addTimeSlot(dayIndex, index)"
                >
                  Add Slot
                </button>
                <button
                  v-if="day.timeSlots.length > 1"
                  type="button"
                  class="btn btn-border btn-icon"
                  @click="removeTimeSlot(dayIndex, index)"
                >
                  Remove Slot
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--END: Unique Times-->
      </div>
    </div>
  </div>
</template>

<script>
//Import libraries
import _ from "lodash";

//Import components
import ToggleButton from "./ToggleButton";

export default {
  name: "SchedulerField",
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      initOfferingPopulated: false,
      initComplete: false,
      overallDays: {
        days: [
          { name: "Mon", isActive: true },
          { name: "Tue", isActive: true },
          { name: "Wed", isActive: true },
          { name: "Thu", isActive: true },
          { name: "Fri", isActive: true },
          { name: "Sat", isActive: false },
          { name: "Sun", isActive: false },
        ],
        timeSlots: [],
      },
      selectAllDays: {
        type: "toggle",
        value: true,
        placeholder: "Same Time Every Day",
      },
      uniqueSlots: [
        {
          day: "Monday",
          isActive: {
            type: "toggle",
            value: true,
            placeholder: "Available",
          },
          timeSlots: [],
        },
        {
          day: "Tuesday",
          isActive: {
            type: "toggle",
            value: true,
            placeholder: "Available",
          },
          timeSlots: [],
        },
        {
          day: "Wednesday",
          isActive: {
            type: "toggle",
            value: true,
            placeholder: "Available",
          },
          timeSlots: [],
        },
        {
          day: "Thursday",
          isActive: {
            type: "toggle",
            value: true,
            placeholder: "Available",
          },
          timeSlots: [],
        },
        {
          day: "Friday",
          isActive: {
            type: "toggle",
            value: true,
            placeholder: "Available",
          },
          timeSlots: [],
        },
        {
          day: "Saturday",
          isActive: {
            type: "toggle",
            value: false,
            placeholder: "Available",
          },
          timeSlots: [],
        },
        {
          day: "Sunday",
          isActive: {
            type: "toggle",
            value: false,
            placeholder: "Available",
          },
          timeSlots: [],
        },
      ],
      timeSlotFields: {
        timeSlot: {
          type: "group",
          nestFields: false,
          fields: {
            startTime: {
              type: "dropdown",
              placeholder: "Start time",
              required: true,
              hasError: false,
              fields: [],
              value: null,
              readOnly: false,
            },
            endTime: {
              type: "dropdown",
              placeholder: "End time",
              required: true,
              hasError: false,
              fields: [],
              value: null,
              readOnly: false,
            },
          },
        },
      },
    };
  },
  watch: {
    overallDays: {
      deep: true,
      handler: function () {
        if (this.initComplete) this.fieldChanged();
      },
    },
    uniqueSlots: {
      deep: true,
      handler: function () {
        if (this.initComplete) this.fieldChanged();
      },
    },

    field: {
      deep: true,
      handler: function () {
        if (!this.initOfferingPopulated) {
          this.populateSlots();
        }
      },
    },
  },
  components: {
    FormBuilder: () => import("./FormBuilder"),
    ToggleButton,
  },
  created() {
    this.field.overallDays = this.overallDays;
    this.field.uniqueSlots = this.uniqueSlots;
    this.field.selectAllDays = this.selectAllDays;
    this.initTimeSlots();
  },
  computed: {
    activeDays: function () {
      let days = this.overallDays.days;
      if (this.field.disabledDays) {
        days = this.overallDays.days.filter(
          (day) => !this.field.disabledDays.includes(day)
        );
      }
      return days;
    },
  },
  methods: {
    populateSlots() {
      //Extract existing offering data
      this.selectAllDays.value = this.field.value.hasFixedDays;
      let offeringTimeSlots = this.field.value.days;
      let daysSlots = offeringTimeSlots[0].timeSlots;

      //START: Create the temporary time slot
      const timeSlot = this.createTimeSlot();
      this.timeSlotFields.timeSlot.fields.startTime.fields = timeSlot;
      this.timeSlotFields.timeSlot.fields.endTime.fields = timeSlot;

      let timeSlotData = this.timeSlotFields;
      //END: Create the temporary time slot

      let tempTimeSlot;
      //Create fixed days slots
      if (this.selectAllDays.value) {
        this.overallDays.timeSlots = [];

        for (let i = 0; i < daysSlots.length; i++) {
          tempTimeSlot = _.cloneDeep(timeSlotData);
          tempTimeSlot.timeSlot.fields.startTime.value = [
            daysSlots[i].startTime,
            daysSlots[i].startTime,
          ];
          tempTimeSlot.timeSlot.fields.endTime.value = [
            daysSlots[i].endTime,
            daysSlots[i].endTime,
          ];

          this.overallDays.timeSlots.push(_.cloneDeep(tempTimeSlot));
        }
      }

      //Create unique days slots
      else {
        for (let i = 0; i < this.uniqueSlots.length; i++) {
          this.uniqueSlots[i].timeSlots = [];

          this.uniqueSlots[i].isActive.value = offeringTimeSlots[i].isActive;

          //Populate the unique days time slots
          for (let j = 0; j < offeringTimeSlots[i].timeSlots.length; j++) {
            tempTimeSlot = _.cloneDeep(timeSlotData);
            tempTimeSlot.timeSlot.fields.startTime.value = [
              offeringTimeSlots[i].timeSlots[j].startTime,
              offeringTimeSlots[i].timeSlots[j].startTime,
            ];
            tempTimeSlot.timeSlot.fields.endTime.value = [
              offeringTimeSlots[i].timeSlots[j].endTime,
              offeringTimeSlots[i].timeSlots[j].endTime,
            ];

            this.uniqueSlots[i].timeSlots.push(_.cloneDeep(tempTimeSlot));
          }
        }
      }
    },

    initTimeSlots() {
      const timeSlot = this.createTimeSlot();
      this.timeSlotFields.timeSlot.fields.startTime.fields = timeSlot;
      this.timeSlotFields.timeSlot.fields.endTime.fields = timeSlot;

      let timeSlotData = this.timeSlotFields;

      let tempTimeSlot;
      this.uniqueSlots.forEach(function (day) {
        tempTimeSlot = _.cloneDeep(timeSlotData);

        if (!day.isActive.value) {
          tempTimeSlot.timeSlot.fields.startTime.required = false;
          tempTimeSlot.timeSlot.fields.endTime.required = false;
        }
        day.timeSlots.push(_.cloneDeep(tempTimeSlot));
      });

      this.overallDays.timeSlots.push(_.cloneDeep(this.timeSlotFields));

      setTimeout(() => (this.initComplete = true), 0);
    },

    createTimeSlot() {
      var hours, minutes, ampm;
      var time = [];
      for (var i = 0; i <= 1440; i += 15) {
        hours = Math.floor(i / 60);
        minutes = i % 60;
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        ampm = hours % 24 < 12 ? "AM" : "PM";
        hours = hours % 12;
        if (hours === 0) {
          hours = 12;
        }

        time.push({
          name: hours + ":" + minutes + " " + ampm,
          value: hours + ":" + minutes + " " + ampm,
        });
      }

      return time;
    },

    isEnabledDay(day) {
      let isEnabledDay = true;
      if (
        this.field.disabledDays &&
        this.field.disabledDays.some((res) => res.includes(day))
      ) {
        isEnabledDay = false;
      }

      return isEnabledDay;
    },

    dayToggled(index) {
      const isActive = this.uniqueSlots[index].isActive.value;
      this.uniqueSlots[index].timeSlots.forEach(function (day) {
        day.timeSlot.fields.startTime.required = isActive;
        day.timeSlot.fields.endTime.required = isActive;
      });
      this.fieldChanged();
    },

    addOverallTimeSlot(index) {
      this.overallDays.timeSlots.splice(
        index + 1,
        0,
        _.cloneDeep(this.timeSlotFields)
      );
      this.fieldChanged();
    },

    removeOverallTimeSlot(index) {
      this.overallDays.timeSlots.splice(index, 1);
      this.fieldChanged();
    },

    addTimeSlot(dayIndex, index) {
      this.uniqueSlots[dayIndex].timeSlots.splice(
        index + 1,
        0,
        _.cloneDeep(this.timeSlotFields)
      );
      this.fieldChanged();
    },

    removeTimeSlot(dayIndex, index) {
      this.uniqueSlots[dayIndex].timeSlots.splice(index, 1);
      this.fieldChanged();
    },

    toggleDay(index) {
      this.overallDays.days[index].isActive =
        !this.overallDays.days[index].isActive;
      this.fieldChanged();
    },

    fieldChanged() {
      this.field.overallDays = this.overallDays;
      this.field.uniqueSlots = this.uniqueSlots;
      this.field.selectAllDays = this.selectAllDays;

      if (this.initOfferingPopulated) {
        this.$emit("fieldChanged");
      } else this.initOfferingPopulated = true;
    },
  },
};
</script>

<style scoped lang="scss">
.active-days-toggle.field-toggle-item {
  flex-direction: row !important;

  /deep/ {
    label {
      margin-left: 0.75rem !important;
    }
  }
}

.active-days {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;

  .active-day {
    margin-right: 0.5rem;
    padding: 0.5rem;
    &.active {
      border-color: lighten($purpleColor, 15%);
      background-color: $purpleColor !important;
      color: $whiteColor !important;
    }
  }
}

input[readonly] {
  cursor: default;
  border: 1px solid #e7e6ef;
}

.time-selector-label {
  display: block;
  font-size: $normalFontSize;
  font-weight: $mediumFontWeight;
  opacity: 0.75;
  margin: 2rem 0 1rem;
}

.time-slot {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;

  label {
    width: 4rem;
  }

  .btn {
    margin-left: 0.5rem;
  }

  /deep/ {
    .time-slot-form {
      width: 20rem;
      margin: 0 1rem 0 0;

      .field-main-item {
        margin-bottom: 0;
      }
    }
  }
}

.day-item {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .day-info {
    margin-right: 5rem;
  }

  .specific-day-toggle {
    flex-direction: row !important;
    margin: 0.5rem 0 0 !important;

    /deep/ {
      .field-toggle-button {
        transform: scale(0.8) !important;
        transform-origin: center left !important;
      }

      label {
        font-size: $smallFontSize !important;
      }
    }
  }
}

//Mobile Styles
@media screen and (max-width: $mobileWidth) {
  .active-days {
    .btn {
      font-size: 0;

      &:first-letter {
        font-size: 1rem;
      }
    }
  }

  .day-item {
    display: block;

    .day-info {
      margin-bottom: 1rem;
    }
  }

  .time-slot {
    display: block;

    .time-slot-form {
      width: auto;
      margin-bottom: -1rem;
    }

    .btn {
      display: inline-block;
      margin: 0 .5rem 1.5rem 0;
    }
  }
}
</style>