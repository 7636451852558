require('dotenv').config();
import axios from 'axios';

//Importing Variable mixins
import VariablesMixins from '../mixins/Variables';
const apiURL = VariablesMixins.APIURL;

class OnlineCourseService {

    //Service to get coach workout templates
    static GetCourses() {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.get(`${apiURL}/online-course/all`, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

    //Service to get coach workout template
    static GetCourse(courseID) {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.post(`${apiURL}/online-course/`, {
                                courseID: courseID
                            }, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

    //Service to delete coach workout template
    static DeleteCourse(courseID) {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.post(`${apiURL}/online-course/delete`, {
                                courseID
                            }, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

    //Service to save a workouts
    static SaveSettings(fields) {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.post(`${apiURL}/online-course/settings/save`, {
                                fields
                            }, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

    //Service to save a workouts
    static SaveModules(formData) {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.post(`${apiURL}/online-course/modules/save`, formData, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

    //Service to save an FAQ
    static SaveFAQ(fields) {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.post(`${apiURL}/online-course/faqs/save`, {
                                fields
                            }, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

    //Service to save an FAQ
    static UpdateFAQs(fields) {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.post(`${apiURL}/online-course/faqs/update`, {
                                fields
                            }, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

    //Service to save a Guide
    static SaveGuide(formData) {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.post(`${apiURL}/online-course/guides/save`, formData, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

    //Service to save Guides changes
    static UpdateGuides(fields) {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.post(`${apiURL}/online-course/guides/update`, {
                                fields
                            }, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

    //Service to save attachments
    static SaveAttachments(formData) {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.post(`${apiURL}/online-course/attachments/save`, formData, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

    //Service to assign a workout template to clients
    static AssignPlan(fields) {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.post(`${apiURL}/online-course/assign`, {
                                fields
                            }, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                console.log(error);
                                return false;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }

}

export default OnlineCourseService;