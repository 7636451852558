<template>
  <div class="analytics-wrapper">
    <!--START: Module Header-->
    <div class="header-wrapper">
      <div class="module-info-wrapper">
        <h1 class="module-title">{{ moduleOptions.title }}</h1>
        <p class="module-description">
          {{ moduleOptions.description + " " }}<b>{{ selectedDateRange }}</b>
        </p>
      </div>

      <!--START: Coach Slug-->
      <div class="coach-slug-wrapper">
        <div class="coach-slug-block">
          <DateSelector @dateRangeUpdated="dateRangeUpdated"></DateSelector>
          <button class="btn btn-primary" @click="getAnalytics">
            Get Insights
          </button>
        </div>
      </div>
      <!--END: Coach Slug-->
    </div>
    <!--END: Module Header-->

    <!--START: Profile + Insights -->
    <div class="hero-wrapper">
      <!--START: Total Insights-->
      <InsightsTotal
        :showLoader="showLoader"
        :insights="insights"
      ></InsightsTotal>
      <!--END: Total Insights-->
    </div>
    <!--END: Profile + Insights -->

    <!--START: REPORTS-->
    <div v-if="showComponent" class="reports-wrapper">
      <div class="chart-wrapper">
        <!--START: Transactions -->
        <div class="chart-block">
          <h2 class="title">Transactions</h2>
          <GChart
            type="ColumnChart"
            :data="transactionsChart.data"
            :options="transactionsChart.options"
          />
        </div>
        <!--START: Transactions -->

        <!--START: Amount -->
        <div class="chart-block">
          <h2 class="title">Earnings</h2>
          <GChart
            type="BarChart"
            :data="amountChart.data"
            :options="amountChart.options"
          />
        </div>
        <!--START: Amount -->
      </div>

      <!--START: Offering Types -->
      <div class="chart-block">
        <h2 class="title">Sales By Courses</h2>
        <GChart
          type="LineChart"
          :data="offeringsChart.data"
          :options="offeringsChart.options"
        />
      </div>
      <!--START: Offering Types -->
    </div>
    <!--END: REPORTS-->

    <div class="table-wrapper">
      <div class="table-toggle-wrapper">
        <span
          class="title"
          :class="{ active: !showTransactions }"
          @click="showTransactions = false"
          >Courses Insights</span
        >
        <span
          class="title"
          :class="{ active: showTransactions }"
          @click="showTransactions = true"
          >Transactions</span
        >
      </div>
      <OfferingsTable
        v-if="offerings.length && !showTransactions"
        :offerings="offerings"
      ></OfferingsTable>

      <BookingsTable
        v-else-if="bookings.length"
        :bookings="bookings"
        :totalBookings="insights.transactions"
        :bookingsPerPage="itemsPerPage"
        :currentPage="pageNumber"
        @getBookings="getAnalytics"
      >
      </BookingsTable>
    </div>

    <!--START: Status Message-->
    <StatusMessage
      :show="status.show"
      :isSuccess="status.isSuccess"
      :successMessage="status.successMessage"
      :errorMessage="status.errorMessage"
    ></StatusMessage>
    <!--END: Status Message-->

    <!--START: Page Loader -->
    <LineLoader class="line-loader" :showLoader="showLoader"></LineLoader>
    <!--END: Page Loader -->

    <div
      class="bg-overlay"
      :class="{ show: showBackground }"
      @click="hideBackground()"
    ></div>
  </div>
</template>

<script>
//Import libraries
import { GChart } from "vue-google-charts/legacy";

//Import components
import LineLoader from "@/components/loaders/LineLoader";
import DateSelector from "@/components/user/analytics/DateSelector";
import InsightsTotal from "@/components/user/analytics/InsightsTotal";
import BookingsTable from "@/components/user/analytics/BookingsTable";
import OfferingsTable from "@/components/user/analytics/OfferingsTable";
import StatusMessage from "@/components/modals/StatusMessage";

export default {
  name: "Coach",
  data() {
    return {
      showComponent: false,
      showBackground: false,
      showLoader: false,
      showTransactions: false,
      fromDate: new Date().setDate(new Date().getDate() - 30),
      toDate: new Date(),
      selectedDateRange: "the last month",
      pageNumber: 1,
      itemsPerPage: 10,
      moduleOptions: {
        title: "Overview Dashboard",
        description: `Your analytics and insights for `,
      },

      insights: {
        transactions: 0,
        amount: 0,
        commission: 0,
        eBooks: 0,
        videoCourses: 0,
        onlinePlans: 0,
      },
      bookings: [],
      offerings: [],
      reports: [],

      amountChart: {
        data: [["Month", "Earnings", { role: "style" }]],
        options: {
          vAxis: { format: "currency" },
          height: "300",
          legend: { position: "none" },
          backgroundColor: { fill: "transparent" },
        },
      },
      transactionsChart: {
        data: [["Month", "Transactions", { role: "style" }]],
        options: {
          height: "300",
          legend: { position: "none" },
          backgroundColor: { fill: "transparent" },
        },
      },

      offeringsChart: {
        data: [["Month", "Fitness Courses", "E-Books", "Video Courses"]],
        options: {
          height: "300",
          backgroundColor: { fill: "transparent" },
        },
      },

      status: {
        show: false,
        isSuccess: false,
        successMessage: "🙌 Changes saved",
        errorMessage: "😕 Something's not right. Try again",
      },
    };
  },
  props: {
    coach: {
      type: Object,
      required: true,
    },
  },
  components: {
    LineLoader,
    InsightsTotal,
    BookingsTable,
    DateSelector,
    StatusMessage,
    GChart,
    OfferingsTable,
  },
  async created() {
    await this.getAnalytics();
  },
  computed: {},
  methods: {
    dateRangeUpdated(fromDate, toDate) {
      this.fromDate = fromDate;
      this.toDate = toDate;
      this.selectedDateRange = `${this.moment(this.fromDate).format(
        "Do MMMM YYYY"
      )} to ${this.moment(this.toDate).format("Do MMMM YYYY")}`;
      this.getAnalytics();
    },

    async getAnalytics() {
      this.showLoader = true;
      this.status.show = false;

      this.showComponent = true;

      setTimeout(() => (this.status.show = false), 3000);
      this.showLoader = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.analytics-wrapper {
  margin: 0 1rem;
}
.line-loader {
  position: fixed;
}

.hero-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 1rem 0 -3rem;

  .coach-profile-component {
    width: 50rem;
    margin-right: 5rem;
  }
}

.title {
  opacity: 0.75;
  font-size: $mediumFontSize;
  font-weight: $mediumFontWeight;
  margin: 2rem 0 1rem;
}

.dashboard-wrapper {
  margin-top: 4rem;
  padding: 2.5rem 10%;
}

.coach-slug-wrapper {
  .label-small {
    margin: 0 0 1rem;
  }

  .coach-slug-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
  }

  /deep/ .date-selector-main {
    .label-small {
      display: none;
    }
  }

  .title {
    margin: 0 0 0.5rem;
  }

  .coach-form {
    /deep/ {
      .field-main-item {
        margin-bottom: 0;
        margin-right: 1rem;
      }
    }
  }

  .btn {
    margin-left: 1rem;
  }
}

.header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 3rem;

  .module-info-wrapper {
    flex: 1;
  }

  .module-header {
    flex: 1;
    margin-bottom: 0;

    .module-title {
      font-size: $extraLargeFontSize;
    }
  }
}

.status-message {
  position: fixed;
  top: 6em;
  left: 50%;
  transform: translateX(-50%);
}

.table-wrapper {
  position: relative;
  margin: 5rem 0;

  .table-toggle-wrapper {
    position: absolute;
    top: -1rem;
    right: 0;
    padding: 0.5rem 0.75rem;
    border-radius: 0.75rem;
    border: 1px solid #dcd9e6;
    background-color: #fafaff;
    width: max-content;
    z-index: 5;

    .title {
      display: inline-block;
      opacity: 0.75;
      font-size: $smallFontSize;
      font-weight: $mediumFontWeight;
      padding: 0.5rem 0.5rem;
      border-radius: 0.5rem;
      margin: 0;
      cursor: pointer;

      &.active {
        opacity: 1;
        background-color: $purpleColor;
        color: $whiteColor;
      }
    }
  }
}
</style>