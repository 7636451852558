<template>
  <div v-if="students != undefined && students.length != 0">
    <ul class="grid-table">
      <li class="grid-header">
        <div class="grid-item">Name</div>
        <div class="grid-item">Email</div>
        <div class="grid-item">Phone</div>
        <div class="grid-item">Courses</div>
      </li>
      <li
        v-for="student in sortStudentsByName()"
        class="grid-row"
        :key="student.id"
      >
        <div class="grid-item">
          <h3>{{ student.name }}</h3>
        </div>
        <div class="grid-item customer-details">
          <a :href="`mailto:${student.email}`"> {{ student.email }}</a>
        </div>
        <div class="grid-item customer-details">
          <a :href="`tel:${student.phone}`" class="customer-phone">{{
            student.phone
          }}</a>
        </div>
        <div class="grid-item bookings-count">
          <button
            class="btn btn-border btn-small"
            @click="showDeleteModal(student._id, student.name)"
          >
            Remove
          </button>
        </div>
      </li>
    </ul>

    <span class="table-count">Showing {{ students.length }} students</span>

    <ConfirmationModal
      :fields="deleteFields"
      @primaryEvent="deleteStudent"
      @secondaryEvent="closeModal"
    ></ConfirmationModal>
    <LineLoader :showLoader="showLoader"></LineLoader>
  </div>
</template>
  
  <script>
// Import libraries
import _ from "lodash";
import LiveCourseService from "@/controllers/LiveCourseService";

// Importing Components
import ConfirmationModal from "@/components/modals/ConfirmationModal";
import LineLoader from "@/components/loaders/LineLoader";

export default {
  name: "StudentsTable",
  props: {
    courseID: { type: String },
    students: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showBackground: false,
      showLoader: false,
      deleteCourseID: null,
      deleteFields: {
        show: false,
        title: null,
        type: "delete",
        message: "You can't undo this action",
        primaryBtn: {
          title: "Yes. Delete",
        },
        secondaryBtn: {
          title: "Cancel",
        },
      },
    };
  },
  components: {
    ConfirmationModal,
    LineLoader,
  },
  methods: {
    sortStudentsByName() {
      return _.orderBy(this.students, "name", "asc");
    },

    showDeleteModal(studentID, studentName) {
      this.$parent.showBackground = true;
      this.deleteID = studentID;
      this.deleteFields.show = true;
      this.deleteFields.title = `Remove access for ${studentName}?`;
    },

    async deleteStudent() {
      this.showLoader = true;
      const data = { studentID: this.deleteID, courseID: this.courseID };
      await LiveCourseService.RemoveStudent(data);

      this.$parent.students.splice(
        this.students.findIndex(({ _id }) => _id == this.deleteID),
        1
      );
      this.showLoader = false;
      this.closeModal();
    },

    closeModal() {
      this.deleteFields.show = false;
      this.$parent.showBackground = false;
    },
  },
};
</script>
  
  <style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.grid-item {
  align-self: center;
  width: 27.5%;

  &:nth-child(4) {
    width: 17.5%;
    text-align: right;
  }
}

.grid-row {
  h3 {
    font-size: $normalFontSize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  a {
    color: $blueColor;
    font-size: $smallFontSize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    border-bottom: none;
  }

  .customer-phone {
    color: $greyColor;
  }

  /deep/ .unicon svg {
    fill: $greyColor;
    height: auto;
    width: $smallerFontSize;
    vertical-align: middle;
    margin-right: 0.3rem;
  }

  .bookings-count {
    flex: 1;
    text-align: right;

    span {
      font-size: $smallFontSize;
      font-weight: $mediumFontWeight;
      color: $buttonColor;
      margin-right: 1.5rem;
    }
  }
}

.booking-order {
  text-align: left;
  width: 3.5rem;
  span {
    font-size: $smallerFontSize;
  }
}

.booking-client h3 {
  font-size: $normalFontSize;
  margin-bottom: 0;
}

.booking-date,
.booking-client span {
  font-size: $smallerFontSize;
  border-bottom: none;
  color: $greyColor;
}

.booking-client-email a {
  font-size: $smallerFontSize;
  border-bottom: none;
}

.booking-plan {
  width: calc(100% - 45.5rem);

  p {
    font-size: $smallFontSize;
    color: $blackColor;
    font-weight: $mediumFontWeight;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.plan-payment {
  width: 10rem;
  text-align: right;
}

.plan-type {
  > div {
    text-align: center;
  }

  span {
    display: block;
    margin-bottom: 0.5rem;
    font-size: $smallestFontSize;
    font-weight: $mediumFontWeight;
    color: $greyColor;
  }
}

.booking-date {
  width: 12rem;
}

.booking-phone {
  color: $greyColor;
  font-size: $smallerFontSize;
}

.plan-price {
  font-weight: $mediumFontWeight;
  font-size: $smallerFontSize;
  width: 10rem;
}

.payment-date span {
  color: $blackColor;
  opacity: $mediumOpacity;
}

.payment-status {
  display: inline-block;
  vertical-align: middle;
  font-size: $smallestFontSize;
  font-weight: $mediumFontWeight;
  border-radius: 0.5rem;
  padding: 0.3rem 0.5rem;
  line-height: $smallestFontSize;

  span {
    text-transform: capitalize;
    font-size: $smallestFontSize;
    display: inline-block;
    vertical-align: middle;
  }

  &.processed {
    background-color: lighten($darkGreenColor, 0%);

    span {
      color: darken($greenColor, 50%);
    }
  }

  &.processing {
    background-color: lighten($yellowColor, 0%);

    span {
      color: darken($yellowColor, 45%);
    }
  }

  &.not-applicable {
    background-color: #d5d5d5;

    span {
      color: #4c4c4c;
    }
  }
}

.payment-amount {
  color: $blackColor;
  opacity: $mediumOpacity;
  font-size: $smallFontSize;

  span {
    font-size: $smallestFontSize;
    margin-right: 2px;
  }
}

.renewal-date {
  font-size: $smallerFontSize;
  color: $greyColor;
  line-height: $smallestFontSize;

  .renewal-date-expired {
    font-size: $smallestFontSize;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    line-height: $smallestFontSize;
    background-color: lighten($redColor, 0%);
    color: lighten($redColor, 45%);
  }
}

.client-actions {
  text-align: right;

  .btn-border {
    margin-right: 0.5rem;

    .unicon {
      line-height: 0rem !important;
    }
  }
}

.btn-delete-client {
  @include transition;

  &:hover {
    background-color: lighten($redColor, 30%);

    /deep/ .unicon svg {
      fill: darken($redColor, 10%);
    }
  }
}

.table-count {
  display: block;
  font-size: $smallestFontSize;
  opacity: $mediumOpacity;
  color: $greyColor;
  margin: 1.5rem 0.5rem 1rem;
}

@media screen and (max-width: $mobileWidth) {
  .grid-header {
    display: none;
  }

  .grid-table {
    border-radius: 0;
  }

  .grid-table {
    border: none;
  }

  .grid-row {
    position: relative;
    text-align: left;
    border: 1px solid $greyBorderColor !important;
    margin-bottom: 1rem;
    border-radius: 1rem;
    display: block;

    &:hover {
      border: 1px solid transparent;
      background-color: $whiteColor;
    }

    .customer-info-wrapper {
      display: block;
      padding: 0;

      h3 {
        font-size: $mediumFontSize;
        width: 100%;
      }

      .customer-details {
        width: 100%;
      }

      a {
        display: block;
        margin-top: 0.5rem;
        margin-bottom: 0.25rem;
      }
    }

    &.show {
      .bookings-table {
        border-radius: 0;
        border: none;
        margin-left: -0.95rem;
        width: calc(100% + 1.9rem);

        .grid-row {
          border-radius: 0;
          margin-bottom: 0;
          border: none !important;
          border-bottom: 1px solid #d8d9e5 !important;
          padding: 0.75rem 1.25rem 2rem;
        }

        .plan-type > div {
          text-align: right;

          span {
            margin-bottom: 0;
          }

          .btn {
            display: none;
          }
        }
      }
    }
  }

  .booking-order {
    width: auto;
    margin-bottom: 0.5rem;
  }

  .booking-client-email {
    display: block;
    text-align: left;
    width: auto;
    margin-bottom: 1rem;
  }

  .booking-date {
    text-align: left;
  }

  .booking-client {
    margin: 0.5rem 0;
    width: 100%;
    text-align: left;

    h3 {
      font-size: 1rem;
    }
  }

  .booking-plan {
    text-align: left;
    width: 100% !important;
    margin-bottom: 0.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid lighten($greyBorderColor, 5%);

    p {
      color: $blackColor;
      font-size: $normalFontSize;
    }
  }

  .plan-price {
    position: absolute;
    top: 0.75rem;
    right: 1.25rem;
    width: auto !important;
    font-size: 1.1rem;
  }

  .plan-payment {
    position: absolute;
    right: 0.95rem;
    top: 2.25rem;
    width: auto !important;
    display: inline-block;
    vertical-align: middle;
    text-align: left !important;

    .payment-status {
      margin-top: 3px;
      border-radius: 0.5rem;
      padding: 0.25rem 0.75rem 0.25rem 0.5rem;

      .unicon /deep/ svg {
        display: inline-block;
        vertical-align: middle;
      }

      span {
        display: inline-block;
      }
    }

    .btn {
      float: right;
    }
  }

  .client-actions {
    width: 2rem;
    display: inline-block;
    vertical-align: middle;
  }
}
</style>