<template>
  <div>
    <div
      class="field-main-item"
      :class="field.additionalClass != undefined ? field.additionalClass : ''"
      v-for="(field, name) in fields"
      :key="field.id"
    >
      <!-- START: Group Title -->
      <label class="field-label" v-if="field.groupTitle != undefined">{{
        field.groupTitle
      }}</label>
      <!-- END: Group Title -->

      <!-- START: Field Title -->
      <label class="field-title" v-if="field.title != undefined">{{
        field.title
      }}</label>
      <!-- END: Field Title -->

      <!-- START: Radio Buttons -->
      <RadioButton
        v-if="field.type == 'radio'"
        :radioField="field"
        class="field-wrapper"
        @change="fieldChanged()"
      ></RadioButton>
      <!-- END: Radio Buttons -->

      <!-- START: Radio Buttons -->
      <ToggleButton
        v-else-if="field.type == 'toggle'"
        :toggleField="field"
        :toggleName="name"
        @fieldChanged="fieldChanged()"
      ></ToggleButton>
      <!-- END: Radio Buttons -->

      <div
        v-else-if="field.type == 'date'"
        class="field-datepicker-wrapper field-wrapper"
      >
        <Datepicker
          :type="field.type"
          :name="field.name"
          :placeholder="field.placeholder"
          :required="field.required"
          :monday-first="true"
          :disabled-dates="
            field.disabledDates != undefined ? field.disabledDates : {}
          "
          :value="field.value"
          :class="[
            field.class != undefined ? field.class : '',
            { error: field.hasError },
          ]"
          @focus="field.hasError = false"
          @selected="fieldChanged(true)"
          v-model="field.value"
        ></Datepicker>
        <span
          v-if="field.value != null && field.value != undefined"
          class="datepicker-remove-date"
          @click="clearDate(field)"
          >Remove</span
        >
      </div>

      <!-- START: File Upload Fields -->
      <FileUploadLarge
        v-else-if="field.type == 'file-large'"
        :field="field"
        @change="fieldChanged()"
        @fileUploaded="fileUploaded"
      ></FileUploadLarge>
      <!-- End: File Upload Fields -->

      <!-- START: Group Fields -->
      <GroupFormFields
        v-else-if="field.type == 'group'"
        class="field-wrapper field-group-wrapper"
        :fields="field.fields"
        @fieldChanged="fieldChanged()"
      ></GroupFormFields>
      <!-- END: Group Fields -->

      <!-- START: Money Input -->
      <MoneyField
        v-else-if="field.type == 'money'"
        :field="field"
        @fieldChanged="fieldChanged()"
      ></MoneyField>
      <!-- END: Money Input -->

      <!-- START: Phone Number Input -->
      <TelephoneField
        v-else-if="field.type == 'tel'"
        :field="field"
        @fieldChanged="fieldChanged()"
      ></TelephoneField>
      <!-- END: Phone Number Input -->

      <!-- START: Phone Number Input -->
      <CountryTelephoneField
        v-else-if="field.type == 'country-tel'"
        :field="field"
        @fieldChanged="fieldChanged()"
      ></CountryTelephoneField>
      <!-- END: Phone Number Input -->

      <!-- START: Multiple Select Dropdown Input -->
      <Dropdown
        v-else-if="field.type == 'dropdown'"
        :field="field"
        :class="{ error: field.hasError }"
        @fieldChanged="fieldChanged()"
      ></Dropdown>
      <!-- START: Multiple Select Dropdown Input -->

      <!-- START: Multiple Select Dropdown Input -->
      <MultipleSelectDropdown
        v-else-if="field.type == 'multiple-select-dropdown'"
        :field="field"
        :class="{ error: field.hasError }"
        @change="fieldChanged()"
      ></MultipleSelectDropdown>
      <!-- START: Multiple Select Dropdown Input -->

      <!-- START: Icon Selector -->
      <IconSelector
        v-else-if="field.type == 'icon-selector'"
        :field="field"
        :class="{ error: field.hasError }"
        @fieldChanged="fieldChanged()"
      ></IconSelector>
      <!-- START:  Icon Selector -->

      <!-- START: Textarea -->
      <div v-else-if="field.type == 'textarea'" class="field-wrapper">
        <textarea
          rows="4"
          :name="field.name"
          :placeholder="field.placeholder"
          :required="field.required"
          :readonly="field.readOnly != undefined ? field.readOnly : false"
          :class="{ error: field.hasError }"
          @focus="field.hasError = false"
          @input="fieldChanged()"
          v-model="field.value"
        ></textarea>
      </div>
      <!-- END: Textarea -->

      <!-- START: Text Editor -->
      <div v-else-if="field.type == 'text-editor'" class="field-wrapper">
        <vue-editor
          v-model="field.value"
          class="text-editor"
          :name="field.name"
          :editorToolbar="
            field.showMiniToolbar == true ? editorToolbar : undefined
          "
          :class="{ error: field.hasError }"
          @focus="field.hasError = false"
          @text-change="editorTextChange(field.value)"
          :placeholder="field.placeholder"
          :required="field.required"
        ></vue-editor>
      </div>
      <!-- END: Text Editor -->

      <!-- START: Scheduler -->
      <div v-else-if="field.type == 'scheduler'" class="field-wrapper">
        <Scheduler :field="field" @fieldChanged="fieldChanged()"></Scheduler>
      </div>
      <!-- END: Scheduler -->

      <!-- START: Input Fields -->
      <div v-else class="field-wrapper">
        <input
          :type="field.type"
          :name="field.name"
          :placeholder="field.placeholder"
          :min="field.min"
          :max="field.max"
          :required="field.required"
          :value="field.id"
          :readonly="field.readOnly != undefined ? field.readOnly : false"
          :class="{ error: field.hasError }"
          @focus="field.hasError = false"
          @input="fieldChanged()"
          v-model="field.value"
        />
      </div>
      <!-- END: Input Fields -->
    </div>
  </div>
</template>

<script>
import RadioButton from "./RadioButton";
import ToggleButton from "./ToggleButton";
import MoneyField from "./MoneyField";
import TelephoneField from "./TelephoneField";
import CountryTelephoneField from "./CountryTelephoneField";
import FileUploadLarge from "./FileUploadLarge";
import Dropdown from "./Dropdown";
import Scheduler from "./Scheduler";
import MultipleSelectDropdown from "./MultipleSelectDropdown";
import Datepicker from "vuejs-datepicker";
import { VueEditor } from "vue2-editor";
import IconSelector from "./IconSelector";

export default {
  name: "FormBuilder",
  props: {
    fields: {
      type: Object,
      required: true,
    },
    statusMessageHandler: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      initContent: null,
      editorToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
      ],
      fileUploadID: 0,
    };
  },
  components: {
    GroupFormFields: () => import("./FormBuilder.vue"),
    RadioButton,
    ToggleButton,
    MoneyField,
    TelephoneField,
    CountryTelephoneField,
    FileUploadLarge,
    Dropdown,
    MultipleSelectDropdown,
    Datepicker,
    VueEditor,
    Scheduler,
    IconSelector,
  },
  methods: {
    editorTextChange(value) {
      if (this.initContent == null) this.initContent = value;
      else if (this.initContent != value) this.fieldChanged();
    },

    clearDate(field) {
      field.value = null;
      this.fieldChanged(true);
    },

    fieldChanged(propogateToParent) {
      if (propogateToParent) this.$parent.$emit("fieldChanged");
      else this.$emit("fieldChanged");
    },

    fileUploaded(e) {
      this.$emit("fileUploaded", e);
      this.$emit("fieldChanged");
    },
    fileUploadEleID() {
      this.fileUploadID++;
      console.log(`assetsFieldHandle-${this.fileUploadID}`);
      return `assetsFieldHandle-${this.fileUploadID}`;
    },
  },
};
</script>