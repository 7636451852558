require("dotenv").config();
import axios from "axios";

// Importing Variable mixins
import VariablesMixins from "../mixins/Variables";
const apiURL = VariablesMixins.APIURL;

class StudentService {
  // Service to get student
  static GetStudent() {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .get(`${apiURL}/students/`, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Service to delete student
  static DeleteStudent(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/students/delete`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Service to get courses
  static GetCourses() {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .get(`${apiURL}/students/courses`, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Service to get video course
  static GetVideoCourse(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/students/video-course`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Service to get live course
  static GetLiveCourse(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/students/live-course`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }
}

export default StudentService;
