<template>
  <div class="modal" :class="{ show: fields.show }">
    <div class="success-form">
      <div
        class="form-icon-wrapper"
        :class="{
          'delete-icon': fields.type != undefined && fields.type == 'delete',
        }"
      >
        <unicon class="check" name="check"></unicon>
        <unicon class="delete" name="times"></unicon>
      </div>
      <div class="success-info-wrapper">
        <h3>{{ fields.title }}</h3>
        <p>{{ fields.message }}</p>
        <div class="success-btn-wrapper">
          <button
            type="button"
            v-if="fields.primaryBtn != undefined"
            class="btn btn-primary"
            :class="{
              'btn-delete': fields.type != undefined && fields.type == 'delete',
            }"
            @click="primaryEventHandler"
          >
            {{ fields.primaryBtn.title }}
          </button>
          <button
            type="button"
            v-if="fields.secondaryBtn != undefined"
            class="btn btn-border"
            @click="secondaryEventHandler"
          >
            {{ fields.secondaryBtn.title }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmationModal",
  props: {
    fields: {
      type: Object,
      required: true,
    },
  },
  methods: {
    primaryEventHandler() {
      this.$emit("primaryEvent");
    },
    secondaryEventHandler() {
      this.$emit("secondaryEvent");
    },
  },
};
</script>

<style scoped lang="scss">
.success-form {
  display: flex;
  flex-direction: row;
}

.success-form .unicon {
  align-items: center;
}

.success-form .unicon {
  /deep/ svg {
    display: block;
    padding: 0.75rem;
    border-radius: 50%;
  }
  .check {
    display: block;
  }

  .delete {
    display: none;
  }

  &.check /deep/ svg {
    fill: darken($greenColor, 40%);
    background-color: lighten($greenColor, 15%);
    border: 10px solid lighten($greenColor, 25%);
  }

  &.delete /deep/ svg {
    fill: darken($redColor, 30%);
    background-color: lighten($redColor, 15%);
    border: 10px solid lighten($redColor, 25%);
  }
}

.delete-icon {
  .check {
    display: none;
  }

  .delete {
    display: block;
  }
}

.success-info-wrapper {
  width: 70%;
  margin-left: 2rem;
  h3 {
    font-size: $largeFontSize;
  }
  p {
    color: $greyColor;
  }
}

.success-btn-wrapper {
  margin: 1rem 0 0;
  .btn {
    margin-right: 0.75rem;
  }
}

@media only screen and (max-width: $mobileWidth) {
  .modal {
    width: calc(100% - 5rem);
    bottom: 0.5rem;
    top: auto;
    left: .5rem;
    height: auto;
    transform: translateY(100vh);

    &.show {
      transform: translateY(0);
    }
  }
}
</style>