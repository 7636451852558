<template>
  <div class="zero-state-wrapper" :class="{ show: show }">
    <v-lottie-player class="zero-state-animation" loop :path="pathURL" />
    <div>
      <h3>{{ title }}</h3>
      <p>{{ message }}</p>
      <button v-if="buttonTitle != undefined" class="btn btn-primary" @click="eventHandler()">
        <unicon name="plus"></unicon>
        <span>{{ buttonTitle }}</span>
      </button>
    </div>
  </div>
</template>

<script>
//Import Lottie to run JSON animations
import VueLottiePlayer from "vue-lottie-player";

export default {
  name: "ZeroState",
  data() {
    return {
      pathURL: null,
    };
  },
  components: { vLottiePlayer: VueLottiePlayer },
  props: {
    asset: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    horizontal: {
      type: Boolean,
      required: false,
    },
    show: {
      type: Boolean,
      required: true,
    },
    buttonTitle: {
      type: String,
      required: false,
    },
  },
  created() {
    if (process.env.VUE_APP_MODE == "development")
      this.pathURL = "/assets/animations/" + this.asset;
    else
      this.pathURL =
        process.env.VUE_APP_AWS_ASSETS_URL + "/animations/" + this.asset;
  },
  methods: {
    eventHandler() {
      this.$emit('eventHandler');
    },
  },
};
</script>

<style scoped lang="scss">
.zero-state-wrapper {
  display: none;
  text-align: center;
}

.zero-state-wrapper.show {
  display: block;
}

.zero-state-animation {
  display: block;
  width: 20rem !important;
  height: auto !important;
  margin: -3.5rem auto -0.5rem !important;
}

.zero-state-wrapper h3 {
  font-size: $largeFontSize;
  margin-bottom: 0.3rem;
}

.zero-state-wrapper p {
  color: $greyColor;
  margin-bottom: 1rem;
}
</style>