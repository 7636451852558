<template>
  <div class="field-wrapper">
    <!--START: Input Selector-->
    <div
      class="input-selector-wrapper"
      :class="selectedIcon.name == null ? '' : 'input-selected'"
    >
      <font-awesome-icon
        :icon="selectedIcon.name == null ? 'plus' : selectedIcon.name"
        @click="showIconPicker"
        class="icon-preview"
        :class="{ 'icon-add': selectedIcon.name == null }"
      />

      <input
        type="text"
        :name="field.value.title"
        :placeholder="field.placeholder"
        :required="field.required"
        :readonly="field.readOnly != undefined ? field.readOnly : false"
        :class="{ error: field.hasError }"
        @focus="field.hasError = false"
        @input="formChanged"
        v-model="field.value.title"
      />
    </div>
    <!--START: Input Selector-->

    <!--START: Icon Selector-->
    <div v-if="!hideIconPicker" class="icon-loader-wrapper" v-click-outside="closeModal">
      <label class="label-small">Icon Selector</label>
      <unicon name="times" class="modal-close" @click="closeModal"></unicon>
      <vue-icon-loader
        v-model="selectedIcon"
        :icon-preview="false"
        height="13rem"
        @input="iconSelected"
      ></vue-icon-loader>
    </div>
    <!--END: Icon Selector-->
  </div>
</template>

<script>
//Importing libraries
import vClickOutside from "v-click-outside";

export default {
  name: "IconSelector",
  data() {
    return {
      selectedIcon: {
        name: this.field.value.icon == null ? null : this.field.value.icon,
      },
      hideIconPicker: true,
    };
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    showIconPicker() {
      this.hideIconPicker = false;
    },

    closeModal() {
      this.hideIconPicker = true;
    },

    iconSelected() {
        this.field.value.icon = this.selectedIcon.name;
        this.formChanged();
    },

    formChanged() {
      this.$emit("formChanged");
      this.$emit("fieldChanged");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.field-wrapper {
  position: relative;
}

.input-selector-wrapper {
  position: relative;
  display: flex;

  &::after {
    content: "Add Icon";
    display: block;
    position: absolute;
    left: 0.35rem;
    bottom: -0.15rem;
    color: $greyColor;
    font-size: $smallestFontSize;
  }

  &.input-selected {
    &::after {
      display: none;
    }
  }
  .icon-preview {
    display: block;
    position: relative;
    background-color: #f7f6ff;
    padding: 0.75rem 1.15rem;
    border: 1px solid #e7e6ef;
    border-radius: 0.75rem;
    -webkit-appearance: none;
    color: $purpleColor;
    font-size: 1.5rem;
    margin-right: 1rem;
    cursor: pointer;

    &.icon-add {
      color: $greyColor;
      padding: 0.25rem 0.5rem;
      margin-left: 0.6rem;
    }

    &:hover {
      border-color: $purpleColor;
    }
  }

  input {
    flex: 1;
  }
}

.modal-close {
  top: -1rem;
  right: -1rem;
  background-color: $purpleColor !important;

  /deep/ {
    svg {
      fill: $whiteColor !important;
    }
  }
}
.icon-loader-wrapper /deep/ {
  > .label-small {
    margin: 0 0 0.5rem;
  }

  background-color: $whiteColor;
  padding: 0.75rem 1.15rem;
  border: 1px solid #e7e6ef;
  box-shadow: 0 0 0.7rem -0.1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.75rem;
  position: absolute !important;
  z-index: 10;
  bottom: 4rem;
  left: 0;
  width: calc(100% - 2.3rem);

  .search > div {
    display: none;
  }

  input {
    font-family: "Noto Sans JP";
    transition: all 0.3s;
    display: block;
    font-size: 1rem;
    font-weight: 500;
    outline: none;
    color: #32099b;
    background-color: #f7f6ff;
    width: calc(100% - 2.5rem);
    padding: 0.75rem 1.15rem;
    border: 1px solid #e7e6ef;
    border-radius: 0.75rem;
    -webkit-appearance: none;
  }
  ul {
    background-color: #f7f6ff;
    border: 1px solid #e7e6ef;
    border-radius: 0.75rem;
  }

  li {
    transition: none;
    color: $purpleColor;
    border-color: darken(#f7f6ff, 10%);

    &:hover {
      transform: none;
      color: $purpleColor;
      background-color: lighten($purpleColor, 35%);
      border-color: $purpleColor;
    }

    &.selected {
      transform: none;
      color: $whiteColor;
      background-color: $purpleColor;
      border: 1px solid darken(#f7f6ff, 10%);
    }
  }
}
</style>