<template>
  <div>
    <label class="label-small">Modules</label>
    <!--START: Modules Tree-->
    <div class="modules-wrapper">
      <DraggableComponent
        v-if="plan.modules.length"
        v-model="plan.modules"
        tag="ul"
        group="modules"
        handle=".drag-handle"
        @start="drag = true"
        @end="drag = false"
        @change="formChanged"
        class="modules"
      >
        <div
          v-for="(module, index) in plan.modules"
          class="module"
          :key="module.id"
          :class="{ active: index == selectedIndex }"
          @click="gotoModule(index)"
        >
          <div class="module-info-wrapper">
            <unicon class="drag-handle" name="draggabledots"></unicon>
            <span class="module-index">{{ index + 1 }}</span>
            <h3>{{ module.title != null ? module.title : "New Module" }}</h3>
            <button
              class="btn btn-icon btn-delete-client"
              @click.stop="showDeleteModal(index)"
            >
              <unicon name="trash-alt"></unicon>
            </button>
          </div>
        </div>
      </DraggableComponent>
    </div>
    <!--END: Modules Tree-->

    <button class="btn btn-border btn-add-module" @click="addModule">
      <unicon name="plus"></unicon>
      <span>Add Module</span>
    </button>

    <!--START: Delete Modal -->
    <ConfirmationModal
      :fields="deleteFields"
      @primaryEvent="deleteItem"
      @secondaryEvent="hideBackground"
    ></ConfirmationModal>
    <!--END: Delete Modal -->

    <!--START: BG Overlay-->
    <div
      class="bg-overlay"
      :class="{ show: showBackground }"
      @click="hideBackground()"
    ></div>
    <!--END: BG Overlay-->
  </div>
</template>

<script>
//Importing Components
import DraggableComponent from "vuedraggable";

//Import components
import ConfirmationModal from "@/components/modals/ConfirmationModal";

export default {
  name: "ModulesTree",
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedIndex: 0,
      showBackground: false,
      deleteID: null,
      deleteFields: {
        show: false,
        type: "delete",
        title: "Delete this module?",
        primaryBtn: {
          title: "Yes. Delete",
        },
        secondaryBtn: {
          title: "Cancel",
        },
      },
    };
  },
  components: {
    DraggableComponent,
    ConfirmationModal,
  },
  methods: {
    addModule() {
      this.$emit("addModule");
      this.selectedIndex = this.plan.modules.length - 1;
    },
    gotoModule(index) {
      this.selectedIndex = index;
      this.$emit("moduleSelected", index);
    },

    showDeleteModal(itemID) {
      this.showBackground = true;
      this.deleteID = itemID;
      this.deleteFields.show = true;
    },

    deleteItem() {
      this.plan.modules.splice(this.deleteID, 1);
      if (this.selectedIndex > this.plan.modules.length - 1)
        this.gotoModule(this.plan.modules.length - 1);

      this.formChanged();
      this.hideBackground();
    },

    hideBackground() {
      this.showBackground = false;
      this.deleteFields.show = false;
    },

    formChanged() {
      this.$emit("modulesChanged");
    },
  },
};
</script>

<style scoped lang="scss">
.label-small {
  display: block;
  font-size: $smallestFontSize;
  font-weight: $mediumFontWeight;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  color: $greyColor;
  margin: 0 0 0.5rem;
}

.modules-wrapper {
  background-color: $whiteColor;
  border: 1px solid #ececec;
  border-radius: 0.75rem;
}

.no-modules {
  text-align: center;

  em {
    display: block;
    font-size: 3rem;
    margin-bottom: 0.75rem;
  }
  h3 {
    font-size: $normalFontSize;
    font-weight: $normalFontWeight;

    opacity: 0.85;
    line-height: 1.1;
  }
}

.modules {
  margin: 0;
}

.module {
  position: relative;
  transition: all 0.3s;
  display: block;
  cursor: pointer;
  padding: 0.5rem;
  border-bottom: 1px solid lighten(#d8d9e5, 5%) !important;

  &:last-child {
    margin: 0;
    border-bottom-left-radius: 0.65rem;
    border-bottom-right-radius: 0.65rem;
  }

  &:first-child {
    border-top-left-radius: 0.65rem;
    border-top-right-radius: 0.65rem;
  }

  .module-info-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    h3 {
      flex: 1;
      font-size: $smallFontSize;
      font-weight: $mediumFontWeight;
      opacity: 0.65;
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 2rem;
    }
  }

  .module-index {
    font-size: $smallerFontSize;
    font-weight: $mediumFontWeight;
    color: $blackColor;
    opacity: 0.5;
    margin-right: 0.35rem;
    margin-left: 0.35rem;
  }

  .drag-handle {
    align-self: center;
    margin: 0 0.25rem 0 0.5rem;

    /deep/ svg {
      width: $mediumFontSize;
      fill: $buttonColor;
    }

    &::before {
      @include transition;
      opacity: 0;
      position: absolute;
      display: block;
      content: "";
      cursor: grab;
      border: 2px solid $buttonColor;
      border-radius: 0.5rem;
      top: -2px;
      left: -2px;
      height: calc(100% - 0px);
      width: calc(100% - 0px);
      z-index: -1;
    }

    &:hover::before {
      opacity: 1;
      z-index: 0;
    }
  }

  &.active {
    background-color: lighten($buttonColor, 40%);
    .module-info-wrapper {
      h3,
      .module-index {
        opacity: 1;
        color: $fontColor;
      }

      h3 {
        font-weight: $boldFontWeight;
      }
    }
  }
}

.btn-add-module {
  display: block;
  width: 100%;
  margin-top: 0.5rem;
}
</style>