import Vue from "vue";
import VueRouter from "vue-router";

//Import the various views
import UserLogin from "@/views/Authentication/User/Login";

//START: Builder
import BuilderSettings from "@/views/User/Builder/Common/Settings";
import BuilderFAQs from "@/views/User/Builder/Common/FAQs";
import BuilderGuides from "@/views/User/Builder/Common/Guides";
import BuilderAttachments from "@/views/User/Builder/Common/Attachments";
import BuilderCertificate from "@/views/User/Builder/Common/Certificate";

import VideoCourses from "@/views/User/Builder/VideoCourseBuilder/Courses";
import VideoCourseDashboard from "@/views/User/Builder/VideoCourseBuilder/CourseDashboard";
import VideoCourseBuilder from "@/views/User/Builder/VideoCourseBuilder/Builder";
//END: Builder

//START: Live Courses
import LiveCourses from "@/views/User/Builder/LiveCourseBuilder/Courses";
import LiveCourseDashboard from "@/views/User/Builder/LiveCourseBuilder/CourseDashboard";
import LiveCourseBuilder from "@/views/User/Builder/LiveCourseBuilder/Builder";

import LiveCourseDetails from "@/views/User/Builder/LiveCourseBuilder/Details";
import LiveCourseStudents from "@/views/User/Builder/LiveCourseBuilder/Students";
//END: Live Courses

import CoachDashboard from "@/views/User/Dashboard";
import CoachBookings from "@/views/User/Bookings";
import StudentsView from "@/views/User/Students";
import CoachInsights from "@/views/User/Insights";

/** STUDENT VIEWS **/

// Authentication Views
import StudentCreatePasswordToken from "@/views/Authentication/Student/CreatePasswordToken";
import StudentCreatePassword from "@/views/Authentication/Student/CreatePassword";
import StudentForgotPassword from "@/views/Authentication/Student/ForgotPassword";

// Dashboard Views
import StudentLogin from "@/views/Authentication/Student/Login";
import StudentCourses from "@/views/Student/Dashboard/Courses";
import StudentCertificates from "@/views/Student/Dashboard/Certificates";
import StudentDashboard from "@/views/Student/Dashboard/Dashboard";
import StudentVideoCourse from "@/views/Student/Dashboard/VideoCourse";
import StudentGuides from "@/views/Student/Dashboard/Guides";
import StudentFAQs from "@/views/Student/Dashboard/FAQs";
import StudentAttachments from "@/views/Student/Dashboard/Attachments";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/sign-in",
  },
  {
    path: "/admin",
    name: "Login",
    component: UserLogin,
    meta: {
      hideHeader: true,
    },
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: CoachDashboard,
    meta: {
      hideHeader: true,
    },
    children: [
      {
        path: "/dashboard/bookings",
        component: CoachBookings,
        props: true,
        meta: {
          showRouteModal: true,
          hideHeader: true,
        },
      },
      {
        path: "/dashboard/students",
        component: StudentsView,
        props: true,
        meta: {
          showRouteModal: true,
          hideHeader: true,
        },
      },
      {
        path: "/dashboard/insights",
        component: CoachInsights,
        props: true,
        meta: {
          showRouteModal: true,
          hideHeader: true,
        },
      },
      {
        path: "/dashboard/video-courses",
        name: "Video Courses",
        component: VideoCourses,
        props: true,
        meta: {
          showRouteModal: true,
          hideHeader: true,
          category: "offeringsBuilder",
        },
      },
      {
        path: "/dashboard/video-course-builder/:courseID?",
        component: VideoCourseDashboard,
        props: true,
        meta: {
          showRouteModal: true,
          hideHeader: true,
          workouts: true,
          category: "offeringsBuilder",
          subCategory: "videoCourseBuilder",
        },
        children: [
          {
            path: "course",
            component: VideoCourseBuilder,
            props: true,
            meta: {
              showRouteModal: true,
              hideHeader: true,
              category: "offeringsBuilder",
              subCategory: "videoCourseBuilder",
            },
          },
          {
            path: "details",
            component: BuilderSettings,
            props: true,
            meta: {
              showRouteModal: true,
              hideHeader: true,
              category: "offeringsBuilder",
              subCategory: "videoCourseBuilder",
            },
          },
          {
            path: "guides",
            component: BuilderGuides,
            props: true,
            meta: {
              showRouteModal: true,
              hideHeader: true,
              category: "offeringsBuilder",
              subCategory: "videoCourseBuilder",
            },
          },
          {
            path: "faqs",
            component: BuilderFAQs,
            props: true,
            meta: {
              showRouteModal: true,
              hideHeader: true,
              category: "offeringsBuilder",
              subCategory: "videoCourseBuilder",
            },
          },
          {
            path: "attachments",
            component: BuilderAttachments,
            props: true,
            meta: {
              showRouteModal: true,
              hideHeader: true,
              category: "offeringsBuilder",
              subCategory: "videoCourseBuilder",
            },
          },
          {
            path: "certificate",
            component: BuilderCertificate,
            props: true,
            meta: {
              showRouteModal: true,
              hideHeader: true,
              category: "offeringsBuilder",
              subCategory: "videoCourseBuilder",
            },
          },
        ],
      },
      {
        path: "/dashboard/live-courses",
        name: "Live Courses",
        component: LiveCourses,
        props: true,
        meta: {
          showRouteModal: true,
          hideHeader: true,
        },
      },
      {
        path: "/dashboard/live-course-builder/:courseID?",
        component: LiveCourseDashboard,
        props: true,
        meta: {
          showRouteModal: true,
          hideHeader: true,
          workouts: true,
          category: "offeringsBuilder",
          subCategory: "liveCourseBuilder",
        },
        children: [
          {
            path: "course",
            component: LiveCourseBuilder,
            props: true,
            meta: {
              showRouteModal: true,
              hideHeader: true,
              category: "offeringsBuilder",
              subCategory: "liveCourseBuilder",
            },
          },
          {
            path: "details",
            component: LiveCourseDetails,
            props: true,
            meta: {
              showRouteModal: true,
              hideHeader: true,
              category: "offeringsBuilder",
              subCategory: "liveCourseBuilder",
            },
          },
          {
            path: "guides",
            component: BuilderGuides,
            props: true,
            meta: {
              showRouteModal: true,
              hideHeader: true,
              category: "offeringsBuilder",
              subCategory: "videoCourseBuilder",
            },
          },
          {
            path: "faqs",
            component: BuilderFAQs,
            props: true,
            meta: {
              showRouteModal: true,
              hideHeader: true,
              category: "offeringsBuilder",
              subCategory: "videoCourseBuilder",
            },
          },
          {
            path: "attachments",
            component: BuilderAttachments,
            props: true,
            meta: {
              showRouteModal: true,
              hideHeader: true,
              category: "offeringsBuilder",
              subCategory: "videoCourseBuilder",
            },
          },
          {
            path: "certificate",
            component: BuilderCertificate,
            props: true,
            meta: {
              showRouteModal: true,
              hideHeader: true,
              category: "offeringsBuilder",
              subCategory: "videoCourseBuilder",
            },
          },
          {
            path: "students",
            component: LiveCourseStudents,
            props: true,
            meta: {
              showRouteModal: true,
              hideHeader: true,
              category: "offeringsBuilder",
              subCategory: "videoCourseBuilder",
            },
          },
        ],
      },
    ],
  },

  // Student Routes
  {
    path: "/sign-in",
    name: "StudentLogin",
    component: StudentLogin,
    meta: {
      hideHeader: true,
      isClientApp: true,
    },
  },
  {
    path: "/set-password",
    name: "Student Password Creation",
    component: StudentCreatePasswordToken,
    meta: {
      hideHeader: true,
      isClientApp: true,
    },
  },
  {
    path: "/create-password",
    name: "Student New Password",
    component: StudentCreatePassword,
    meta: {
      hideHeader: true,
      isClientApp: true,
    },
  },
  {
    path: "/forgot-password",
    name: "Student Forgot Password",
    component: StudentForgotPassword,
    meta: {
      hideHeader: true,
      isClientApp: true,
    },
  },
  {
    path: "/student/courses",
    name: "Courses",
    component: StudentCourses,
    meta: {
      hideHeader: true,
      isClientApp: true,
    },
  },
  {
    path: "/student/certificates",
    name: "Certificates",
    component: StudentCertificates,
    meta: {
      hideHeader: true,
      isClientApp: true,
    },
  },

  {
    path: "/student/courses/:courseID/:courseType",
    name: "Student Dashboard",
    component: StudentDashboard,
    meta: {
      hideHeader: true,
      isClientApp: true,
    },
    children: [
      {
        path: "modules",
        component: StudentVideoCourse,
        props: true,
        meta: {
          showRouteModal: true,
          hideHeader: true,
          workouts: true,
        },
      },
      {
        path: "guides",
        component: StudentGuides,
        props: true,
        meta: {
          showRouteModal: true,
          hideHeader: true,
          workouts: true,
        },
      },
      {
        path: "faqs",
        component: StudentFAQs,
        props: true,
        meta: {
          showRouteModal: true,
          hideHeader: true,
          workouts: true,
        },
      },
      {
        path: "attachments",
        component: StudentAttachments,
        props: true,
        meta: {
          showRouteModal: true,
          hideHeader: true,
          workouts: true,
        },
      },
    ],
  },

  {
    path: "*",
    redirect: "/sign-in",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
  },
});

export default router;
