<template>
    <div class="coach-settings-modal" :class="{ show: show }">
      <!--START: Header-->
      <div class="modal-header">
        <unicon name="angle-down" @click="closeModal" />
        <h3>Add FAQ</h3>
      </div>
      <!--END: Header-->
  
      <div class="scroll-wrapper">
        <!--START: FAQ-->
        <form class="coach-form" @change="formChanged">
          <FormBuilder :fields="fields" @fieldChanged="formChanged"></FormBuilder>
        </form>
        <!--END: FAQ-->
      </div>
  
      <button
        class="btn btn-primary"
        :disabled="disableButton"
        @click="updateCoach"
      >
        Save FAQ
      </button>
  
      <!--START: Page Loader -->
      <LineLoader :showLoader="showLoader"></LineLoader>
      <!--END: Page Loader -->
  
      <!--START: Status Message-->
      <StatusMessage
        :show="status.show"
        :isSuccess="status.isSuccess"
        :successMessage="status.successMessage"
        :errorMessage="status.errorMessage"
      ></StatusMessage>
      <!--END: Status Message-->
    </div>
  </template>
  
  <script>
  //Service for coach
  import DiyPlanService from "@/controllers/DiyPlanService";
  import VideoCourseService from "@/controllers/VideoCourseService";
  import OnlineCourseService from "@/controllers/OnlineCourseService";
  
  //Import components
  import LineLoader from "@/components/loaders/LineLoader";
  import StatusMessage from "@/components/modals/StatusMessage";
  import FormBuilder from "@/components/form/FormBuilder";
  
  export default {
    name: "CoachAddPlanFAQ",
    data() {
      return {
        showLoader: false,
        disableButton: true,
        fields: {
          question: {
            type: "text",
            title: "Question*",
            placeholder: "Enter your question",
            required: true,
            hasError: false,
            value: this.faqObject.question,
          },
          answer: {
            type: "text-editor",
            title: "Answer*",
            placeholder: "Enter your answer",
            required: true,
            hasError: false,
            value: this.faqObject.answer,
          },
        },
        status: {
          show: false,
          isSuccess: true,
          successMessage: "🙌 Changes saved",
          errorMessage: "😕 Something's not right. Try again",
        },
      };
    },
    props: {
      faqObject: {
        type: Object,
        required: true,
      },
      show: {
        type: Boolean,
        required: true,
      },
    },
    watch: {
      faqObject: {
        handler: function () {
          this.fields.question.value = this.faqObject.question;
          this.fields.answer.value = this.faqObject.answer;
        },
        immediate: true,
      },
    },
    components: {
      LineLoader,
      StatusMessage,
      FormBuilder,
    },
    async created() {
      this.resetFields(this.fields);
    },
    methods: {
      async updateCoach() {
        var timeoutHandler = null;
  
        clearTimeout(timeoutHandler);
  
        this.showStatus = true;
        this.isSuccess = false;
        this.showLoader = true;
  
        //Check if the form has valid input
        var formFields = {
          ...this.fields,
        };
  
        var formValidation = this.validateForm(formFields);
  
        if (formValidation.hasErrors) {
          this.status.isSuccess = false;
        } else if (!this.disableButton) {
          if (this.faqObject._id != undefined)
            formFields.faqID = this.faqObject._id;
  
          formFields.planID = this.$parent.plan._id;
          formFields.planTitle = this.$parent.plan.title;
  
          //Check if it's a clients plan or a new template
          if (
            this.$route.meta.customerPlan != undefined &&
            this.$route.meta.customerPlan
          ) {
            formFields.userType = "client";
          }
  
          //Check for type of course - Video / Online Plan
          var response;
          if (
            this.$route.meta.subCategory != undefined &&
            this.$route.meta.subCategory == "videoCourseBuilder"
          ) {
            response = await VideoCourseService.SaveFAQ(formFields);
          } else if (
            this.$route.meta.subCategory != undefined &&
            this.$route.meta.subCategory == "onlineCourseBuilder"
          ) {
            response = await OnlineCourseService.SaveFAQ(formFields);
          } else response = await DiyPlanService.SaveFAQ(formFields);
  
          if (!response.hasError) {
            this.status.isSuccess = true;
            this.$emit("faqSaved", response.data);
          } else {
            this.status.isSuccess = false;
            this.status.errorMessage = response.error;
          }
  
          this.resetFields(this.fields);
          this.showSuccessMessage();
          this.closeModal();
        }
  
        this.status.show = true;
        this.disableButton = true;
        this.showLoader = false;
  
        //Hide the notification
        timeoutHandler = setTimeout(() => (this.status.show = false), 3000);
      },
  
      formChanged() {
        this.disableButton = false;
      },
  
      hideBackground() {
        this.showBackground = false;
      },
  
      showSuccessMessage() {
        this.$parent.status.isSuccess = true;
        this.$parent.status.show = true;
        setTimeout(() => (this.$parent.status.show = false), 3000);
      },
  
      closeModal() {
        this.$emit("closeModal");
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  @import "@/assets/styles/shared/dashboard.scss";
  </style>