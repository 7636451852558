<template>
  <div v-if="showComponent" class="workouts-module">
    <!-- START: Module Header -->
    <div class="module-header">
      <div class="module-info-wrapper">
        <h1 class="module-title">Course Guides</h1>
        <p class="module-description">
          These guides are created to help you execute the course better.<br />Go
          through them in detail before you start!
        </p>
      </div>
    </div>
    <!-- END: Module Header -->

    <!--START: Guides-->
    <ul class="guides-list">
      <li
        class="guide-item"
        v-for="(guide, i) in plan.guides"
        :key="i"
        @click="showGuideModal(guide)"
      >
        <img
          v-if="guide.coverImageURL != undefined"
          :src="guide.coverImageURL"
          class="guide-image"
          alt="Guide Image"
        />
        <div class="guide-info-wrapper">
          <h3>{{ guide.title }}</h3>
          <button class="btn btn-border">View Guide</button>
        </div>
      </li>
    </ul>
    <!--END: Guides-->

    <GuideModal :show="showModal" :guide="selectedGuide"> </GuideModal>

    <!--START: Loaders-->
    <LineLoader :showLoader="showLoader"></LineLoader>
    <div
      class="bg-overlay"
      :class="{ show: showBackground }"
      @click="closeModal"
    ></div>
    <!--END: Loaders-->
  </div>
</template>

<script>
//Import components
import LineLoader from "@/components/loaders/LineLoader";
import GuideModal from "@/components/student/GuideModal";

export default {
  name: "ClientGuides",
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showComponent: false,
      showBackground: false,
      showModal: false,
      selectedGuide: {},
      showLoader: false,
    };
  },
  async created() {
    if ((await this.authenticateClient("sign-in", false)) == false)
      this.$router.push({
        path: "sign-in",
      });
    this.client = this.$store.state.client.client;

    this.showComponent = true;
  },

  components: {
    LineLoader,
    GuideModal,
  },
  methods: {
    showGuideModal(guide) {
      this.selectedGuide = guide;

      this.showModal = true;
      this.showBackground = true;
    },
    closeModal() {
      this.showModal = false;
      this.showBackground = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.module-header {
  position: relative;
  top: auto;
  left: auto;
  width: auto;
  display: block;
  padding: 2rem 12.5%;
  background: transparent;
  z-index: 10;

  /deep/ .module-title {
    font-family: $headingFont;
    font-size: 2rem;
    margin: 2rem 1.5rem 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .module-description {
    margin-top: 0.5rem;
    font-size: $mediumFontSize;
    width: 70%;
  }

  .module-actions-wrapper,
  .module-info-wrapper {
    align-self: center;
  }

  .modal-close {
    display: none;
  }
}

.guides-list {
  margin: 0 12% 3rem;
}

.guide-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.3s;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  background-color: $whiteColor;
  border: 1px solid #dbdbdb;

  .guide-image {
    width: 6rem;
    border-radius: 0.5rem;
    margin-right: 1.5rem;
  }

  .guide-info-wrapper {
    h3 {
      font-size: $mediumFontSize;
      font-weight: $mediumFontWeight;
      margin-bottom: 0.5rem;
    }
  }

  &:hover {
    border-color: darken($lightWhiteColor, 25%);
    box-shadow: 0 0.25rem 0.7rem -0.15rem rgba(0, 0, 0, 0.15);

    /deep/ .btn {
      background-color: $lightWhiteColor;
      color: darken($lightWhiteColor, 65%);
      border: 1px solid darken($lightWhiteColor, 55%);
      
    }
  }
}

.module-actions-wrapper {
  display: flex;
  text-align: right;
}

@media screen and (max-width: $mobileWidth) {
  .module-header {
    position: relative;
    width: auto;
    padding: 1.5rem 2rem 2rem;
    margin-bottom: 0;

    .module-title {
      margin: 0;
      font-size: 1.25rem;
    }

    .modal-close {
      display: block;
      border: none;
      top: 1rem;
      right: 1rem;
    }

    .module-description {
      width: auto;
      font-size: $smallFontSize;
    }
  }

  .module-actions-wrapper {
    display: none;
  }

  .guides-list {
    margin: 0 1rem 3rem;
  }
}
</style>