<template>
  <div v-if="offerings != undefined">
    <h2 class="title">Courses Insights</h2>

    <!--START: Bookings Table-->
    <div v-if="offerings.length != 0">
      <ul class="grid-table">
        <li class="grid-header">
          <div class="grid-item">Course</div>
          <div class="grid-item">Type</div>
          <div class="grid-item">Payment Status</div>
          <div class="grid-item">Status</div>
          <div class="grid-item">Price</div>
          <div class="grid-item">Sales</div>
          <div class="grid-item">Transactions</div>
        </li>
        <li v-for="offering in offerings" :key="offering.id" class="grid-row">
          <div class="grid-item booking-client">
            <h3>{{ offering.title }}</h3>
            <span
              >Created on
              {{
                moment(offering.createdAt).format("MMM DD YYYY, hh:mm A")
              }}</span
            >
          </div>

          <div class="grid-item offering-type">
            <span :class="offering.type">{{
              getOfferingTitle(offering.type)
            }}</span>
          </div>

          <!--START: Status-->
          <div class="grid-item">
            <span
              v-if="offering.soldOut"
              class="offering-deactivate-payments"
            >
              OFF
            </span>
            <span v-else class="active-payments"> Active </span>
          </div>
          <div class="grid-item">
            <span
              class="offering-active-status"
              :class="[
                { active: offering.activatePlan },
                { 'payments-accepted': offering.soldOut != true },
              ]"
            >
              {{ offering.activatePlan ? "Published" : "Unpublished" }}
            </span>
          </div>
          <!--END: Status-->

          <div class="grid-item plan-price">
            <span>{{ getCoachCurrency() }}</span>
            <em>{{ parseInt(offering.price).toLocaleString("en-IN") }}</em>
          </div>

          <div class="grid-item plan-price">
            <em>{{
              parseInt(offering.totalTransactions).toLocaleString("en-IN")
            }}</em>
          </div>

          <div class="grid-item plan-price">
            <span>{{ getCoachCurrency() }}</span>
            <em>{{
              parseInt(offering.totalAmount).toLocaleString("en-IN")
            }}</em>
          </div>
        </li>
      </ul>
    </div>
    <!--END: Bookings Table-->

    <div v-else class="no-bookings-wrapper">
      <em>🤔</em>
      <span>Hmph, we couldn't find any bookings for these dates</span>
    </div>

    <div
      class="bg-overlay"
      :class="{ show: showBackground }"
      @click="closeModal()"
    ></div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "OfferingsTable",
  props: {
    offerings: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showBackground: false,
      showLoader: false,
    };
  },

  methods: {
    sortOfferingsByDate() {
      return _.orderBy(this.offerings, "date", "createdAt");
    },

    formatPaymentStatus(status) {
      return status.replace(" ", "-").toLowerCase();
    },

    getOfferingTitle(offeringType) {
      switch (offeringType) {
        case "personal-training":
          return "Service Based Course";
        case "e-book":
          return "E-Book";
        case "diy-plan":
          return "Fitness Course";
        case "video-course":
          return "Video Course";
        case "online-course":
          return "Online Course";
        case "consultation-call":
          return "Appointment";
        default:
          return null;
      }
    },

    getBookings(index) {
      this.$parent.pageNumber = index;
      this.$emit("getBookings");
    },
  },
};
</script>

<style scoped lang="scss">
$gridTableRadius: 0.75rem;
.title {
  opacity: 0.75;
  font-size: $mediumFontSize;
  font-weight: $mediumFontWeight;
  margin: 2rem 0 1rem;
}

.line-loader {
  position: fixed;
  bottom: 0;
  z-index: 101;
}

.grid-table {
  margin: 0;
  border: 1px solid #edeae7;
  border-radius: $gridTableRadius;
}

.grid-header {
  padding: 0.95rem 0.95rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  background-color: $lightBlueColor;
  border-top-left-radius: $gridTableRadius;
  border-top-right-radius: $gridTableRadius;

  .grid-item {
    color: #696d8d;
    font-size: $smallerFontSize;
  }
}

.grid-row {
  z-index: 1;
  background-color: transparent;
  border: 1px solid transparent;
  background-color: $whiteColor;
  border-top: 1px solid #e6e8f1;
  box-shadow: 0 0 0.7rem -0.15rem rgba(0, 0, 0, 0);
  position: relative;
  border-radius: 0;
  padding: 0.75rem 0.95rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

.grid-row.selected,
.grid-row:hover {
  z-index: 10;
  cursor: pointer;
  background-color: #f3f5ff;
  border: 1px solid #efefef;
  box-shadow: 0 0 0.7rem -0.15rem rgba(0, 0, 0, 0.015);
  & + .grid-row {
    border-top: 1px solid transparent;
  }
}

.grid-row.selected {
  z-index: 11;

  .client-group .btn-border {
    background-color: lighten($purpleColor, 0%);
    color: lighten($purpleColor, 45%);
    border: 1px solid $purpleColor;

    .unicon /deep/ svg {
      fill: lighten($purpleColor, 45%);
    }
  }
}

.grid-row:last-child {
  border-bottom: none;
  border-bottom-left-radius: $gridTableRadius;
  border-bottom-right-radius: $gridTableRadius;
}

.grid-item {
  align-self: center;

  &:nth-child(1) {
    width: calc(70% - 25rem);
    padding-right: 2rem;
  }
  &:nth-child(2) {
    width: 6rem;
  }
  &:nth-child(3) {
    width: 10rem;
    text-align: center;
  }
  &:nth-child(4) {
    text-align: center;
    width: 7rem;
  }
  &:nth-child(5),
  &:nth-child(6),
  &:nth-child(7) {
    width: 10%;
    text-align: right;
  }
}

.booking-order {
  text-align: left;
  span {
    display: block;
    font-size: $smallerFontSize;

    &:first-child {
      font-weight: $mediumFontWeight;
    }
  }
}

.booking-client h3 {
  font-size: $normalFontSize;
  margin-bottom: 0;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.booking-date,
.booking-client span {
  font-size: $smallerFontSize;
  border-bottom: none;
  color: $greyColor;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  padding-bottom: 0.5rem;
}

.booking-client-email a {
  font-size: $smallerFontSize;
  border-bottom: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  padding-right: 2rem;
}

.booking-plan p {
  color: $greyColor;
  font-size: $smallerFontSize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  padding-bottom: 0.5rem;
}

.booking-phone {
  color: $greyColor;
  font-size: $smallerFontSize;
}

.plan-price {
  font-weight: $mediumFontWeight;
  font-size: $smallerFontSize;
}

.payment-date span {
  color: $blackColor;
  opacity: $mediumOpacity;
}

.payment-status {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5rem;
  font-size: $smallestFontSize;
  font-weight: $mediumFontWeight;
  border-radius: 0.5rem;
  padding: 0.3rem 0.5rem;
  line-height: $smallestFontSize;

  span {
    text-transform: capitalize;
    font-size: $smallestFontSize;
    display: inline-block;
    vertical-align: middle;
  }

  &.processing,
  &.processed {
    background-color: lighten($darkGreenColor, 0%);

    span {
      color: darken($greenColor, 50%);
    }
  }

  // &.processing {
  //   background-color: lighten($yellowColor, 0%);

  //   span {
  //     color: darken($yellowColor, 45%);
  //   }
  // }

  &.not-applicable {
    background-color: #d5d5d5;

    span {
      color: #4c4c4c;
    }
  }
}

.payment-amount {
  color: $blackColor;
  opacity: $mediumOpacity;
  font-size: $smallFontSize;

  span {
    font-size: $smallestFontSize;
    margin-right: 2px;
  }
}

.renewal-date {
  font-size: $smallerFontSize;
  color: $greyColor;
  line-height: $smallestFontSize;

  .renewal-date-expired {
    font-size: $smallestFontSize;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    line-height: $smallestFontSize;
    background-color: lighten($redColor, 0%);
    color: lighten($redColor, 45%);
  }
}

.client-actions {
  text-align: right;

  .btn-border {
    margin-right: 0.5rem;

    .unicon {
      line-height: 0rem !important;
    }
  }
}

.btn-delete-client {
  @include transition;

  &:hover {
    background-color: lighten($redColor, 30%);

    /deep/ .unicon svg {
      fill: darken($redColor, 10%);
    }
  }
}

.table-count {
  display: block;
  font-size: $smallestFontSize;
  opacity: $mediumOpacity;
  color: $greyColor;
  margin-left: 2rem;
}

.offering-type {
  text-align: left;

  span {
    display: inline-block;
    font-weight: $mediumFontWeight;
    font-size: $smallestFontSize;
    padding: 0.3rem 0.5rem;
    border-radius: 0.5rem;
    background-color: $darkGreenColor;
    color: darken($darkGreenColor, 45%);

    &.e-book {
      background-color: $purpleColor;
      color: $whiteColor;
    }
  }
}

.no-bookings-wrapper {
  text-align: center;
  font-size: $largeFontSize;
  color: $greyColor;

  em {
    font-size: 2rem;
    margin-right: 1rem;
  }

  span {
    font-weight: $mediumFontWeight;
  }
}

.pagination-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
}

.page-number {
  display: inline-block;
  vertical-align: middle;
  padding: 0.5rem;
  color: $greyColor;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  background-color: transparent;
  cursor: pointer;

  &.selected {
    background-color: $purpleColor;
    color: $whiteColor;
  }
}

.offering-deactivate-payments {
  font-size: $smallestFontSize;
  font-weight: $mediumFontWeight;
  background-color: $redColor;
  color: $whiteColor;
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-align: center;
  width: 6rem;
}

.active-payments {
  color: $greyColor;
  font-size: $smallerFontSize;
}

.offering-active-status {
  font-size: $smallestFontSize;
  font-weight: $mediumFontWeight;
  background-color: darken($whiteColor, 10%);
  color: darken($whiteColor, 60%);
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-align: center;
  width: 5rem;
  &.active {
    background-color: #e2ffe2;
    color: #098925;
    border: 1px solid #baefb9;
  }
}

@media screen and (max-width: $mobileWidth) {
  .grid-header {
    display: none;
  }

  .grid-table {
    border-radius: 0;
  }

  .grid-table {
    border: none;
  }

  .grid-row {
    position: relative;
    text-align: right;
    border: 1px solid $greyBorderColor !important;
    margin-bottom: 1rem;
    border-radius: 1rem;
    display: block;

    &:hover {
      border: 1px solid transparent;
      background-color: $whiteColor;
    }
  }

  .booking-order {
    width: auto;
    margin-bottom: 0.5rem;
  }

  .booking-client-email {
    display: block;
    text-align: left;
    width: auto;
    margin-bottom: 1rem;
  }

  .booking-date {
    text-align: left;
  }

  .booking-client {
    margin: 0.5rem 0;
    width: 100%;
    text-align: left;

    h3 {
      font-size: 1rem;
    }
  }

  .booking-plan {
    text-align: left;
    width: 100% !important;
    margin-bottom: 0.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid lighten($greyBorderColor, 5%);

    p {
      font-size: $normalFontSize;
    }
  }

  .plan-price {
    position: absolute;
    top: 0.75rem;
    right: 1rem;
    width: auto !important;
    font-size: 1.1rem;
  }

  .plan-payment {
    position: absolute;
    right: 0.25rem;
    top: 2.25rem;
    width: auto !important;
    display: inline-block;
    vertical-align: middle;
    text-align: left !important;

    .payment-status {
      margin-top: 3px;
      border-radius: 0.5rem;
      padding: 0.25rem 0.75rem 0.25rem 0.5rem;

      .unicon /deep/ svg {
        display: inline-block;
        vertical-align: middle;
      }

      span {
        display: inline-block;
      }
    }

    .btn {
      float: right;
    }
  }

  .client-actions {
    width: 2rem;
    display: inline-block;
    vertical-align: middle;
  }
}
</style>