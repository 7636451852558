<template>
  <div v-if="showComponent" class="authentication-component">
    <div class="authentication-wrapper">
      <img src="@/assets/images/logo.png" class="logo" alt="Logo" />

      <div class="form-wrapper">
        <h1>Create A Password</h1>
        <p>
          Congratulations on purchasing your course.<br />Enter the email where
          you received instructions
        </p>

        <!--START: Status Message-->
        <StatusMessage
          :show="status.show"
          :isSuccess="status.isSuccess"
          :errorMessage="status.errorMessage"
          :successMessage="status.successMessage"
        ></StatusMessage>
        <!--END: Status Message-->

        <!--START: Form Wrapper-->
        <form v-on:submit.prevent="createPasswordToken()">
          <FormBuilder
            :fields="fields"
            @fieldChanged="fieldChanged()"
          ></FormBuilder>
          <button type="submit" class="btn btn-primary">
            Get Email for Setting Password
            <unicon name="angle-right-b"></unicon>
          </button>
        </form>
        <!--END: Form Wrapper-->
      </div>

      <div class="alternate-options-wrapper">
        <div class="alternate-option">
          <p>Want to sign in?</p>
          <router-link to="sign-in">Sign in</router-link>
        </div>
      </div>
    </div>

    <!--START: Line Loader-->
    <LineLoader :showLoader="showLoader"></LineLoader>
    <!--END: Line Loader-->
  </div>
</template>

<script>
//Importing services
import AuthService from "@/controllers/AuthService";

//Importing components
import LineLoader from "@/components/loaders/LineLoader";
import FormBuilder from "@/components/form/FormBuilder";
import StatusMessage from "@/components/modals/StatusMessage";

export default {
  name: "CreatePasswordToken",
  data() {
    return {
      showComponent: false,
      showLoader: false,
      status: {
        show: false,
        isSuccess: false,
        errorMessage: "Enter a valid e-mail address",
        successMessage:
          "If your course purchase is linked to this email, you would've received an email to set your password in your inbox.",
      },
      fields: {
        email: {
          type: "email",
          placeholder: "E-mail address",
          required: true,
          hasError: false,
        },
      },
    };
  },
  components: {
    LineLoader,
    FormBuilder,
    StatusMessage,
  },
  async created() {
    if (!(await this.authenticateClient("/client/plans")))
      this.showComponent = true;
  },
  methods: {
    async createPasswordToken() {
      //Hide status message and show line loader
      this.status.show = false;
      this.showLoader = true;

      //Check if the form has valid input
      var formValidation = this.validateForm(this.fields);
      if (!formValidation.hasErrors) {
        //Use the service to authenticate the user
        await AuthService.CreatePasswordToken(this.fields);
        this.status.isSuccess = true;
      } else {
        this.status.isSuccess = false;
      }
      //Hide line loader
      this.status.show = true;
      this.showLoader = false;
    },

    fieldChanged() {
      this.status.show = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/authentication.scss";

.help-article {
  color: lighten($whiteColor, 25%);
  margin-right: 2rem;
}

.alternate-options-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
}
</style>