<template>
  <div>
    <h2 class="title">Total Insights</h2>
    <div class="insights-summary-wrapper">
      <div class="insights-stat-block">
        <div class="insights-stat-wrapper">
          <div class="insights-stat">
            <unicon name="user"></unicon>
            <div class="insights-stat-info">
              <em>{{
                parseInt(insights.transactions).toLocaleString("en-IN")
              }}</em>
              <label>Transactions</label>
            </div>
          </div>
          <div class="insights-stat">
            <unicon name="money-insert"></unicon>
            <div class="insights-stat-info">
              <em
                ><span>{{ getCoachCurrency() }}</span
                >{{ parseInt(insights.amount).toLocaleString("en-IN") }}</em
              >
              <label>Total Amount</label>
            </div>
          </div>
          <div class="insights-stat">
            <unicon name="eye"></unicon>
            <div class="insights-stat-info">
              <em>{{ parseInt(insights.eBooks).toLocaleString("en-IN") }}</em>
              <label>E-Books Sold</label>
            </div>
          </div>
          <div class="insights-stat">
            <unicon name="users-alt"></unicon>
            <div class="insights-stat-info">
              <em>{{
                parseInt(insights.videoCourses).toLocaleString("en-IN")
              }}</em>
              <label>Video Courses Sold</label>
            </div>
          </div>
          <div class="insights-stat">
            <unicon name="users-alt"></unicon>
            <div class="insights-stat-info">
              <em>{{
                parseInt(insights.onlinePlans).toLocaleString("en-IN")
              }}</em>
              <label>Fitness Courses Sold</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "InsightsTotal",
  props: {
    insights: Object,
    showLoader: Boolean
  },
};
</script>

<style scoped lang="scss">
.title {
  opacity: 0.75;
  font-size: $mediumFontSize;
  font-weight: $mediumFontWeight;
  margin: 2rem 0 1rem;
}

.insights-summary-wrapper {
  display: flex;
  margin: 1rem 0 2rem;
}

.label-small {
  display: block;
  font-size: $smallestFontSize;
  color: $greyColor;
  margin-bottom: 1rem;

  b {
    font-weight: $mediumFontWeight;
  }
}

.insights-stat-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.insights-stat {
  flex-grow: 1;
  width: calc(33% - 3rem - 3rem);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 3rem 1.5rem 0;
  background-color: $whiteColor;
  border: 1px solid darken($whiteColor, 10%);
  padding: 1rem 1.5rem;
  border-radius: 1rem;

  em {
    display: block;
    font-size: 1.5rem;
    font-weight: $mediumFontWeight;
    color: $blackColor;
    margin-bottom: 0.5rem;

    span {
      font-size: $largeFontSize;
    }
  }

  label {
    font-size: $normalFontSize;
    font-weight: $mediumFontWeight;
    color: $greyColor;
  }

  .unicon {
    margin-right: 1rem;
    /deep/ svg {
      background: lighten($greenColor, 25%);
      fill: darken($darkGreenColor, 10%);
      width: 1rem;
      height: auto;
      padding: 0.5rem;
      border: 1px solid lighten($greenColor, 20%);
      border-radius: 0.5rem;
    }
  }
}
</style>