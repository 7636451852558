<template>
  <div>
    <!--START: Module Header-->
    <div class="module-header">
      <div class="module-info-wrapper extend">
        <h1 class="module-title">{{ moduleOptions.title }}</h1>
        <p class="module-description">{{ moduleOptions.description }}</p>
      </div>
    </div>

    <!--START: Monthly Sales Summary -->
    <div v-if="showComponent" class="monthly-summary-wrapper">
      <div class="insights-title-wrapper">
        <h1 class="insights-title">
          {{ moment().format("MMMM") }} {{ moment().format("YYYY") }}
        </h1>
        <FormBuilder
          :fields="fields"
          @fieldChanged="monthChanged"
        ></FormBuilder>
      </div>
      <MonthlyInsights
        :totalAmount="monthlySummary.totalAmount"
        :totalSales="monthlySummary.totalSales"
        :totalViews="profileAnalytics.totalViews"
        :uniqueViews="profileAnalytics.uniqueViews"
      ></MonthlyInsights>

      <div class="offerings-sales-wrapper" v-if="offeringsSales.length > 0">
        <MonthlySalesTable
          v-if="offeringsSales.length"
          :offerings="offeringsSales"
        ></MonthlySalesTable>
      </div>

      <div class="offerings-sales-wrapper">
        <MonthlyLocationInsights
          v-if="profileAnalytics.locationAnalytics.length"
          :locations="profileAnalytics.locationAnalytics"
        ></MonthlyLocationInsights>
      </div>
    </div>
    <!--END: Monthly Sales Summary -->

    <!--START: Zero State -->
    <ZeroState
      v-if="offeringsSales.length == 0"
      :asset="zeroStateOptions.asset"
      :show="zeroStateOptions.show"
      :title="zeroStateOptions.title"
      :message="zeroStateOptions.message"
      :buttonTitle="zeroStateOptions.buttonTitle"
      class="zero-state"
    ></ZeroState>
    <!--END: Zero State -->

    <!--START: Page Loader -->
    <LineLoader :showLoader="showLoader"></LineLoader>
    <!--END: Page Loader -->

    <div
      class="bg-overlay"
      :class="{ show: showBackground }"
      @click="hideBackground()"
    ></div>
  </div>
</template>

<script>
//Import libraries
import _ from "lodash";

//Service for coach
import InsightService from "@/controllers/InsightService";

//Import components
import LineLoader from "@/components/loaders/LineLoader";
import ZeroState from "@/components/dashboard/ZeroState";
import MonthlySalesTable from "@/components/user/MonthlySalesTable";
import MonthlyInsights from "@/components/user/MonthlyInsights";
import MonthlyLocationInsights from "@/components/user/MonthlyLocationInsights";
import FormBuilder from "@/components/form/FormBuilder";

export default {
  name: "CoachInsights",
  props: {
    coach: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showComponent: false,
      showBackground: false,
      showLoader: false,
      overallInsights: {},
      offeringsSales: [],
      profileAnalytics: {},
      historicAnalytics: [],
      monthlySummary: {
        totalAmount: 0,
        totalSales: 0,
      },
      fields: {
        months: {
          type: "dropdown",
          placeholder: "Choose month",
          required: false,
          hasError: false,
          fields: [],
        },
      },
      moduleOptions: {
        title: "Insights",
        description: "Analytics and insights about how you're doing",
      },
      zeroStateOptions: {
        show: true,
        asset: "metrics.json",
        title: "View Your Insights",
        message: "Your plan and website insights will start appearing here",
      },
    };
  },
  components: {
    LineLoader,
    ZeroState,
    MonthlyInsights,
    MonthlySalesTable,
    MonthlyLocationInsights,
    FormBuilder,
  },
  async created() {
    this.showLoader = true;

    if (_.isEmpty(this.coach)) {
      await this.authenticateCoach("/sign-in", false);
      this.coach = this.$store.state.coach.coach;
    }

    await this.initInsights();

    this.showLoader = false;
    this.showComponent = true;
  },
  methods: {
    async initInsights() {
      this.showLoader = true;

      const response = await InsightService.GetInsights();

      if (!response.hasError) {
        this.overallInsights = response.data;
        this.offeringsSales =
          this.overallInsights.currentMonthSummary.offeringSummary;
        this.profileAnalytics =
          this.overallInsights.currentMonthSummary.analytics;
        this.historicAnalytics = this.overallInsights.historicAnalytics;
        this.getMonthlySales();
        this.initMonthsDropdown();
      }

      this.showLoader = false;
    },

    initMonthsDropdown() {
      let monthCounter = 0;
      let monthsList = [
        {
          name: `${this.moment().format("MMMM")} ${this.moment().format(
            "YYYY"
          )}`,
          value: monthCounter,
        },
      ];

      monthCounter++;
      this.historicAnalytics.forEach(function (historicMonth) {
        monthsList.push({
          name: `${historicMonth.date.month} ${historicMonth.date.year}`,
          value: monthCounter,
        });

        monthCounter++;
      });

      this.fields.months.fields = monthsList;
    },

    getMonthlySales() {
      let totalAmount = 0;
      let totalSales = 0;
      this.offeringsSales.forEach(function (offering) {
        totalAmount += offering.sales.totalAmount;
        totalSales += offering.sales.totalSales;
      });

      this.monthlySummary = {
        totalAmount: totalAmount,
        totalSales: totalSales,
      };
    },

    //Month changed from dropdown
    monthChanged() {
      let selectedMonthIndex = this.fields.months.value[1];

      //Check if it's the current month
      if (selectedMonthIndex == 0) {
        this.offeringsSales =
          this.overallInsights.currentMonthSummary.offeringSummary;
        this.profileAnalytics =
          this.overallInsights.currentMonthSummary.analytics;
      }
      //Get data for historic month
      else {
        this.offeringsSales =
          this.overallInsights.historicAnalytics[
            selectedMonthIndex - 1
          ].offeringSummary;
        this.profileAnalytics =
          this.overallInsights.historicAnalytics[
            selectedMonthIndex - 1
          ].analytics;
      }

      this.getMonthlySales();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.insights-title-wrapper {
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #d8d9e5;
  display: flex;
  flex-direction: row;
  align-items: center;

  .insights-title {
    margin-bottom: 0;
  }

  /deep/ .field-main-item {
    margin-left: 2rem;
    margin-bottom: 0;
    width: 12rem;
  }
}

.profile-summary-wrapper,
.monthly-summary-wrapper {
  padding: 2rem 2rem 1rem;
}

.zero-state {
  padding-bottom: 0 2rem;
}

@media screen and (min-width: $mobileWidth) {
  .profile-summary-wrapper,
  .monthly-summary-wrapper {
    padding: 2rem 2rem 2rem;
  }
}

//Mobile Styles
@media screen and (max-width: $mobileWidth) {
  .profile-summary-wrapper,
  .monthly-summary-wrapper {
    padding: 2rem 1rem 2rem;
  }

  .insights-title-wrapper {
    padding-bottom: 0;
    border-bottom: none;

    .insights-title {
      flex: 1;
    }
    /deep/ {
      .field-main-item {
        margin-top: 0.5rem;
        margin-left: 0;
      }
    }
  }
}
</style>