<template>
  <div v-if="showComponent" class="guides-block">
    <!--START: Module Header-->
    <div class="module-header">
      <div class="module-info-wrapper">
        <h1 class="module-title">{{ moduleOptions.title }}</h1>
        <p class="module-description">{{ moduleOptions.description }}</p>
      </div>

      <!--START: Module Actions-->
      <div class="module-actions-wrapper">
        <button
          class="btn btn-border btn-add"
          @click="
            emptyItem();
            showAddGuideModal();
          "
        >
          Add Guide<unicon name="plus" />
        </button>
      </div>
      <!--END: Module Actions-->
    </div>
    <!--END: Module Header-->

    <!--START: Guides-->
    <div v-if="plan.guides != undefined && plan.guides.length > 0">
      <div class="guides-list">
        <DraggableComponent
          v-model="plan.guides"
          tag="ul"
          group="guides"
          handle=".drag-handle"
          @start="drag = true"
          @end="drag = false"
          @change="compareState"
          class="guides block-wrapper"
        >
          <div
            v-for="guide in plan.guides"
            class="guide"
            :key="guide.id"
            @click="
              editItem(guide);
              showAddGuideModal();
            "
          >
            <div class="guide-title-wrapper">
              <unicon class="drag-handle" name="draggabledots"></unicon>
              <div class="guide-wrapper">
                <h3>{{ guide.title }}</h3>
              </div>
              <div class="guide-actions">
                <button class="btn btn-icon btn-edit">
                  <span>Edit</span>
                  <unicon name="pen"></unicon>
                </button>
                <button
                  class="btn btn-icon btn-delete-client"
                  @click.stop="showDeleteModal(guide._id)"
                >
                  <span>Delete</span>
                  <unicon name="trash-alt"></unicon>
                </button>
              </div>
            </div>
          </div>
        </DraggableComponent>
      </div>
      <span class="drag-instruction">
        <b>Tip:</b> Hold the <unicon name="draggabledots"></unicon> icon to
        re-order your guides
      </span>
    </div>
    <!--END: Links-->

    <!--START: Zero State -->
    <ZeroState
      v-else
      :asset="zeroStateOptions.asset"
      :show="true"
      :title="zeroStateOptions.title"
      :message="zeroStateOptions.message"
      :buttonTitle="zeroStateOptions.buttonTitle"
      @eventHandler="
        emptyItem();
        showAddGuideModal();
      "
      class="zero-state"
    ></ZeroState>
    <!--END: Zero State -->

    <!--START: Page Loader -->
    <LineLoader :showLoader="showLoader"></LineLoader>
    <!--END: Page Loader -->

    <!--START: Status Message-->
    <StatusMessage
      :show="status.show"
      :isSuccess="status.isSuccess"
      :successMessage="status.successMessage"
    ></StatusMessage>
    <!--END: Status Message-->

    <!--START: Delete Modal -->
    <ConfirmationModal
      :fields="deleteFields"
      @primaryEvent="deleteItem"
      @secondaryEvent="hideBackground"
    ></ConfirmationModal>
    <!--END: Delete Modal -->

    <!--START: Unsaved Changes Modal -->
    <ConfirmationModal
      :fields="unsavedChangesFields"
      class="unsaved-changes-modal"
      @primaryEvent="continueRoute"
      @secondaryEvent="hideBackground"
    ></ConfirmationModal>
    <!--END: Unsaved Changes Modal -->

    <AddGuide
      :guideObject="editObject"
      :show="showAddGuide"
      @guideSaved="updatePlan"
      @closeModal="hideBackground"
    ></AddGuide>

    <div
      class="bg-overlay"
      :class="{ show: showBackground }"
      @click="hideBackground()"
    ></div>
  </div>
</template>

<script>
//Import libraries
import DraggableComponent from "vuedraggable";

//Import components
import LineLoader from "@/components/loaders/LineLoader";
import StatusMessage from "@/components/modals/StatusMessage";
import ZeroState from "@/components/dashboard/ZeroState";
import AddGuide from "@/components/courseBuilder/AddGuide";
import ConfirmationModal from "@/components/modals/ConfirmationModal";

export default {
  name: "CoachPlanGuides",
  props: {
    plan: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showLoader: false,
      showComponent: false,
      showBackground: false,
      showPageLoader: false,
      showAddGuide: false,
      editObject: {},
      moduleOptions: {
        title: "Plan Guides",
        description: "Add articles to help your customer",
      },
      deleteID: null,
      deleteFields: {
        show: false,
        type: "delete",
        title: "Delete this guide?",
        primaryBtn: {
          title: "Yes. Delete",
        },
        secondaryBtn: {
          title: "Cancel",
        },
      },
      toRoute: null,
      unsavedChangesFields: {
        show: false,
        title: "You Have Unsaved Changes",
        message:
          "Your changes will be lost. Are you sure you want to continue?",
        type: "delete",
        primaryBtn: {
          title: "Yes, Continue",
        },
        secondaryBtn: {
          title: "Cancel",
        },
      },
      status: {
        show: false,
        isSuccess: true,
        successMessage: "🙌 Changes saved",
        errorMessage: "😕 Something's not right. Try again",
      },
      zeroStateOptions: {
        asset: "photos.json",
        title: "Let's Add A Guide",
        message: "Add information on how to go about your course",
        buttonTitle: "Add Guide",
      },
    };
  },
  components: {
    LineLoader,
    StatusMessage,
    ZeroState,
    AddGuide,
    DraggableComponent,
    ConfirmationModal,
  },
  async created() {
    this.showComponent = true;
  },
  methods: {
    updatePlan(updatedPlan) {
      this.$emit("updatePlan", updatedPlan);
    },

    compareState() {
      this.$parent.plan = this.plan;
      this.$parent.saveModule = "guides";
      this.$emit("compareState");
    },

    showDeleteModal(itemID) {
      this.showBackground = true;
      this.deleteID = itemID;
      this.deleteFields.show = true;
    },

    deleteItem() {
      this.plan.guides.splice(
        this.plan.guides.findIndex(({ _id }) => _id == this.deleteID),
        1
      );

      this.compareState();
      this.hideBackground();
    },

    emptyItem() {
      this.editObject = {};
    },

    editItem(item) {
      this.editObject = item;
    },

    showAddGuideModal() {
      this.toRoute = null;
      if (!this.$parent.disableButton) {
        this.showBackground = true;
        this.unsavedChangesFields.show = true;
      } else {
        this.showBackground = true;
        this.showAddGuide = true;
      }
    },

    formChanged() {
      this.$parent.disableButton = false;
    },

    hideBackground() {
      this.showBackground = false;
      this.deleteFields.show = false;
      this.unsavedChangesFields.show = false;
      this.showAddGuide = false;
    },

    continueRoute() {
      this.$parent.disableButton = true;

      if (this.toRoute != null) this.$router.push({ path: this.toRoute.path });
      else {
        this.$emit("resetPlan");
        this.showAddGuideModal();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.$parent.disableButton) {
      this.toRoute = to;
      this.showBackground = true;
      this.unsavedChangesFields.show = true;
    } else {
      this.$emit("resetPlan");
      next();
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.coach-form {
  padding: 1.5rem 1.5rem;

  /deep/ .field-main-item:last-child {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 4rem;
  }
}

.guides-list {
  margin-top: 0.5rem;
}

.guides {
  margin: 0;
}

.guide {
  background-color: $whiteColor;
  position: relative;
  border-bottom: 1px solid $greyBorderColor;
  padding: 0.75rem 0.5rem;
  margin: 0 2px;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  &:last-child {
    border-bottom: none;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  &:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  &::before {
    @include transition;
    opacity: 0;
    position: absolute;
    display: block;
    content: "";
    border: 2px solid $purpleColor;
    border-radius: 0.5rem;
    top: -2px;
    left: -2px;
    height: calc(100% - 0px);
    width: calc(100% - 0px);
    z-index: -1;
  }

  &:hover {
    background-color: $whiteColor;
  }
}

.guide-wrapper {
  flex: 1;
  margin: 0 1rem;
  overflow: hidden;

  h3 {
    font-size: $mediumFontSize;
    margin: 0 0 0.15rem;
  }

  span {
    color: $purpleColor;
    text-transform: capitalize;
  }

  p {
    display: block;
    margin-top: 0.15rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.drag-handle {
  align-self: center;
  margin: 0 0.25rem 0 0.5rem;
  cursor: grab;

  /deep/ svg {
    width: $mediumFontSize;
    fill: $buttonColor;
  }

  &::before {
    @include transition;
    opacity: 0;
    position: absolute;
    display: block;
    content: "";
    border: 2px solid $purpleColor;
    border-radius: 0.5rem;
    top: -2px;
    left: -2px;
    height: calc(100% - 0px);
    width: calc(100% - 0px);
    z-index: -1;
  }

  &:hover::before {
    opacity: 1;
    z-index: 0;
  }
}

.guide-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
  flex: 1;
}

.guide-img {
  display: none;
}

@media screen and (min-width: $mobileWidth) {
  .guides-list {
    margin-top: 0;

    .label-small {
      font-weight: $mediumFontWeight;
      letter-spacing: 0;
      text-transform: none;
    }

    .guides {
      padding: 2rem 1rem 1rem;
    }

    .guide {
      transition: all 0.3s;
      display: block;
      cursor: pointer;
      margin: 0 1rem 0.5rem;
      padding: 0.5rem;
      border: 1px solid lighten(#d8d9e5, 5%) !important;
      border-radius: 0.5rem;

      &:hover {
        border: 1px solid lighten(#d8d9e5, 0%) !important;
        box-shadow: 0 0.5rem 0.7rem -0.15rem rgba(179, 179, 179, 0.35);
      }

      .guide-img {
        display: block;
        width: 100%;
        border-radius: 0.25rem;
        margin-bottom: 0.5rem;
      }
    }

    .guide-title-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .guide-actions {
      text-align: right;
    }
  }

  .guide-wrapper {
    margin: 0 0.5rem;

    h3 {
      font-size: $normalFontSize;
      font-weight: 500;
    }
    p {
      color: $greyColor;
      font-size: $smallerFontSize;
    }
  }
}

.btn-icon {
  margin-left: 1rem;
  span {
    font-weight: $normalFontWeight;
    font-size: $smallFontSize;
    margin-right: 0.25rem;
  }
}
</style>