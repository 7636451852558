<template>
  <div
    v-if="name != undefined"
    class="avatar-wrapper"
    :class="size != undefined ? size : ''"
  >
    <span>{{ getNameInitials(name) }}</span>
  </div>
</template>

<script>
export default {
  name: "ProfileAvatar",
  data() {
    return {
      backgroundColor: null,
    };
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
    },
  },
  methods: {
    getNameInitials(personName) {
      if (personName != undefined && personName.indexOf(" ") >= 0) {
        personName = personName.split(" ");
      }
      var profileName = "";
      for (var i = 0; i < personName.length; i++) {
        if (i < 2) {
          profileName += personName[i].charAt(0);
        } else break;
      }

      return profileName;
    },
  },
};
</script>

<style scoped lang="scss">
.avatar-wrapper {
  display: block;
  position: relative;
  background-color: $purpleColor;
  text-align: center;
  height: 1.85rem;
  width: 1.85rem;
  border-radius: 50%;

  span {
    position: absolute;
    color: lighten($purpleColor, 40%);
    font-size: $smallestFontSize;
    line-height: 0.9;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.large {
  height: 2rem;
  width: 2rem;

  span {
    font-size: $normalFontSize;
  }
}

.extra-large {
  height: 5rem;
  width: 5rem;

  span {
    font-size: $largestFontSize;
  }
}
</style>