<template>
  <div v-if="showComponent">
    <!-- START: Module Header -->
    <div class="module-header">
      <div class="module-info-wrapper">
        <h1 class="module-title">{{ moduleOptions.title }}</h1>
        <p class="module-description">{{ moduleOptions.description }}</p>
      </div>
      <div class="module-actions-wrapper">
        <button class="btn btn-primary" @click="gotoBuilder">
          <unicon name="plus"></unicon>
          <span>{{ zeroStateOptions.buttonTitle }}</span>
        </button>
      </div>
    </div>
    <!-- END: Module Header -->

    <!--START: Grid Table -->
    <div class="courses-table-wrapper" v-if="courses.length > 0">
      <CoursesTable :courses="courses" ref="coursesTable"></CoursesTable>
    </div>
    <!--END: Grid Table -->

    <!--START: Zero State -->
    <ZeroState
      v-else-if="!showLoader"
      :asset="zeroStateOptions.asset"
      :show="zeroStateOptions.show"
      :title="zeroStateOptions.title"
      :message="zeroStateOptions.message"
      :buttonTitle="zeroStateOptions.buttonTitle"
      @eventHandler="gotoBuilder"
      class="zero-state"
    ></ZeroState>
    <!--END: Zero State -->

    <!--START: Info Tip-->
    <div class="offering-info-wrapper">
      <div class="offering-title-info">
        <p>
          <unicon name="lock"></unicon> The videos are protected by
          <b>DRM Video Encryption</b> using Google Widevine DRM technology
        </p>
      </div>
    </div>
    <!--END: Info Tip-->

    <!--START: Page Loader -->
    <PageLoader :showLoader="showLoader"></PageLoader>
    <!--END: Page Loader -->

    <div
      class="bg-overlay"
      :class="{ show: showBackground }"
      @click="hideBackground()"
    ></div>
  </div>
</template>

<script>
import _ from "lodash";

// Import servicest
import VideoCourseService from "@/controllers/VideoCourseService";

//Importing dashboard components
import PageLoader from "@/components/loaders/PageLoader";
import ZeroState from "@/components/dashboard/ZeroState";
import CoursesTable from "@/components/videoCourse/CoursesTable";

export default {
  name: "CoursesList",
  data() {
    return {
      showComponent: false,
      showZeroState: false,
      showBackground: false,
      showLoader: false,
      moduleOptions: {
        title: "Video Courses",
        description: "Here's a view of your video courses",
      },
      courses: [],
      selectedCourseID: null,
      zeroStateOptions: {
        show: true,
        asset: "clients.json",
        title: "Build A Video Course",
        message: "Start by adding your video course",
        buttonTitle: "Create Video Course",
      },
    };
  },
  components: {
    ZeroState,
    CoursesTable,
    PageLoader,
  },
  async created() {
    this.showLoader = true;

    if (_.isEmpty(this.coach)) {
      await this.authenticateCoach("/sign-in", false);
      this.coach = this.$store.state.coach.coach;
    }

    this.showComponent = true;

    const response = await VideoCourseService.GetCourses();
    if (!response.hasError) {
      this.courses = response.data;
    }
    this.showLoader = false;
  },
  methods: {
    gotoBuilder() {
      this.$router.push({ path: `/dashboard/video-course-builder/details` });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
/deep/ .page-loader {
  margin-top: 3rem;
}

.offering-info-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 1rem 2rem;
  width: 70%;

  .offering-info-icon {
    font-size: 1.5rem;
    margin-right: 1rem;
    margin-top: 0.25rem;
  }

  .offering-title-info {
    h3 {
      font-size: 1rem;
      color: $darkBlueColor;
      font-weight: $mediumFontWeight;
    }

    p {
      color: $greyColor;

      /deep/ .unicon svg {
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.5rem;
        margin-left: 0.25rem;
        width: 1rem;
        height: auto;
        margin-top: -5px;
        fill: darken($darkGreenColor, 20%);
        background-color: lighten($darkGreenColor, 45%);
        padding: 0.25rem;
        border: 1px solid lighten($darkGreenColor, 5%);
        border-radius: 0.25rem;
      }
    }
  }
}

.courses-table-wrapper {
  padding: 3rem 2rem 1rem;
}

.module-header {
  margin-bottom: 2rem;
}

.grid-table {
  margin-top: 2rem;
}

.zero-state {
  padding-bottom: 0 2rem;
}

.clients-toggle-options /deep/ {
  .field-radio-wrapper {
    margin-top: 2rem;
    margin-bottom: 0;
  }

  .field-label {
    display: none;
  }
}
</style>