<template>
  <div>
    <!--START: Module Header-->
    <div class="module-header">
      <div class="module-info-wrapper extend">
        <h1 class="module-title">{{ moduleOptions.title }}</h1>
        <p class="module-description">{{ moduleOptions.description }}</p>
      </div>

      <!--START: Module Actions-->
      <div class="module-actions-wrapper">
        <button class="btn btn-primary" @click="showAddBookingModal()">
          Add Student <unicon name="plus" />
        </button>
      </div>
      <!--END: Module Actions-->
    </div>
    <!--END: Module Header-->

    <!--START: Grid Table -->
    <div
      class="bookings-table-wrapper"
      v-if="bookings.length > 0 || coachHasBookings"
    >
      <BookingsSummary
        :dateRange="dateRange"
        :totalAmount="totalAmount"
        :totalSales="totalSales"
        @getBookings="getBookings"
      ></BookingsSummary>
      <label class="label-small">{{ bookings.length }} Bookings</label>
      <BookingsTable
        v-if="!fetchingBookings"
        :bookings="bookings"
        :currentPage="pageNumber"
        :bookingsPerPage="bookingsPerPage"
        :totalBookings="totalSales"
        @getBookings="getBookings"
        ref="bookingsTable"
      ></BookingsTable>
    </div>
    <!--END: Grid Table -->

    <!--START: Zero State -->
    <ZeroState
      v-else
      :asset="zeroStateOptions.asset"
      :show="zeroStateOptions.show"
      :title="zeroStateOptions.title"
      :message="zeroStateOptions.message"
      :buttonTitle="zeroStateOptions.buttonTitle"
      class="zero-state"
    ></ZeroState>
    <!--END: Zero State -->

    <!--START: Page Loader -->
    <LineLoader :showLoader="showLoader"></LineLoader>
    <!--END: Page Loader -->

    <!--START: Add Booking Modal -->
    <AddBooking
      :coach="coach"
      :show="showAddBooking"
      @closeModal="hideBackground"
      @bookingCompleted="manualBookingAdded"
    ></AddBooking>
    <!--START: Add Booking Modal -->

    <div
      class="bg-overlay"
      :class="{ show: showBackground }"
      @click="hideBackground()"
    ></div>
  </div>
</template>

<script>
//Import libraries
import _ from "lodash";

//Service for coach
import BookingService from "@/controllers/BookingService";

//Import components
import LineLoader from "@/components/loaders/LineLoader";
import ZeroState from "@/components/dashboard/ZeroState";
import BookingsTable from "@/components/user/BookingsTable";
import AddBooking from "@/components/user/AddBooking";
import BookingsSummary from "@/components/user/BookingsSummary";

export default {
  name: "CoachBookings",
  props: {
    coach: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showComponent: false,
      showBackground: false,
      showLoader: false,
      fetchingBookings: true,
      coachHasBookings: false,
      showAddBooking: false,
      totalAmount: 0,
      totalSales: 0,
      bookings: [],
      pageNumber: 1,
      bookingsPerPage: 25,

      moduleOptions: {
        title: "Course Bookings",
        description: "Manage course bookings",
      },
      dateRange: {
        startDate: {
          type: "date",
          title: "Start Date",
          class: "top-fixed",
          placeholder: "Mention a start date",
          required: false,
          hasError: false,
          value: new Date().setDate(new Date().getDate() - 30),
          disabledDates: {
            from: new Date(),
          },
        },
        endDate: {
          type: "date",
          title: "End Date",
          class: "top-fixed",
          placeholder: "Mention an end date",
          required: false,
          hasError: false,
          value: new Date(),
          disabledDates: {
            from: new Date(),
          },
        },
      },
      zeroStateOptions: {
        show: true,
        asset: "metrics.json",
        title: "Manage Your Bookings",
        message: "Your client and course bookings will start appearing here",
      },
    };
  },
  components: {
    LineLoader,
    ZeroState,
    BookingsTable,
    BookingsSummary,
    AddBooking,
  },
  async created() {
    this.showLoader = true;

    if (_.isEmpty(this.coach)) {
      await this.authenticateCoach("/sign-in", false);
      this.coach = this.$store.state.coach.coach;
    }

    await this.getBookings();

    this.showLoader = false;
    this.showComponent = true;
  },
  methods: {
    async getBookings() {
      this.fetchingBookings = true;
      this.showLoader = true;

      const fields = {
        fromDate: this.dateRange.startDate.value,
        toDate: this.dateRange.endDate.value,
        pageNumber: this.pageNumber,
        perPage: this.bookingsPerPage,
      };

      const response = await BookingService.GetBookings(fields);

      if (!response.hasError) {
        this.bookings = response.data;
        this.totalAmount = 0;
        this.totalSales = response.data.length;

        if (this.bookings.length) this.coachHasBookings = true;
      }

      window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchingBookings = false;
      this.showLoader = false;
    },

    manualBookingAdded() {
      this.hideBackground();
      this.getBookings();
    },

    showAddBookingModal() {
      this.toRoute = null;
      this.showBackground = true;
      this.showAddBooking = true;
    },

    hideBackground() {
      this.showBackground = false;
      this.showAddBooking = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.bookings-table-wrapper {
  padding: 3rem 2rem 1rem;
}

.payment-header {
  display: flex;
  flex-direction: row;
}

.payment-header-wrapper {
  padding: 0 2rem 2rem;
}

.price-wrapper,
.plan-price {
  color: $purpleColor;
  font-weight: $mediumFontWeight;

  span {
    font-size: $normalFontSize;
    margin-right: 0.15rem;
  }
  em {
    font-size: $largeFontSize;
  }
}

.plan-price {
  color: $greyColor;
}

.payment-header-item {
  margin-right: 0rem;
  flex: 1;
  label {
    font-size: $smallerFontSize;
    color: $greyColor;
    display: block;
    margin-bottom: 0.5rem;
  }

  &:last-child {
    margin-right: 0;
  }
}

.zero-state {
  padding-bottom: 0 2rem;
}

.label-small {
  margin: 1rem 0;
}

@media screen and (min-width: $mobileWidth) {
  .payment-header-wrapper {
    padding: 2rem 1.5rem 2rem;
  }
}

//Mobile Styles
@media screen and (max-width: $mobileWidth) {
  .bookings-table-wrapper {
    padding: 3rem 1rem 2rem;
  }
}
</style>