<template>
  <div v-if="showComponent" class="authentication-component">
    <div class="authentication-wrapper">
      <img src="@/assets/images/logo.png" class="logo" alt="Logo" />

      <div class="form-wrapper">
        <h1>Namaste 👋</h1>
        <p>Please login here to access your course</p>

        <!--START: Status Message-->
        <StatusMessage
          :show="status.show"
          :isSuccess="status.isSuccess"
          :errorMessage="status.errorMessage"
        ></StatusMessage>
        <!--END: Status Message-->

        <!--START: Email Form Wrapper-->
        <form v-on:submit.prevent="loginUser()">
          <FormBuilder
            :fields="fields"
            @fieldChanged="fieldChanged()"
          ></FormBuilder>
          <button type="submit" class="btn btn-primary">
            Sign In
            <unicon name="angle-right-b"></unicon>
          </button>
          <div class="alternate-option forgot-password-option">
            <router-link to="/set-password" class="create-password"
              >First time? Create Password</router-link
            >
            <router-link to="/forgot-password" class="forgot-password"
              >Forgot Password?</router-link
            >
          </div>
        </form>
        <!--END: Form Wrapper-->
      </div>
    </div>

    <!--START: Line Loader-->
    <LineLoader :showLoader="showLoader"></LineLoader>
    <!--END: Line Loader-->
  </div>
</template>

<script>
//Importing services
import AuthService from "@/controllers/AuthService";

//Importing components
import LineLoader from "@/components/loaders/LineLoader";
import FormBuilder from "@/components/form/FormBuilder";
import StatusMessage from "@/components/modals/StatusMessage";

export default {
  name: "Login",
  data() {
    return {
      showComponent: false,
      showLoader: false,
      showEmailLogin: false,
      showOTPFields: false,
      status: {
        show: false,
        isSuccess: false,
        errorMessage: "The e-mail and password don't match",
      },
      phoneLoginFields: {
        phone: {
          type: "tel",
          title: "Phone Number",
          placeholder: "Your 10 digit phone number",
          required: true,
          hasError: false,
          readOnly: false,
        },
      },
      phoneButtonText: "Get OTP",
      otpFields: {
        otp: {
          type: "number",
          title: "OTP",
          placeholder: "Enter OTP",
          required: true,
          hasError: false,
        },
      },
      fields: {
        email: {
          type: "email",
          placeholder: "E-mail",
          required: true,
          hasError: false,
        },
        password: {
          type: "password",
          placeholder: "Password",
          required: true,
          hasError: false,
        },
      },
    };
  },
  metaInfo() {
    return {
      title: "Login To Your Account",
      meta: [
        {
          name: "description",
          content: "",
          vmid: "description",
        },
        {
          name: "og:title",
          content: "Login To Your Account",
          vmid: "og:title",
        },
        {
          name: "og:image",
          content: "",
          vmid: "og:image",
        },
        {
          name: "og:description",
          content: "",
          vmid: "og:description",
        },
        {
          name: "og:url",
          content: "",
          vmid: "og:url",
        },
        {
          name: "og:type",
          content: "website",
          vmid: "og:type",
        },
      ],
    };
  },
  components: {
    LineLoader,
    FormBuilder,
    StatusMessage,
  },
  async created() {
    if (!(await this.authenticateClient("/student/courses")))
      this.showComponent = true;
  },
  methods: {
    async loginUser() {
      //Hide status message and show line loader
      this.status.show = false;
      this.showLoader = true;

      //Check if the form has valid input
      var formValidation = this.validateForm(this.fields);
      if (!formValidation.hasErrors) {
        //Use the service to authenticate the user
        const client = await AuthService.LoginStudent(this.fields);
        if (client.hasError) {
          this.status.show = true;
          this.status.errorMessage = client.error;
        } else if (!client.hasError) {
          this.redirectUser(client.data);
        }
      }
      //Hide line loader
      this.showLoader = false;
    },

    async generateOTP() {
      if (!this.showOTPFields) {
        //Hide status message and show line loader
        this.status.show = false;
        this.showLoader = true;

        //Check if the form has valid input
        var formValidation = this.validateForm(this.phoneLoginFields);
        if (!formValidation.hasErrors) {
          //Use the service to authenticate the user
          const loginResponse = await AuthService.GenerateOTP({
            ...this.phoneLoginFields,
            userType: "client",
          });
          if (loginResponse.hasError) {
            this.status.show = true;
            this.status.errorMessage = loginResponse.error;
          } else if (!loginResponse.hasError) {
            this.phoneButtonText = "Login with OTP";
            this.phoneLoginFields.phone.readOnly = true;
            this.showOTPFields = true;
          }
        }
        //Hide line loader
        this.showLoader = false;
      } else this.loginUserViaOTP();
    },

    async loginUserViaOTP() {
      //Hide status message and show line loader
      this.status.show = false;
      this.showLoader = true;

      //Check if the form has valid input
      var formValidation = this.validateForm({
        ...this.phoneLoginFields,
        ...this.otpFields,
      });

      if (!formValidation.hasErrors) {
        //Use the service to authenticate the user
        const client = await AuthService.ValidateOTP({
          ...this.otpFields,
          userType: "client",
        });
        if (client.hasError) {
          this.status.show = true;
          this.status.errorMessage = client.error;
        } else if (!client.hasError) {
          this.redirectUser(client.data);
        }
      }
      //Hide line loader
      this.showLoader = false;
    },

    redirectUser(client) {
      this.$store.commit("updateClient", {
        client: client,
        loggedIn: true,
      });
      this.$router.push({ path: "/student/courses" });
    },

    fieldChanged() {
      this.status.show = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/authentication.scss";

.authentication-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8%;
  width: 84%;

  .authentication-wrapper {
    position: relative;
    transform: none;
    top: auto;
    left: auto;
    right: auto;
    margin: -1rem auto 0;
    text-align: center;
  }

  .login-asset {
    margin-top: 2rem;
    max-width: 40rem;
    width: 60%;
  }
}

.help-article {
  color: $greyColor;
  margin-bottom: 1.5rem;
}

.create-password {
  font-size: $normalFontSize !important;
  font-weight: $mediumFontWeight;
}

.forgot-password {
  display: block;
  color: $greyColor;
  margin-top: 0.5rem;
}

.login-disclaimer {
  margin: 1rem 0 0;
  em {
    font-size: 1.25rem;
    margin-right: 0.5rem;
  }
  p {
    font-size: $smallerFontSize;
    color: $greyColor;
    flex: 1;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
    font-size: $smallestFontSize;
    font-weight: $mediumFontWeight;
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;
    line-height: $smallestFontSize;
    background-color: lighten($yellowColor, 0%);
    color: darken($yellowColor, 45%);
  }
}

@media screen and (max-width: $mobileWidth) {
  .authentication-component {
    position: relative;
    top: auto;
    left: auto;
    min-height: 100vh;
    flex-direction: column-reverse;
    padding: 0 0.75rem;
    width: calc(100% - 1.5rem);
    align-items: center;
    justify-content: center;
    display: block;
    padding-top: 2rem;

    .authentication-wrapper {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .login-asset {
      max-width: none;
      width: 80%;
    }
  }
}
</style>