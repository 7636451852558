<template>
  <div v-if="showComponent" class="module-dashboard coach-profile">
    <!--START: Profile Header-->
    <CoachHeader :coach="coach"></CoachHeader>
    <!--END: Profile Header-->
    <div class="admin-wrapper">
      <div class="dashboard-wrapper">
        <div class="profile-menu-wrapper">
          <!--START: Profile Menu-->
          <div class="coach-menu block-wrapper">
            <router-link
              class="coach-menu-item home-menu-item"
              to="/dashboard/"
            >
              <unicon name="estate" class="coach-menu-icon" />
              <span>Home</span>
              <unicon name="angle-right-b" class="coach-angle-icon" />
            </router-link>

            <router-link
              class="coach-menu-item"
              to="/dashboard/video-courses"
              :class="{
                active: $route.meta.subCategory == 'videoCourseBuilder',
              }"
            >
              <unicon name="bolt-alt" class="coach-menu-icon" />
              <span>Video Courses</span>
              <label class="menu-description"
                >Video course names, description, modules</label
              >
              <unicon name="angle-right-b" class="coach-angle-icon" />
            </router-link>

            <router-link
              class="coach-menu-item"
              to="/dashboard/live-courses"
              :class="{
                active: $route.meta.subCategory == 'live-courses',
              }"
            >
              <unicon name="layer-group" class="coach-menu-icon" />
              <span>Live Courses</span>
              <label class="menu-description"
                >Batches for live and studio courses</label
              >
              <unicon name="angle-right-b" class="coach-angle-icon" />
            </router-link>
          </div>
          <!--END: Profile Menu-->

          <!--START: Dashboard & Stats-->
          <label class="label-small">Activity</label>
          <div class="coach-menu block-wrapper">
            <router-link class="coach-menu-item" to="/dashboard/bookings">
              <unicon name="money-insert" class="coach-menu-icon" />
              <span>Bookings</span>
              <label class="menu-description">Client bookings, payments</label>
              <unicon name="angle-right-b" class="coach-angle-icon" />
            </router-link>

            <router-link class="coach-menu-item" to="/dashboard/students">
              <unicon name="users-alt" class="coach-menu-icon" />
              <span>Students</span>
              <label class="menu-description"
                >Students details and courses</label
              >
              <unicon name="angle-right-b" class="coach-angle-icon" />
            </router-link>

            <router-link class="coach-menu-item" to="/dashboard/insights">
              <unicon name="analytics" class="coach-menu-icon" />
              <span>Insights</span>
              <label class="menu-description">Plan Summary, Analytics</label>
              <unicon name="angle-right-b" class="coach-angle-icon" />
            </router-link>
          </div>
          <!--END: Dashboard & Stats-->
        </div>

        <div class="skipper-footer">
          <img src="@/assets/images/logomark.png" class="logo" alt="Logo" />
          <span
            >Powered by Skipper<br />&copy; AGZ Technologies Private
            Limited.</span
          >
        </div>

        <!--START: Page Loader -->
        <PageLoader :showLoader="showPageLoader"></PageLoader>
        <!--END: Page Loader -->
      </div>

      <!--START: Router Wrapper-->
      <div
        v-if="showRouteModal"
        class="modal-route"
        :class="{ show: showRouteModal }"
      >
        <router-link to="/dashboard">
          <unicon class="close-router" name="times" />
        </router-link>
        <div class="modal-content">
          <router-view :coach="coach" class="modal-block"></router-view>
          <div class="skipper-footer">
            <img src="@/assets/images/logomark.png" class="logo" alt="Logo" />
            <span
              >Powered by Skipper<br />&copy; AGZ Technologies Private
              Limited.</span
            >
          </div>
        </div>
      </div>
      <!--END: Router Wrapper-->

      <DashboardMain
        v-show="$route.name == 'Dashboard'"
        :coach="coach"
      ></DashboardMain>
    </div>

    <!--START: Page Loader -->
    <LineLoader :showLoader="showLoader"></LineLoader>
    <!--END: Page Loader -->

    <div
      class="bg-overlay"
      :class="{ show: showBackground }"
      @click="hideBackground()"
    ></div>
  </div>
</template>

<script>
//Import components
import CoachHeader from "@/components/user/Header";
import DashboardMain from "@/components/user/DashboardMain";
import PageLoader from "@/components/loaders/PageLoader";
import LineLoader from "@/components/loaders/LineLoader";

export default {
  name: "CoachDashboard",
  data() {
    return {
      coach: {},
      showComponent: false,
      showBackground: false,
      showPageLoader: false,
      showLoader: false,
      fields: {
        darkMode: {
          type: "toggle",
          value: true,
          placeholder: "Dark Mode",
        },
      },

      showRouteModal: false,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (newVal) {
        this.showRouteModal = newVal.meta && newVal.meta.showRouteModal;
        this.coach = this.$store.state.coach.coach;
      },
    },
  },
  components: {
    CoachHeader,
    DashboardMain,
    PageLoader,
    LineLoader,
  },
  computed: {
    currentRouteName() {
      if (this.$route.name != undefined) return this.$route.name.toLowerCase();
      else return false;
    },
  },
  async created() {
    this.showPageLoader = true;

    if ((await this.authenticateCoach("/sign-in", false)) == false)
      this.$router.push({
        path: "/sign-in",
      });
    this.coach = this.$store.state.coach.coach;

    this.fields.darkMode.value = this.coach.darkMode;
    this.showComponent = true;
    this.showPageLoader = false;
  },
  methods: {
    gotoRoute(routeURL, menuIndex) {
      if (this.isMobile()) {
        this.$refs[`menu-${menuIndex}`].classList.toggle("show");
      } else this.$router.push({ path: routeURL });
    },

    hideBackground() {
      this.closeOnboardingPopup();
      this.showBackground = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.module-dashboard {
  background-color: lighten($lightWhiteColor, 5%);
}

.dashboard-wrapper {
  padding: 0;
}

.hero-wrapper {
  text-align: center;
  padding: 2rem 1.5rem;

  .avatar-wrapper {
    margin: auto;
  }

  .coach-name {
    font-size: $largeFontSize;
    margin: 0.5rem 0 0.1rem;
  }

  .coach-email {
    display: block;
    color: $greyColor;
  }

  .copy-wrapper {
    display: inline-block;
    vertical-align: middle;
    padding: 0.75rem 1.25rem;
    border-radius: 0.5rem;
    margin-top: 1rem;
    background-color: #e6e2f9;
    color: $purpleColor;
    font-size: $normalFontSize;

    .coach-link {
      display: inline-block;
      vertical-align: middle;
      em {
        font-weight: $mediumFontWeight;
      }
    }

    .unicon {
      display: inline-block;
      vertical-align: middle;
      margin-left: 1rem;

      /deep/ svg {
        fill: $purpleColor;
        height: auto;
        width: $mediumFontSize;
      }
    }
  }
}

.coach-menu {
  margin: 0;
  padding: 0;

  .coach-menu-item {
    display: block;
    position: relative;
    padding: 1rem 1.5rem;
    text-decoration: none;
    border: none;
    border: 1px solid transparent;

    &.home-menu-item {
      display: none;
    }

    .coach-angle-icon,
    .coach-menu-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 1.5rem;

      /deep/ svg {
        fill: $greyColor;
        height: auto;
        width: $mediumFontSize;
      }
    }

    .coach-angle-icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 1rem;
      color: $blackColor;

      sup {
        display: inline-block;
        color: $darkGreenColor;
        vertical-align: top;
        font-size: 0.75rem;
        font-weight: 400;
        margin-left: 0.35rem;
        transform: translateY(-0.25rem);
      }
    }

    .menu-description {
      display: block;
      color: $greyColor;
      font-size: $smallerFontSize;
      opacity: $mediumOpacity;
      margin: 0.5rem 0 0 2.65rem;
    }
  }
}

.skipper-footer {
  padding: 3rem 1.5rem 2rem;

  .logo {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1rem;
    width: 1.5rem;
  }

  span {
    font-size: $smallerFontSize;
    line-height: 1.4;
    display: inline-block;
    vertical-align: middle;
    color: $greyColor;
  }
}

.modal-route {
  width: calc(100%);
  height: calc(100% - 1rem);
  background-color: #faf7f3;
  box-shadow: 0 0 1rem 0.1rem rgba(73, 73, 73, 0.15);
  border-radius: 0.5rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0.5rem 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000000001;
  transform: translateX(calc(100% + 3rem));

  &.show {
    animation-name: showRouteModal;
    animation-duration: 0.35s;
    animation-delay: 0.01s;
    animation-fill-mode: forwards;
  }

  .modal-content {
    width: calc(100%);
    position: absolute;
    overflow-y: scroll;
    padding-top: 5rem;
    top: 0;
    left: 0;
  }

  /deep/ .field-toggle-item {
    margin-top: 1rem;
    flex-direction: row-reverse;

    .field-toggle-button {
      transform: scale(1.25);
      transform-origin: center right;
    }

    label {
      margin-left: 0;
      font-size: 1rem;
    }
  }

  .close-router {
    position: absolute;
    z-index: 10;
    top: 0.65rem;
    left: 0.5rem;

    &/deep/ svg {
      fill: $blackColor;
      width: 2rem;
      height: auto;
      padding: 0.25rem;
      border-radius: 0.25rem;
    }
  }

  @keyframes showRouteModal {
    0% {
      transform: translateX(calc(100% + 3rem));
    }
    100% {
      transform: translateX(0);
    }
  }
}

.profile-picture-image {
  position: relative;
  width: 8rem;
  height: 8rem;
  margin: 0 auto 0.5rem;
  overflow: hidden;
  border-radius: 50%;
  background: $whiteColor;
  border: 2px solid $purpleColor;
  padding: 3px;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: calc(8rem - 3px);
  }
}

.tooltip-booking {
  left: 1rem;
  top: auto;
  bottom: calc(100% + 3rem);
  margin: auto;
  z-index: 10000000000;
}

.mega-menu {
  position: relative;
  display: none;

  &.show {
    display: block;
    margin: 0.5rem 0 1rem 6.5rem;

    .coach-menu-item {
      display: table;
      padding: 0;
      margin: 0 0 1.25rem;
      background: transparent !important;
      border: none !important;

      label {
        margin-left: 0;
      }

      &.router-link-exact-active,
      &.active {
        span {
          opacity: 1;
        }
      }
    }
  }

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: -2.25rem;
    background-color: $purpleColor;
    width: 1px;
    height: 100%;
  }
}

:not(.mega-menu) .router-link-active {
}

@media screen and (min-width: $mobileWidth) {
  .module-dashboard {
    min-height: 100vh;
  }

  .tooltip-booking {
    left: calc(100% + 1rem);
    top: 0;
    bottom: auto;

    /deep/ .arrow {
      transform: rotate(90deg);
      top: 0.5rem;
      bottom: auto;
      left: -2.25rem;
    }
  }

  .admin-wrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 0 0;
  }

  .dashboard-wrapper {
    width: 19rem;
    position: fixed;
    z-index: 1;
    padding: 0 0;
    top: 4rem;
    height: calc(100% - 4rem);
    margin: 0;

    &::after {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      bottom: 4rem;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        lighten($lightWhiteColor, 5%)
      );
      height: 4rem;
      width: 100%;
    }
  }

  .profile-menu-wrapper {
    position: relative;
    border-radius: 0;
    padding-top: 1rem;
    max-height: 75vh;
    overflow-y: scroll;
    overflow-x: hidden;

    &::before {
      display: block;
      content: "";
      position: absolute;
      right: -1rem;
      top: 0;
      background: lighten($greyBorderColor, 5%);
      height: calc(100vh - 4rem);
      width: 1px;
    }
  }

  .label-small {
    margin: 0.5rem 1.25rem;
    font-weight: 500;
    font-size: 10px;
  }

  .hero-wrapper {
    display: none;
    padding: 1.5rem;

    .coach-name {
      font-size: $mediumFontSize;
    }

    .copy-wrapper {
      display: none;
      font-size: $smallerFontSize;
    }

    .coach-email {
      font-size: $smallerFontSize;
    }
  }

  .coach-menu {
    position: relative;
    background-color: transparent !important;
    padding: 0.5rem 0;

    &:last-child {
      padding-bottom: 5rem;
    }

    .coach-menu-item {
      padding: 0.5rem 1rem;
      margin: 0.1rem 0 0.1rem 1rem;
      border-radius: 0.5rem;
      cursor: pointer;

      &:hover {
        background-color: lighten($lightWhiteColor, 2.5%);
      }

      &.home-menu-item {
        display: block;
      }

      span {
        color: $blackColor;
        font-size: $smallFontSize;
        opacity: $mediumOpacity;
      }

      .menu-description {
        display: none;
      }

      .coach-angle-icon /deep/ svg,
      .coach-menu-icon /deep/ svg {
        width: 0.85rem;
        opacity: $lightOpacity;
      }
      .unicon {
        margin-right: 1.25rem;
      }

      &:not(.home-menu-item).router-link-active,
      &.router-link-exact-active,
      &.active {
        background-color: $lightWhiteColor;
        border: 1px solid darken($lightWhiteColor, 25%);

        .unicon /deep/ svg,
        span {
          font-weight: $mediumFontWeight;
          fill: darken($lightWhiteColor, 75%);
          color: darken($lightWhiteColor, 75%);

          sup {
            display: none;
          }
        }
      }
    }
  }

  .mega-menu {
    &::before {
      display: none;
    }

    &.show {
      margin: 0.5rem 0 1rem 4.5rem;
    }

    .coach-menu-item {
      margin: 0 0 0.75rem;
      span {
        opacity: 0.55;
        font-size: $smallerFontSize;
      }
    }
  }

  .profile-picture-image {
    width: 4rem;
    height: 4rem;

    img {
      width: calc(4rem - 3px);
    }
  }

  .skipper-footer {
    padding: 0;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    white-space: nowrap;

    .logo {
      width: 1.25rem;
      margin-right: 0.75rem;
    }
    span {
      font-size: $smallestFontSize;
    }
  }

  .modal-route {
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    height: fit-content;
    transform: none;
    flex: 1;
    margin: 0 0 0 calc(20rem + 0px);
    box-shadow: none;
    z-index: 0;
    padding: 9rem 0 0;
    overflow: hidden;

    .close-router {
      display: none;
    }

    /deep/ .module-actions-wrapper,
    /deep/ .module-info-wrapper {
      flex: 1;
    }

    /deep/ .skipper-footer {
      display: none;
    }

    &.show {
      position: relative;
      z-index: 1;
      animation: none;
    }

    .modal-content {
      position: relative;
      width: auto;
      height: calc(100vh - 9.5rem);
      padding-top: 0;
    }

    /deep/ .field-toggle-item .field-toggle-button {
      transform: scale(1);
    }
  }
}

//Mobile Styles
@media screen and (max-width: $mobileWidth) {
  .course-builder {
    display: none !important;
  }

  .dashboard-wrapper {
    padding: 6rem 0;
    margin-top: 0;
  }

  .modal-route {
    overflow-y: scroll;
  }

  .modal-content {
    height: calc(100vh - 5rem);
  }
}
</style>