<template>
  <div class="date-selector-main">
    <div class="selected-range-wrapper">
      <label class="label-small">Select Date</label>
      <span @click="showDateSelector = !showDateSelector"
        ><b>{{ moment(this.startDate).format("Do MMMM YYYY") }}</b>
        to
        <b>{{ moment(this.endDate).format("Do MMMM YYYY") }}</b>
        <unicon name="angle-down"></unicon
      ></span>
    </div>
    <div v-if="showDateSelector" class="date-selector-wrapper">
      <div class="date-selector-block">
        <!--START: Month Selector-->
        <div v-if="showSelector.month" class="month-selector">
          <span
            v-for="date in getDates"
            :key="date.id"
            @click="selectMonth(date.month, date.year)"
          >
            {{ date.month }} {{ date.year }}
          </span>
        </div>
        <!--END: Month Selector-->

        <!--START: Date Range Selector-->
        <div v-if="showSelector.custom" class="date-range-selector">
          <FormBuilder class="date-form" :fields="dateRange"></FormBuilder>
          <button
            type="button"
            class="btn btn-primary"
            @click="selectDateRange"
          >
            Get Insights
          </button>
        </div>
        <!--START: Date Range Selector-->
      </div>
      <div class="option-selector-list">
        <span
          v-for="(option, index) in selectorOption"
          :key="option.id"
          :class="{ selected: index == selectedOptionIndex }"
          @click="optionSelected(option.val, index)"
        >
          {{ option.title }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import FormBuilder from "@/components/form/FormBuilder";

export default {
  name: "DateSelector",
  props: {
    totalSales: Number,
    totalAmount: Number,
  },
  data() {
    return {
      showBackground: false,
      showLoader: false,
      startDate: new Date().setDate(new Date().getDate() - 30),
      endDate: new Date(),
      selectedOptionIndex: 0,
      showDateSelector: false,
      showSelector: {
        month: false,
        custom: false,
      },
      selectorOption: [
        { title: "Today", val: "today" },
        { title: "Yesterday", val: "yesterday" },
        { title: "Last Week", val: "last-week" },
        { title: "Month", val: "month" },
        { title: "Custom Range", val: "custom" },
      ],
      dateRange: {
        startDate: {
          type: "date",
          title: "Start Date",
          class: "top-fixed",
          placeholder: "Mention a start date",
          required: false,
          hasError: false,
          value: new Date().setDate(new Date().getDate() - 30),
          disabledDates: {
            from: new Date(),
          },
        },
        endDate: {
          type: "date",
          title: "End Date",
          class: "top-fixed",
          placeholder: "Mention an end date",
          required: false,
          hasError: false,
          value: new Date(),
          disabledDates: {
            from: new Date(),
          },
        },
      },
    };
  },
  components: {
    FormBuilder,
  },
  computed: {
    getDates: function () {
      let dates = [];
      let startDate = this.moment("01 Nov 2021");
      const currentDate = this.moment();

      for (var d = startDate; d <= currentDate; d.add(1, "M")) {
        dates.push({
          month: d.format("MMMM"),
          year: d.format("YYYY"),
        });
      }

      return dates.reverse();
    },
  },
  methods: {
    selectDateRange() {
      this.startDate = this.dateRange.startDate.value;
      this.endDate = this.dateRange.endDate.value;

      this.changeDates();
    },

    selectMonth(selectedMonth, selectYear) {
      this.startDate = this.moment(
        `01 ${selectedMonth} ${selectYear}`
      ).toDate();
      this.endDate = new Date(
        this.startDate.getFullYear(),
        this.startDate.getMonth() + 1,
        0
      );

      this.changeDates();
    },

    optionSelected(option, index) {
      this.selectedOptionIndex = index;
      this.hideSelectors();

      switch (option) {
        case "today":
          this.startDate = this.moment().startOf("day");
          this.endDate = this.moment();
          this.changeDates();
          break;
        case "yesterday":
          this.startDate = this.moment().subtract(1, "days").startOf("day");
          this.endDate = this.startDate.endOf("day");
          this.changeDates();
          break;
        case "last-week":
          this.startDate = this.moment().subtract(7, "days").startOf("day");
          this.endDate = this.moment();
          this.changeDates();
          break;
        case "month":
          this.showSelector.month = true;
          break;
        case "custom":
          this.showSelector.custom = true;
          break;
        default:
      }
    },

    hideSelectors() {
      this.showSelector = {
        month: false,
        custom: false,
      };
    },

    changeDates() {
      this.$emit("dateRangeUpdated", this.startDate, this.endDate);
      this.showDateSelector = false;
    },
  },
};
</script>

<style scoped lang="scss">
.selected-range-wrapper {
  text-align: right;

  label {
    display: block;
    color: $greyColor;
    font-size: $smallerFontSize;
    margin-bottom: 0.5rem;
    font-weight: $mediumFontWeight;
  }

  span {
    transition: all 0.3s;
    cursor: pointer;
    padding: 0.85rem 1rem;
    border-radius: 0.75rem;
    background-color: #f7f6ff;
    border: 1px solid #e7e6ef;
    display: block;
    font-size: 0.9rem;
    color: #32099b;

    .unicon /deep/ {
      vertical-align: middle;
      margin-left: 0.5rem;
      margin-right: -0.25rem;
      svg {
        fill: $purpleColor;
        height: 1rem;
        width: 1rem;
      }
    }

    b {
      font-weight: $mediumFontWeight;
    }

    &:hover {
      background-color: $purpleColor;
      color: $whiteColor;

      .unicon /deep/ svg {
        fill: $whiteColor;
      }
    }
  }
}

.date-selector-main {
  position: relative;
}

.date-selector-wrapper {
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background-color: $whiteColor;
  border-radius: 1rem;
  z-index: 100;
  border: 1px solid #e6e6e6;
  box-shadow: 0 0 0.7rem -0.1rem rgba(0, 0, 0, 0.15);

  .date-selector-block {
    width: max-content;
  }
}

.date-range-selector {
  padding: 1rem;
  background-color: $whiteColor;
  margin: 0;

  .date-form /deep/ {
    flex: 1;

    .field-main-item {
      flex: 1;
    }
  }
}
.month-selector {
  padding: 1rem;
  background-color: $whiteColor;
  span {
    transition: all 0.3s;
    cursor: pointer;
    display: block;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #e6e6e6;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      color: $whiteColor;
      background-color: $purpleColor;
    }
  }
}
.option-selector-list {
  width: 12rem;
  background-color: darken($whiteColor, 3%);
  span {
    cursor: pointer;
    display: block;
    padding: 0.5rem 1.5rem;
    border-bottom: 1px solid hsl(0, 0%, 90%);
    color: lighten(#1e1e1e, 20%);

    &:hover {
      background-color: $purpleColor;
      color: $whiteColor;
    }

    &:last-child {
      border-bottom: none;
    }

    &.selected {
      color: lighten($blackColor, 0%);
      color: $blackColor;
      background-color: $whiteColor;
      border-bottom-color: $purpleColor;
    }
  }
}

.btn-primary {
  display: block;
  width: 100%;
}
</style>