<template>
  <div class="modules-wrapper-main">
    <label class="label-small">Modules</label>
    <!--START: Modules Tree-->
    <div class="modules-wrapper">
      <div class="modules">
        <div
          v-for="(module, index) in plan.modules"
          class="module"
          :key="module.id"
          :class="{ active: index == selectedIndex }"
          @click="gotoModule(index)"
        >
          <div class="module-info-wrapper">
            <span class="module-index">{{ index + 1 }}.</span>
            <h3>{{ module.title != null ? module.title : "New Module" }}</h3>
            <unicon name="play"></unicon>
          </div>
        </div>
      </div>
    </div>
    <!--END: Modules Tree-->
  </div>
</template>

<script>
export default {
  name: "ModulesList",
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  components: {},
  methods: {
    gotoModule(index) {
      this.selectedIndex = index;
      this.$emit("moduleSelected", index);
    },
  },
};
</script>

<style scoped lang="scss">
.modules-wrapper {
  position: relative;
  overflow-y: scroll;
  max-height: 25vw;
}

.modules-wrapper-main {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3rem;
    width: 100%;
    z-index: 5;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
}

.label-small {
  display: block;
  font-size: $smallestFontSize;
  font-weight: $mediumFontWeight;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  color: $greyColor;
  margin: 0 0 0.5rem;
}

.no-modules {
  text-align: center;

  em {
    display: block;
    font-size: 3rem;
    margin-bottom: 0.75rem;
  }
  h3 {
    font-size: $normalFontSize;
    font-weight: $normalFontWeight;
    opacity: 0.85;
    line-height: 1.1;
  }
}

.modules {
  margin: 0;
}

.module {
  position: relative;
  transition: all 0.3s;
  display: block;
  cursor: pointer;
  padding: 0.75rem;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #ececec !important;

  .unicon {
    display: none;
    /deep/ svg {
      fill: $whiteColor;
    }
  }

  &:last-child {
    margin-bottom: 2rem;
  }

  .module-info-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    h3 {
      flex: 1;
      color: $blackColor;
      font-size: $smallFontSize;
      font-weight: $normalFontWeight;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 2rem;
      opacity: 0.75;
      margin-bottom: 0;
    }
  }

  .module-index {
    font-size: $smallerFontSize;
    font-weight: $mediumFontWeight;
    color: $blackColor;
    margin-right: 0.35rem;
    margin-left: 0.35rem;
    opacity: 0.65;
  }

  &.active {
    border-radius: 0.5rem;
    background-color: lighten($buttonColor, 5%);
    .module-info-wrapper {
      h3,
      .module-index {
        opacity: 1;
        color: darken($buttonColor, 40%);
      }

      h3 {
        font-weight: $mediumFontWeight;
      }
    }

    .unicon {
      display: block;

      /deep/ svg {
        fill: darken($buttonColor, 35%);
        height: auto;
        width: 1.25rem;
      }
    }
  }
}

//Mobile Styles
@media screen and (max-width: $mobileWidth) {
  .module:last-child {
    border-bottom: none !important;
  }

  .modules-wrapper {
    max-height: 20rem;
  }
}
</style>