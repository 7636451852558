<template>
  <div v-if="bookings != undefined">
    <!--START: Bookings Table-->
    <div v-if="bookings.length != 0">
      <ul class="grid-table">
        <li class="grid-header">
          <div class="grid-item">Order</div>
          <div class="grid-item">Date</div>
          <div class="grid-item">Student</div>
          <div class="grid-item">Email</div>
          <div class="grid-item">Course</div>
        </li>
        <li
          v-for="booking in sortBookingsByDate()"
          :key="booking.id"
          class="grid-row"
        >
          <div class="grid-item booking-order">
            <span v-if="booking.orderID != undefined">
              #{{ booking.orderID }}
            </span>
            <span v-else> - </span>
          </div>
          <div class="grid-item booking-date">
            <span>
              {{ moment(booking.createdAt).format("MMM DD YYYY, hh:mm A") }}
            </span>
          </div>

          <div class="grid-item booking-client">
            <h3>{{ booking.student.name }}</h3>
            <span>+{{ booking.student.phone }}</span>
          </div>

          <div class="grid-item booking-client-email">
            <a :href="`mailto:${booking.student.email}`">{{
              booking.student.email
            }}</a>
          </div>

          <div class="grid-item booking-plan">
            <p>{{ booking.course.title }}</p>
          </div>
        </li>
      </ul>
      <div class="pagination-wrapper">
        <div class="pagination">
          <span
            v-for="index in Math.ceil(totalBookings / bookingsPerPage)"
            class="page-number"
            :class="{ selected: index == currentPage }"
            :key="index"
            @click="getBookings(index)"
          >
            {{ index }}
          </span>
        </div>
        <span class="table-count">Showing {{ bookings.length }} bookings</span>
      </div>
    </div>
    <!--END: Bookings Table-->

    <div v-else class="no-bookings-wrapper">
      <em>🤔</em>
      <span>Hmph, we couldn't find any bookings for these dates</span>
    </div>

    <div
      class="bg-overlay"
      :class="{ show: showBackground }"
      @click="closeModal()"
    ></div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "BookingsTable",
  props: {
    bookings: {
      type: Array,
      required: true,
    },
    totalBookings: {
      type: Number,
      required: true,
    },
    bookingsPerPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showBackground: false,
      showLoader: false,
    };
  },
  methods: {
    sortBookingsByDate() {
      return _.orderBy(this.bookings, "date", "createdAt");
    },

    formatPaymentStatus(status) {
      return status.replace(" ", "-").toLowerCase();
    },

    getPaymentStatus(status) {
      switch (status) {
        case "processed":
        case "processing":
          return "Payment Made";
        case "not applicable":
          return "No Payment";
        default:
          return status;
      }
    },

    getBookings(index) {
      this.$parent.pageNumber = index;
      this.$emit("getBookings");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.line-loader {
  position: fixed;
  bottom: 0;
  z-index: 101;
}

.grid-item {
  align-self: center;

  &:nth-child(1) {
    width: 4rem;
  }
  &:nth-child(2) {
    width: 11rem;
  }
  &:nth-child(3) {
    width: 15rem;
  }
  &:nth-child(4) {
    width: 13rem;
  }
  &:nth-child(5) {
    text-align: left;
    width: calc(100% - 43rem);
  }
}

.booking-order {
  text-align: left;
  span {
    font-size: $smallerFontSize;
  }
}

.booking-client h3 {
  font-size: $normalFontSize;
  margin-bottom: 0;
}

.booking-date,
.booking-client span {
  font-size: $smallerFontSize;
  border-bottom: none;
  color: $greyColor;
}

.booking-client-email a {
  font-size: $smallerFontSize;
  border-bottom: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  padding-right: 2rem;
}

.booking-plan p {
  color: $greyColor;
  font-size: $smallerFontSize;
}

.booking-phone {
  color: $greyColor;
  font-size: $smallerFontSize;
}

.plan-price {
  font-weight: $mediumFontWeight;
  font-size: $smallerFontSize;
}

.payment-date span {
  color: $blackColor;
  opacity: $mediumOpacity;
}

.payment-status {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5rem;
  font-size: $smallestFontSize;
  font-weight: $mediumFontWeight;
  border-radius: 0.5rem;
  padding: 0.3rem 0.5rem;
  line-height: $smallestFontSize;

  span {
    text-transform: capitalize;
    font-size: $smallestFontSize;
    display: inline-block;
    vertical-align: middle;
  }

  &.processing,
  &.processed {
    background-color: lighten($darkGreenColor, 0%);

    span {
      color: darken($greenColor, 50%);
    }
  }

  // &.processing {
  //   background-color: lighten($yellowColor, 0%);

  //   span {
  //     color: darken($yellowColor, 45%);
  //   }
  // }

  &.not-applicable {
    background-color: #d5d5d5;

    span {
      color: #4c4c4c;
    }
  }
}

.payment-amount {
  color: $blackColor;
  opacity: $mediumOpacity;
  font-size: $smallFontSize;

  span {
    font-size: $smallestFontSize;
    margin-right: 2px;
  }
}

.renewal-date {
  font-size: $smallerFontSize;
  color: $greyColor;
  line-height: $smallestFontSize;

  .renewal-date-expired {
    font-size: $smallestFontSize;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    line-height: $smallestFontSize;
    background-color: lighten($redColor, 0%);
    color: lighten($redColor, 45%);
  }
}

.client-actions {
  text-align: right;

  .btn-border {
    margin-right: 0.5rem;

    .unicon {
      line-height: 0rem !important;
    }
  }
}

.slashed-price {
  opacity: 0.5;
  font-size: $smallestFontSize;
  display: block;
  text-decoration: line-through;
}
.discount-used {
  display: block;
  margin-top: 0.5rem;
  color: $greyColor;
  font-size: $smallerFontSize;

  em {
    display: inline-block;
    padding: 0.35rem;
    border-radius: 0.25rem;
    background-color: lighten($purpleColor, 35%);
    font-weight: $mediumFontWeight;
    color: $purpleColor;
  }
}

.btn-delete-client {
  @include transition;

  &:hover {
    background-color: lighten($redColor, 30%);

    /deep/ .unicon svg {
      fill: darken($redColor, 10%);
    }
  }
}

.table-count {
  display: block;
  font-size: $smallestFontSize;
  opacity: $mediumOpacity;
  color: $greyColor;
  margin-left: 2rem;
}

.no-bookings-wrapper {
  text-align: center;
  font-size: $largeFontSize;
  color: $greyColor;

  em {
    font-size: 2rem;
    margin-right: 1rem;
  }

  span {
    font-weight: $mediumFontWeight;
  }
}

.pagination-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
}

.page-number {
  display: inline-block;
  vertical-align: middle;
  padding: 0.5rem;
  color: $greyColor;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  background-color: transparent;
  cursor: pointer;

  &.selected {
    background-color: $purpleColor;
    color: $whiteColor;
  }
}

@media screen and (max-width: $mobileWidth) {
  .grid-header {
    display: none;
  }

  .grid-table {
    border-radius: 0;
  }

  .grid-table {
    border: none;
  }

  .grid-row {
    position: relative;
    text-align: right;
    border: 1px solid $greyBorderColor !important;
    margin-bottom: 1rem;
    border-radius: 1rem;
    display: block;

    &:hover {
      border: 1px solid transparent;
      background-color: $whiteColor;
    }
  }

  .booking-order {
    width: auto;
    margin-bottom: 0.5rem;
  }

  .booking-client-email {
    display: block;
    text-align: left;
    width: auto;
    margin-bottom: 1rem;
  }

  .booking-date {
    text-align: left;
  }

  .booking-client {
    margin: 0.5rem 0;
    width: 100%;
    text-align: left;

    h3 {
      font-size: 1rem;
    }
  }

  .booking-plan {
    text-align: left;
    width: 100% !important;
    margin-bottom: 0.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid lighten($greyBorderColor, 5%);

    p {
      font-size: $normalFontSize;
    }
  }

  .plan-price {
    position: absolute;
    top: 0.75rem;
    right: 1rem;
    width: auto !important;
    font-size: 1.1rem;
  }

  .plan-payment {
    position: absolute;
    right: 0.25rem;
    top: 2.25rem;
    width: auto !important;
    display: inline-block;
    vertical-align: middle;
    text-align: left !important;

    .payment-status {
      margin-top: 3px;
      border-radius: 0.5rem;
      padding: 0.25rem 0.75rem 0.25rem 0.5rem;

      .unicon /deep/ svg {
        display: inline-block;
        vertical-align: middle;
      }

      span {
        display: inline-block;
      }
    }

    .btn {
      float: right;
    }
  }

  .client-actions {
    width: 2rem;
    display: inline-block;
    vertical-align: middle;
  }
}
</style>