<template>
  <div v-if="showComponent">
    <!--START: Module Header-->
    <div class="module-header">
      <div class="module-info-wrapper">
        <h1 class="module-title">{{ moduleOptions.title }}</h1>
        <p class="module-description">{{ moduleOptions.description }}</p>
      </div>
    </div>
    <!--END: Module Header-->
    <div class="column-block course-details-main">
      <div class="course-details-wrapper">
        <!--START: Basic Fields-->
        <FormBuilder
          class="primary-form"
          :fields="fields"
          @fieldChanged="formChanged"
          @input="formChanged"
        ></FormBuilder>
        <!--END: Basic Fields-->
      </div>
    </div>

    <!--START: Page Loader -->
    <LineLoader :showLoader="showLoader"></LineLoader>
    <!--END: Page Loader -->

    <!--START: Status Message-->
    <StatusMessage
      :show="status.show"
      :isSuccess="status.isSuccess"
      :successMessage="status.successMessage"
    ></StatusMessage>
    <!--END: Status Message-->

    <!--START: Unsaved Changes Modal -->
    <ConfirmationModal
      :fields="unsavedChangesFields"
      class="unsaved-changes-modal"
      @primaryEvent="continueRoute"
      @secondaryEvent="hideBackground"
    ></ConfirmationModal>
    <!--END: Unsaved Changes Modal -->

    <div
      class="bg-overlay"
      :class="{ show: showBackground }"
      @click="hideBackground()"
    ></div>
  </div>
</template>
    
<script>
//Import components
import LineLoader from "@/components/loaders/LineLoader";
import FormBuilder from "@/components/form/FormBuilder";
import StatusMessage from "@/components/modals/StatusMessage";
import ConfirmationModal from "@/components/modals/ConfirmationModal";

export default {
  name: "BuilderSettings",
  props: {
    plan: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showLoader: false,
      showComponent: false,
      showBackground: false,
      showPageLoader: false,
      fields: {
        name: {
          type: "text",
          title: "Course Name*",
          additionalClass: "large-field",
          placeholder: "Add the name of the course",
          required: true,
          hasError: false,
          value: "",
        },
        description: {
          type: "text",
          title: "Certificate Description*",
          placeholder:
            'A 15" x 20" painting course of Lord Balaji in January 2024',
          required: true,
          hasError: false,
          value: "",
        },
        additionalDetails: {
          type: "group",
          nestFields: false,
          fields: {
            level: {
              type: "dropdown",
              title: "Course Level*",
              placeholder: "Select a level",
              required: true,
              hasError: false,
              fields: [
                { name: "Beginner", value: "Beginner" },
                { name: "Intermediate", value: "Intermediate" },
                { name: "Advanced", value: "Advanced" },
              ],
              value: null,
              readOnly: false,
            },
            location: {
              type: "dropdown",
              title: "Course Location*",
              placeholder: "Select where the course is conducted",
              required: true,
              hasError: false,
              fields: [
                { name: "Studio", value: "Studio" },
                { name: "Online", value: "Online" },
              ],
              value: null,
              readOnly: false,
            },
          },
        },
      },

      moduleOptions: {
        title: "Certificate",
        description: "Add certification details for your course",
      },
      toRoute: null,
      unsavedChangesFields: {
        show: false,
        title: "You Have Unsaved Changes",
        message:
          "Your changes will be lost. Are you sure you want to continue?",
        type: "delete",
        primaryBtn: {
          title: "Yes, Continue",
        },
        secondaryBtn: {
          title: "Cancel",
        },
      },
      status: {
        show: false,
        isSuccess: true,
        successMessage: "🙌 Changes saved",
        errorMessage: "😕 Something's not right. Try again",
      },
    };
  },
  components: {
    LineLoader,
    StatusMessage,
    FormBuilder,
    ConfirmationModal,
  },
  computed: {},
  async created() {
    this.showComponent = true;
    this.initData();
  },
  methods: {
    initData() {
      const { certificate } = this.plan;
      if (certificate) {
        this.fields.name.value = certificate.name;
        this.fields.description.value = certificate.description;

        this.fields.additionalDetails.fields.level.value = certificate.level
          ? [certificate.level, certificate.level]
          : null;

        this.fields.additionalDetails.fields.location.value =
          certificate.location
            ? [certificate.location, certificate.location]
            : null;
      }
    },

    compareState() {
      this.prepareSettingsData();

      this.$parent.saveModule = "certificate";
      this.$emit("compareState");
    },

    prepareSettingsData() {
      const data = this.parseData(this.fields);
      this.$parent.plan.certificate = data;
    },

    formChanged() {
      this.compareState();
    },

    hideBackground() {
      this.showBackground = false;
      this.unsavedChangesFields.show = false;
    },

    continueRoute() {
      this.$parent.disableButton = true;

      if (this.toRoute != null) this.$router.push({ path: this.toRoute.path });
      else {
        this.$emit("resetPlan");
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.$parent.disableButton) {
      this.toRoute = to;
      this.showBackground = true;
      this.unsavedChangesFields.show = true;
    } else {
      this.$emit("resetPlan");
      next();
    }
  },
};
</script>
    
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.course-details-wrapper {
  flex: 1;
}
.column-block {
  margin: 7rem 15% 2rem;
  background-color: $whiteColor;
  padding: 1rem;
  border: 1px solid #ececec;
  border-radius: 1rem;
  margin-bottom: 2rem;

  .block-label {
    font-size: 1rem;
    color: $blackColor;
    font-weight: $mediumFontWeight;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  .block-description {
    color: $greyColor;
    margin: 1rem 0 1.25rem;
  }
}

.primary-form {
  /deep/ {
    .field-main-item:last-child {
      .field-toggle-item {
        flex-direction: row !important;

        label {
          margin-left: 1rem;
        }
      }
    }
  }
}

.access-wrapper {
  .block-description {
    margin: 1rem 0 0;
    span {
      display: inline-block;
      margin-top: 0.15rem;
      margin-right: 0.5rem;
    }

    &:last-child {
      margin: 0.15rem 0 1.25rem;
    }
  }
}

.course-details-main {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
</style>