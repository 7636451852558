<template>
  <div class="footer">
    <img src="@/assets/images/logo.png" class="logo" alt="Logo" />
    <span>&copy; Tanjore Collective. All Rights Reserved.</span>
  </div>
</template>
  
  
<script>
export default {
  name: "StudentFooter",
  data() {
    return {};
  },
  components: {},
  async created() {},
  methods: {},
};
</script>
  
<style scoped lang="scss">
.footer {
  text-align: center;
  padding: 8rem 1.5rem 2rem;
  background-color: $lightWhiteColor;

  .logo {
    display: block;
    margin: 0 auto 0.5rem;
    width: 6.5rem;
  }

  span {
    font-size: $smallerFontSize;
    line-height: 1.4;
    display: inline-block;
    vertical-align: middle;
    color: $greyColor;
  }
}

@media screen and (max-width: $mobileWidth) {
  .footer {
    padding: 16rem 1.5rem 3rem;
  }
}
</style>