<template>
  <div v-if="showComponent">
    <!-- START: Module Header -->
    <div class="module-header" ref="moduleHeader">
      <div class="module-info-wrapper">
        <h1 class="module-title">{{ moduleOptions.title }}</h1>
        <p class="module-description">{{ moduleOptions.description }}</p>
      </div>
    </div>
    <!-- END: Module Header -->

    <div class="builder-wrapper">
      <!--START: Module View-->
      <ModulesTree
        :plan="plan"
        class="modules-tree"
        @addModule="addModule"
        @moduleSelected="showModule"
        @modulesChanged="compareState"
      ></ModulesTree>

      <ModuleBuilder
        class="module-builder"
        :module="selectedModule"
        @moduleUpdated="updateSelectedModule"
      ></ModuleBuilder>
      <!--END: Module View-->
    </div>

    <!--START: Page Loader -->
    <PageLoader
      class="page-loader-wrapper"
      :showLoader="showLoader"
    ></PageLoader>
    <!--END: Page Loader -->

    <!--START: Workout Delete Modal-->
    <ConfirmationModal
      :fields="routeFields"
      @primaryEvent="continueRoute"
      @secondaryEvent="closeModal"
    ></ConfirmationModal>
    <!--END: Workout Delete Modal-->

    <!--START: Loaders-->
    <div
      class="bg-overlay"
      :class="{ show: showBackground }"
      @click="hideBackground()"
    ></div>
    <!--END: Loaders-->
  </div>
</template>

<script>
//Importing components
import PageLoader from "@/components/loaders/PageLoader";
import ConfirmationModal from "@/components/modals/ConfirmationModal";
import ModulesTree from "@/components/liveCourse/ModulesTree";
import ModuleBuilder from "@/components/liveCourse/ModuleBuilder";

export default {
  name: "LiveCourseBuilder",
  props: {
    plan: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showComponent: false,
      CTAText: "Save Course",
      showBackground: false,
      moduleOptions: {
        title: "Course Builder",
        description: "Add modules to your course and create your course",
      },
      selectedModule: {},
      selectedModuleIndex: 0,
      showLoader: false,
      showOptions: {
        assignWorkout: false,
      },
      routeFields: {
        show: false,
        title: "You still have unsaved changes!",
        type: "delete",
        message: "Do you want to continue without saving?",
        primaryBtn: {
          title: "Yes, continue",
        },
        secondaryBtn: {
          title: "Cancel",
        },
      },
      nextRoute: null,
    };
  },
  components: {
    PageLoader,
    ConfirmationModal,
    ModulesTree,
    ModuleBuilder,
  },
  async created() {
    this.showComponent = true;
    this.initModules();
  },
  methods: {
    initModules() {
      if (this.plan.modules == undefined || !this.plan.modules.length) {
        this.$parent.plan.modules = [];
        this.$parent.plan.modules.push({
          videoID: null,
          title: null,
          description: null,
          visibility: {
            isVisible: true,
            numberOfDays: 0,
          },
        });
      }
      this.selectedModuleIndex = 0;
      this.selectedModule = this.plan.modules[this.selectedModuleIndex];
    },
    addModule() {
      this.$parent.plan.modules.push({
        videoID: null,
        title: null,
        description: null,
        visibility: {
          isVisible: true,
          numberOfDays: 0,
        },
      });
      this.selectedModuleIndex = this.plan.modules.length - 1;
      this.selectedModule = this.plan.modules[this.selectedModuleIndex];
    },

    showModule(index) {
      this.selectedModuleIndex = index;
      this.selectedModule = this.plan.modules[this.selectedModuleIndex];
    },

    updateSelectedModule(module) {
      this.plan.modules.splice(this.selectedModuleIndex, 1, module);
      this.compareState();
    },

    compareState() {
      this.$parent.plan = this.plan;
      this.$parent.saveModule = "modules";
      this.$emit("compareState");
    },

    continueRoute() {
      this.$parent.disableButton = true;
      this.$router.push({ path: this.nextRoute.path });
    },

    closeModal() {
      this.showBackground = false;
      this.routeFields.show = false;
    },
  },
  beforeRouteLeave(to, from, next) {
    this.nextRoute = to;
    if (this.$parent.disableButton == false) {
      this.showBackground = true;
      this.routeFields.show = true;
    } else {
      this.$emit("resetPlan");
      next();
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.line-loader {
  position: fixed;
  bottom: 0;
  z-index: 10;

  &.animate {
    height: 15px;
  }
}

.page-loader-wrapper {
  padding-top: 12rem;
}

.status-message {
  position: fixed;
  left: 0;
  right: 0;
  bottom: auto;
  top: 7.5rem;
  z-index: 11;
  width: max-content;
  margin: auto;

  /deep/ span {
    font-weight: $mediumFontWeight;
  }
}

.dashboard-wrapper {
  position: relative;
  padding: 0 0 2rem;
}

.module-header {
  position: fixed;
  top: 3.75rem;
  left: 0;
  width: calc(100% - 6rem);
  margin: 0;
  z-index: 10 !important;
  padding: 1.15rem 3rem;
  background-color: $whiteColor;
  border-bottom: 1px solid darken($whiteColor, 10%);
  box-shadow: 0 0.3rem 0.7rem -0.1rem rgba(95, 71, 71, 0.15);
  justify-content: normal;
  align-items: stretch;
  flex-direction: row;

  .module-info-wrapper,
  .builder-tools,
  .module-actions-wrapper {
    align-self: flex-end;
  }

  .module-info-wrapper {
    flex: 1 !important;
    margin-right: 3.5rem;
  }

  .module-actions-wrapper {
    position: relative !important;
    top: auto !important;
    right: auto !important;
    text-align: right;
    margin-bottom: 0;
  }
}

.builder-tools {
  position: relative;
  display: flex;
  align-items: stretch;
  padding-right: 1.25rem;
  margin-right: 0.5rem;
  flex-direction: row;

  &::before {
    @include vertical-center-align;
    display: block;
    content: "";
    width: 1px;
    height: 50%;
    right: 0;
    background-color: $greyBorderColor;
  }
}

.builder-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 6rem 2rem 2rem;
  .modules-tree {
    width: 23rem;
    margin-right: 2rem;
  }
}

.tooltip-wrapper {
  .tooltip {
    opacity: 1;
    display: none;
  }

  &:hover .tooltip {
    z-index: 5;
    display: block;
    bottom: -1.5rem;
    width: max-content;
  }
}

.module-builder-wrapper {
  flex: 1;
  text-align: center;
}

.module-builder {
  width: calc(100% - 25rem)
}

@media screen and (min-width: 800px) {
  .module-actions-wrapper,
  .module-info-wrapper {
    flex: none !important;
  }
}
</style>