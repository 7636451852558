<template>
  <div v-if="showComponent" class="workouts-module">
    <!-- START: Module Header -->
    <div class="module-header">
      <div class="module-info-wrapper">
        <h1 class="module-title">Frequently Asked Questions</h1>
      </div>
    </div>
    <!-- END: Module Header -->

    <ul class="faq-list">
      <li
        class="faq-item"
        v-for="(faq, i) in plan.faqs"
        :key="i"
        :class="{ show: i === activeFAQ }"
        @click="toggleFAQ(i)"
      >
        <div class="question">
          <h3>{{ faq.question }}</h3>
          <span class="faq-icon"></span>
        </div>
        <div class="answer" v-html="faq.answer"></div>
      </li>
    </ul>

    <LineLoader :showLoader="showLoader"></LineLoader>
    <div
      class="bg-overlay"
      :class="{ show: showBackground }"
      @click="closeModal"
    ></div>
  </div>
</template>

<script>
//Import components
import LineLoader from "@/components/loaders/LineLoader";

export default {
  name: "ClientFAQs",
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showComponent: false,
      showBackground: false,
      client: {},
      showLoader: false,
      activeFAQ: null,
    };
  },
  async created() {
    if ((await this.authenticateClient("sign-in", false)) == false)
      this.$router.push({
        path: "sign-in",
      });
    this.client = this.$store.state.client.client;

    this.showComponent = true;
  },

  components: {
    LineLoader,
  },
  methods: {
    toggleFAQ(faqIndex) {
      if (this.activeFAQ == faqIndex) {
        this.activeFAQ = null;
      } else this.activeFAQ = faqIndex;
    },
    closeModal() {
      this.showBackground = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.module-header {
  position: relative;
  top: auto;
  left: auto;
  width: auto;
  display: block;
  padding: 2rem 2.5rem;
  background: transparent;
  text-align: center;
  z-index: 10;

  /deep/ .module-title {
    font-family: $headingFont;
    font-size: 2rem;
    margin: 2rem 1.5rem 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .module-actions-wrapper,
  .module-info-wrapper {
    align-self: center;
  }

  .modal-close {
    display: none;
  }
}

.faq-list {
  margin: 0 12% 3rem;
}

.faq-item {
  transition: all 0.3s;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  background-color: $whiteColor;
  border: 1px solid #dbdbdb;
  cursor: pointer;

  .question {
    position: relative;

    h3 {
      transition: all 0.3s;
      color: $blackColor;
      width: 80%;
      font-size: $mediumFontSize;
      font-weight: $mediumFontWeight;
      opacity: $mediumOpacity;
    }

    .faq-icon {
      position: absolute;
      right: 0;
      top: 0.5rem;
      height: 1rem;
      width: 1rem;

      &::after,
      &::before {
        content: "";
        transition: all 0.3s;
        display: block;
        position: absolute;
        background-color: darken($lightWhiteColor, 35%);
      }

      &::after {
        top: 50%;
        transform: translateY(-50%);
        height: 100%;
        width: 2px;
        left: -1px;
      }

      &::before {
        top: calc(50% - 2px);
        transform: translateX(-50%);
        width: 100%;
        height: 2px;
      }
    }
  }

  .answer {
    display: none;

    /deep/ {
      * {
        color: #332711;
        font-size: 0.95rem;
      }

      ul {
        list-style-type: disc !important;
        margin-left: 1.5rem;
      }

      ol {
        list-style-type: decimal !important;
        margin-left: 1.5rem;
      }

      li {
        margin-bottom: 0.25rem;
      }

      p {
        line-height: 1.6;
      }
    }
  }

  &:hover {
    background-color: $whiteColor;
    border-color: darken($lightWhiteColor, 25%);
    box-shadow: 0 0.25rem 0.7rem -0.15rem rgba(0, 0, 0, 0.15);
  }

  &.show {
    background-color: $whiteColor;
    border-color: darken($lightWhiteColor, 25%);

    h3 {
      opacity: 1 !important;
      margin-bottom: 1.25rem;
    }

    .answer {
      display: block;
    }

    .faq-icon::after {
      height: 0;
    }
  }
}

.module-actions-wrapper {
  display: flex;
  text-align: right;
}

@media screen and (max-width: $mobileWidth) {
  .module-header {
    position: relative;
    width: auto;
    padding: 1.5rem 1rem 2rem;
    margin-bottom: 0;

    .module-title {
      font-size: 1.25rem;
      margin: 0;
    }

    .modal-close {
      display: block;
      border: none;
      top: 1rem;
      right: 1rem;
    }
  }

  .module-actions-wrapper {
    display: none;
  }

  .faq-list {
    margin: 0 1rem 3rem;
  }

  .faq-item {
    .question h3 {
      font-size: $normalFontSize;
    }
  }
}
</style>