<template>
  <div v-if="showComponent">
    <!--START: Module Header-->
    <div class="module-header">
      <div class="module-info-wrapper">
        <h1 class="module-title">{{ moduleOptions.title }}</h1>
        <p class="module-description">{{ moduleOptions.description }}</p>
      </div>
    </div>
    <!--END: Module Header-->

    <div class="column-block course-details-main">
      <!--START: Cover Image & Video-->
      <div class="cover-image-wrapper">
        <div class="cover-media-wrapper">
          <!--START: Cover Image Uploader-->
          <div class="image-block">
            <label class="field-title">{{
              coverMediaFields.coverImageURL.title
            }}</label>
            <div class="rel-wrapper">
              <!-- START: File Upload Fields -->
              <FileUploadLarge
                class="image-uploader"
                :field="coverMediaFields.coverImageURL"
                :class="{
                  'has-image':
                    (plan.coverImageURL != undefined &&
                      plan.coverImageURL != false) ||
                    croppedImageURL != null,
                }"
                @fileUploaded="checkImageUpload()"
              ></FileUploadLarge>
              <!-- End: File Upload Fields -->

              <!--START: Cropped & Selected Image Preview-->
              <div
                class="uploaded-image-wrapper"
                v-if="
                  (plan.coverImageURL != undefined &&
                    plan.coverImageURL != false) ||
                  croppedImageURL != null
                "
              >
                <img
                  :src="
                    croppedImageURL != null
                      ? croppedImageURL
                      : plan.coverImageURL
                  "
                  alt="Cover image"
                />
                <span>Change Cover Image</span>
              </div>
              <!--END: Cropped & Selected Image Preview-->
            </div>
          </div>
          <!--END: Cover Image Uploader-->
        </div>
      </div>
      <!--END: Cover Image & Video-->

      <div class="course-details-wrapper">
        <!--START: Basic Fields-->
        <FormBuilder
          class="primary-form"
          :fields="fields"
          @fieldChanged="formChanged"
          @input="formChanged"
        ></FormBuilder>
        <!--END: Basic Fields-->

        <!--START: Access Days Settings-->
        <div v-if="!fields.lifetimeAccess.value" class="access-wrapper">
          <div class="block-info">
            <span class="block-label">Access Settings</span>
            <p class="block-description">
              Choose for how many days or weeks, customers get access to this
              after purchase
            </p>
            <p class="block-description">
              <span><b>Note:</b></span
              >This is calculated from when the customer buys the course
            </p>
          </div>
          <FormBuilder
            class="days-form"
            :fields="daysFields"
            @fieldChanged="formChanged"
          ></FormBuilder>
        </div>
        <!--END: Access Days Settings-->
      </div>
    </div>

    <!--START: Page Loader -->
    <LineLoader :showLoader="showLoader"></LineLoader>
    <!--END: Page Loader -->

    <!--START: Status Message-->
    <StatusMessage
      :show="status.show"
      :isSuccess="status.isSuccess"
      :successMessage="status.successMessage"
    ></StatusMessage>
    <!--END: Status Message-->

    <!--START: Unsaved Changes Modal -->
    <ConfirmationModal
      :fields="unsavedChangesFields"
      class="unsaved-changes-modal"
      @primaryEvent="continueRoute"
      @secondaryEvent="hideBackground"
    ></ConfirmationModal>
    <!--END: Unsaved Changes Modal -->

    <!--START: Image Cropper -->
    <ImageCropper
      :show="showImageCropper"
      :imageURL="imageCropperURL"
      :cropType="['rectangle', 'tall-square']"
      class="image-cropper-module"
      @closeImageCropper="closeImageCropper"
      @saveImage="saveCroppedImage"
    ></ImageCropper>
    <!--END: Image Cropper -->

    <div
      class="bg-overlay"
      :class="{ show: showBackground }"
      @click="hideBackground()"
    ></div>
  </div>
</template>

<script>
//Import components
import LineLoader from "@/components/loaders/LineLoader";
import FormBuilder from "@/components/form/FormBuilder";
import StatusMessage from "@/components/modals/StatusMessage";
import ConfirmationModal from "@/components/modals/ConfirmationModal";
import FileUploadLarge from "@/components/form/FileUploadLarge";
import ImageCropper from "@/components/form/ImageCropper";

export default {
  name: "BuilderSettings",
  props: {
    plan: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showLoader: false,
      showComponent: false,
      showBackground: false,
      showPageLoader: false,
      showImageCropper: false,
      imageCropperURL: null,
      croppedImageURL: null,
      fields: {
        title: {
          type: "text",
          title: "Title*",
          additionalClass: "large-field",
          placeholder: "Add your title",
          required: true,
          hasError: false,
          value: this.plan.title != undefined ? this.plan.title : null,
        },
        lifetimeAccess: {
          type: "toggle",
          value: false,
          placeholder: "Give Customer Lifetime Access",
        },
      },
      coverMediaFields: {
        coverImageURL: {
          type: "file-large",
          title: "Cover Image",
          fileType: "image",
          fileList: [],
          imagesList: [],
          single: true,
          showPreview: true,
          persistUploader: true,
          required: false,
          hasError: false,
        },
      },
      daysFields: {
        days: {
          type: "number",
          title: "Number",
          placeholder: "0",
          required: false,
          hasError: false,
        },
        unit: {
          type: "dropdown",
          title: "Type",
          placeholder: "Choose Type",
          required: true,
          hasError: false,
          fields: [
            { name: "Days", value: "days" },
            { name: "Weeks", value: "weeks" },
          ],
          value: ["Days", "days"],
        },
      },
      moduleOptions: {
        title: "Details & Information",
        description: "Add details and information about the course",
      },
      toRoute: null,
      unsavedChangesFields: {
        show: false,
        title: "You Have Unsaved Changes",
        message:
          "Your changes will be lost. Are you sure you want to continue?",
        type: "delete",
        primaryBtn: {
          title: "Yes, Continue",
        },
        secondaryBtn: {
          title: "Cancel",
        },
      },
      status: {
        show: false,
        isSuccess: true,
        successMessage: "🙌 Changes saved",
        errorMessage: "😕 Something's not right. Try again",
      },
    };
  },
  components: {
    LineLoader,
    StatusMessage,
    FormBuilder,
    ConfirmationModal,
    FileUploadLarge,
    ImageCropper,
  },
  computed: {
    imagesUploaded: function () {
      if (this.coverMediaFields.coverImageURL.fileList.length > 0) return true;
      else return false;
    },
    getUploadedImage: function () {
      if (this.imagesUploaded) {
        var imagesLength =
          this.coverMediaFields.coverImageURL.imagesList.length;
        var selectedFile =
          this.coverMediaFields.coverImageURL.imagesList[imagesLength - 1];
        return selectedFile;
      } else return null;
    },
  },
  async created() {
    this.showComponent = true;
    this.initSettings();
  },
  methods: {
    initSettings() {
      this.plan.id = this.$route.params.courseID;

      if (this.plan.id != null && this.plan.id != undefined) {
        this.daysFields.days.value =
          this.plan.accessSettings.days != undefined
            ? this.plan.accessSettings.days
            : null;

        this.fields.lifetimeAccess.value =
          this.plan.accessSettings.lifetimeAccess != undefined
            ? this.plan.accessSettings.lifetimeAccess
            : false;
      }
    },

    compareState() {
      this.prepareSettingsData();

      this.$parent.saveModule = "settings";
      this.$emit("compareState");
    },

    prepareSettingsData() {
      let selectedUnit = this.daysFields.unit.value;
      let numberOfDays = this.daysFields.days.value;

      if (selectedUnit[1] == "weeks") selectedUnit = 7;
      else selectedUnit = 1;

      this.$parent.plan.title = this.fields.title.value;
      this.$parent.plan.accessSettings = {
        lifetimeAccess: this.fields.lifetimeAccess.value,
        days: numberOfDays * selectedUnit,
      };
    },

    //START: Cover Image Cropper
    checkImageUpload() {
      if (this.imagesUploaded) {
        this.imageCropperURL = this.getUploadedImage;
        this.showImageCropper = true;
      } else {
        this.showImageCropper = false;
      }
    },

    closeImageCropper() {
      this.coverMediaFields.coverImageURL.fileList.splice(0);
      this.coverMediaFields.coverImageURL.imagesList.splice(0);
      this.checkImageUpload();
    },

    saveCroppedImage(imageBlob, imageURL) {
      this.showImageCropper = false;
      this.croppedImageBlob = imageBlob;
      this.croppedImageURL = imageURL;

      this.$parent.plan.coverImage = {};
      if (this.croppedImageBlob != null) {
        this.$parent.plan.coverImage = {
          blob: [this.croppedImageBlob],
          name: this.coverMediaFields.coverImageURL.fileList[0].name,
        };
      }

      this.formChanged();
    },
    //END: Cover Image Cropper

    formChanged() {
      this.compareState();
    },

    hideBackground() {
      this.showBackground = false;
      this.unsavedChangesFields.show = false;
    },

    continueRoute() {
      this.$parent.disableButton = true;

      if (this.toRoute != null) this.$router.push({ path: this.toRoute.path });
      else {
        this.$emit("resetPlan");
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.$parent.disableButton) {
      this.toRoute = to;
      this.showBackground = true;
      this.unsavedChangesFields.show = true;
    } else {
      this.$emit("resetPlan");
      next();
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.column-block {
  margin: 7rem 15% 2rem;
  background-color: $whiteColor;
  padding: 1rem;
  border: 1px solid #ececec;
  border-radius: 1rem;
  margin-bottom: 2rem;

  .block-label {
    font-size: 1rem;
    color: $blackColor;
    font-weight: $mediumFontWeight;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  .block-description {
    color: $greyColor;
    margin: 1rem 0 1.25rem;
  }
}

.primary-form {
  /deep/ {
    .field-main-item:last-child {
      .field-toggle-item {
        flex-direction: row !important;

        label {
          margin-left: 1rem;
        }
      }
    }
  }
}

.access-wrapper {
  .block-description {
    margin: 1rem 0 0;
    span {
      display: inline-block;
      margin-top: 0.15rem;
      margin-right: 0.5rem;
    }

    &:last-child {
      margin: 0.15rem 0 1.25rem;
    }
  }
}

.days-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  /deep/ {
    .field-main-item {
      flex: 1;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.image-block {
  margin: 0 0 1.5rem;
  text-align: center;

  .field-title {
    text-align: left;
  }

  .uploaded-image-wrapper {
    transition: all 0.3s;
    position: relative;
    margin: auto;
    overflow: hidden;
    border: 3px solid transparent;

    img {
      display: block;
      position: relative;
      border-radius: 1rem;
      margin: auto;
      width: 100%;
    }
  }

  span {
    display: block;
    color: $purpleColor;
    margin: 0.5rem 0;
  }

  .uploaded-image {
    /deep/ .field-main-item:last-child {
      display: none;
    }
  }

  .image-uploader {
    &.has-image {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      width: 15rem;
      height: 100%;
      opacity: 0;
      z-index: 1;
      margin: auto;

      /deep/ .file-upload-large {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      /deep/ label {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: calc(100% - 4rem);
        height: calc(100% - 2rem);
        margin: auto;
      }

      /deep/ .image-preview {
        display: none;
      }
    }
  }
}

.course-details-main {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .cover-image-wrapper {
    width: 15rem;
    margin-right: 2rem;
  }
}
</style>