<template>
  <div class="coach-settings-modal" :class="{ show: show }">
    <!--START: Header-->
    <div class="modal-header">
      <unicon name="angle-down" @click="closeModal" />
      <h3>Add Guide</h3>
    </div>
    <!--END: Header-->

    <div class="scroll-wrapper">
      <!--START: Guide-->
      <form class="coach-form" @change="formChanged">
        <div
          class="guide-image-block"
          v-if="
            guideObject.coverImageURL != undefined &&
            fields.coverImageURL.fileList.length == 0
          "
        >
          <div class="guide-image-wrapper">
            <img :src="guideObject.coverImageURL" alt="Cover image" />
          </div>
          <span>Change Guide Image</span>
        </div>
        <FormBuilder
          :fields="fields"
          @fieldChanged="formChanged"
          :class="
            guideObject.coverImageURL != undefined &&
            fields.coverImageURL.fileList.length == 0
              ? 'has-image'
              : ''
          "
        ></FormBuilder>
      </form>
      <!--END: Guide-->
    </div>

    <button
      class="btn btn-primary"
      :disabled="disableButton"
      @click="updateCoach"
    >
      Save Guide
    </button>

    <!--START: Page Loader -->
    <LineLoader :showLoader="showLoader"></LineLoader>
    <!--END: Page Loader -->

    <!--START: Status Message-->
    <StatusMessage
      :show="status.show"
      :isSuccess="status.isSuccess"
      :successMessage="status.successMessage"
      :errorMessage="status.errorMessage"
    ></StatusMessage>
    <!--END: Status Message-->
  </div>
</template>
  
  <script>
//Service for coach
import DiyPlanService from "@/controllers/DiyPlanService";
import VideoCourseService from "@/controllers/VideoCourseService";
import OnlineCourseService from "@/controllers/OnlineCourseService";

//Import components
import LineLoader from "@/components/loaders/LineLoader";
import StatusMessage from "@/components/modals/StatusMessage";
import FormBuilder from "@/components/form/FormBuilder";

export default {
  name: "CoachAddPlanGuide",
  data() {
    return {
      showLoader: false,
      disableButton: true,
      fields: {
        coverImageURL: {
          type: "file-large",
          title: "Cover Image",
          fileType: "image",
          fileInstruction: "Recommended size - 300 x 300 pixels",
          fileList: [],
          imagesList: [],
          single: true,
          showPreview: true,
          required: false,
          hasError: false,
        },
        videoURL: {
          type: "text",
          title: "YouTube Video URL",
          placeholder: "Add a video URL as an explainer",
          required: false,
          hasError: false,
          value: this.guideObject.videoURL,
        },
        title: {
          type: "text",
          title: "Title*",
          placeholder: "Add a title",
          required: true,
          hasError: false,
          value: this.guideObject.title,
        },
        description: {
          type: "text-editor",
          title: "Guide Information*",
          placeholder: "Add information about this guide",
          required: true,
          hasError: false,
          value: this.guideObject.description,
        },
      },
      status: {
        show: false,
        isSuccess: true,
        successMessage: "🙌 Changes saved",
        errorMessage: "😕 Something's not right. Try again",
      },
    };
  },
  props: {
    guideObject: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    guideObject: {
      handler: function () {
        this.fields.videoURL.value = this.guideObject.videoURL;
        this.fields.title.value = this.guideObject.title;
        this.fields.description.value = this.guideObject.description;
      },
      immediate: true,
    },
  },
  components: {
    LineLoader,
    StatusMessage,
    FormBuilder,
  },
  async created() {
    this.resetFields(this.fields);
  },
  methods: {
    async updateCoach() {
      var timeoutHandler = null;

      clearTimeout(timeoutHandler);

      this.showStatus = true;
      this.isSuccess = false;
      this.showLoader = true;

      //Check if the form has valid input
      var formFields = {
        ...this.fields,
      };

      var formValidation = this.validateForm(formFields);

      if (formValidation.hasErrors) {
        this.status.isSuccess = false;
      } else if (!this.disableButton) {
        if (this.guideObject._id != undefined)
          formFields.guideID = this.guideObject._id;

        formFields.planID = this.$parent.plan._id;
        formFields.planTitle = this.$parent.plan.title;

        var formData = new FormData();

        var value;
        for (var key in formFields) {
          if (typeof formFields[key] === "object") {
            value = JSON.stringify(formFields[key]);
          } else value = formFields[key];
          formData.append(key, value);
        }

        formData.delete("coverImageURL");
        if (this.fields.coverImageURL.fileList.length > 0)
          for (const i of Object.keys(this.fields.coverImageURL.fileList)) {
            formData.append("images", this.fields.coverImageURL.fileList[i]);
          }
        else formData.append("coverImageURL", this.guideObject.coverImageURL);

        //Check if it's a clients plan or a new template
        if (
          this.$route.meta.customerPlan != undefined &&
          this.$route.meta.customerPlan
        ) {
          formData.append("userType", "client");
        }

        //Check for type of course - Video / Online Course / Online Plan
        var response;
        if (
          this.$route.meta.subCategory != undefined &&
          this.$route.meta.subCategory == "videoCourseBuilder"
        ) {
          response = await VideoCourseService.SaveGuide(formData);
        } else if (
          this.$route.meta.subCategory != undefined &&
          this.$route.meta.subCategory == "onlineCourseBuilder"
        ) {
          response = await OnlineCourseService.SaveGuide(formData);
        } else response = await DiyPlanService.SaveGuide(formData);

        if (!response.hasError) {
          this.status.isSuccess = true;
          this.$emit("guideSaved", response.data);
        } else {
          this.status.isSuccess = false;
          this.status.errorMessage = response.error;
        }

        this.resetFields(this.fields);
        this.showSuccessMessage();
        this.closeModal();
      }

      this.status.show = true;
      this.disableButton = true;
      this.showLoader = false;

      //Hide the notification
      timeoutHandler = setTimeout(() => (this.status.show = false), 3000);
    },

    formChanged() {
      this.disableButton = false;
    },

    hideBackground() {
      this.showBackground = false;
    },

    showSuccessMessage() {
      this.$parent.status.isSuccess = true;
      this.$parent.status.show = true;
      setTimeout(() => (this.$parent.status.show = false), 3000);
    },

    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
  
  <style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

/deep/ .image-preview {
  text-align: center;

  .image-preview-wrapper {
    width: 70%;
    margin: auto;
  }
}

.guide-image-block {
  margin-top: 1rem;
  text-align: center;

  .guide-image-wrapper {
    position: relative;
    width: 80vw;
    height: 80vw;
    max-height: 15rem;
    max-width: 15rem;
    margin: auto;
    border-radius: 1rem;
    overflow: hidden;
  }

  .guide-image-wrapper img {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  span {
    display: block;
    color: $purpleColor;
    margin: 0.5rem 0;
  }
}

.has-image {
  position: relative;
  margin-top: 2rem;

  /deep/ .file-upload-large {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  /deep/ .field-main-item:nth-child(1) {
    opacity: 0;
    position: absolute;
    height: 3rem;
    width: calc(100% - 3rem);
    transform: translateY(-3rem);
    margin-top: -2rem;
    overflow: hidden;
  }
}
</style>