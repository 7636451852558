<template>
  <div>
    <div class="image-cropper-wrapper" :class="{ show: show }">
      <div class="image-block-wrapper">
        <VueCropper
          ref="cropper"
          :src="imageURL"
          :viewMode="imageCropper.viewMode"
          :aspectRatio="imageCropper.aspectRatio"
          :zoomable="imageCropper.zoomable"
          :background="imageCropper.background"
          alt="Source Image"
          class="image-cropper"
        ></VueCropper>
        <div class="image-cropper-toolbar">
          <div class="image-cropper-tools">
            <div
              v-if="checkCropType('square')"
              class="image-cropper-toolbar-icon"
              @click="$refs.cropper.setAspectRatio(1)"
            >
              <unicon name="vector-square"></unicon>
              <span>Square</span>
            </div>
            <div
              v-if="checkCropType('rectangle')"
              class="image-cropper-toolbar-icon"
              @click="$refs.cropper.setAspectRatio(1200 / 630)"
            >
              <unicon name="panorama-h-alt"></unicon>
              <span>Rectangle</span>
            </div>
            <div
              v-if="checkCropType('tall-square')"
              class="image-cropper-toolbar-icon"
              @click="$refs.cropper.setAspectRatio(1000 / 950)"
            >
              <unicon name="vector-square"></unicon>
              <span>Tall Square</span>
            </div>
            <div
              class="image-cropper-toolbar-icon"
              @click="$refs.cropper.rotate(-90)"
            >
              <unicon name="crop-alt-rotate-right"></unicon>
              <span>Rotate</span>
            </div>
          </div>

          <div class="image-cropper-actions">
            <button
              class="btn btn-small btn-border"
              @click="closeImageCropper()"
            >
              Cancel
            </button>
            <button class="btn btn-small btn-save-image" @click="saveImage()">
              Save Image
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-overlay"
      :class="{ show: show }"
      @click="hideBackground()"
    ></div>
  </div>
</template>

<script>
//Import libraries
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "ToggleButton",
  data() {
    return {
      croppedBlob: null,
      imageCropper: {
        zoomable: false,
        viewMode: 1,
        aspectRatio: 1200 / 630,
        background: false,
      },
    };
  },
  props: {
    imageURL: String,
    show: Boolean,
    cropType: Array,
  },
  watch: {
    imageURL: function (val) {
      this.$refs.cropper.replace(val);
    },
    show: function () {
      if (this.cropType == undefined || this.cropType == "square") {
        this.imageCropper.aspectRatio = 1;
      } else if (this.cropType == "rectangle")
        this.imageCropper.aspectRatio = 1200 / 630;
      else this.imageCropper.aspectRatio = parseFloat(this.cropType);

      this.$refs.cropper.setAspectRatio(this.imageCropper.aspectRatio);
    },
  },
  components: {
    VueCropper,
  },
  methods: {
    checkCropType(type) {
      return this.cropType.includes(type);
    },

    saveImage() {
      this.$refs.cropper
        .getCroppedCanvas({
          maxWidth: 800,
        })
        .toBlob(
          (blob) => {
            this.croppedBlob = blob;
            this.$emit(
              "saveImage",
              this.croppedBlob,
              this.$refs.cropper
                .getCroppedCanvas({
                  maxWidth: 800,
                })
                .toDataURL("image/jpg", 1)
            );
          },
          "image/jpg",
          1
        );
    },

    hideBackground() {
      this.closeImageCropper();
    },

    closeImageCropper() {
      this.$emit("closeImageCropper");
    },
  },
};
</script>

<style scoped lang="scss">
.image-cropper-wrapper {
  display: none;
  background: lighten(#1e1e1e, 0%);
  box-shadow: 0 0 0.7rem -0.1rem rgba(0, 0, 0, 0.5);
  border-radius: 1rem;
  padding: 1rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  height: calc(100vh - 10rem);
  width: 45vw;
  z-index: 101;

  &.show {
    display: block;
  }
}

.image-block-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  height: calc(100vh - 10rem);
  overflow-y: scroll;
}

.bg-overlay.show {
  left: 50%;
  top: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);
  background-color: #1e1e1e;
  opacity: 0.7;
}

.image-cropper {
  margin: -4rem auto 0;
  max-width: none;
  height: calc(100vh - 15rem);
}

/deep/ {
  .cropper-modal {
    opacity: 0.8;
    border-radius: 0.5rem;
  }
}

.image-cropper-toolbar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(100% - 1.5rem);
  background-color: #000;
  padding: 0.75rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0.5rem auto 0;

  .image-cropper-tools {
    display: flex;
    flex: 1;
  }

  .image-cropper-actions {
    text-align: right;
    display: flex;
    align-items: flex-end;

    .btn {
      font-size: $smallerFontSize;
      padding: 0.75rem 0.5rem;
      margin-left: 0.5rem;
      background: lighten(#1e1e1e, 10%);
      border-color: lighten(#1e1e1e, 25%);
      opacity: 0.75;
      color: $whiteColor;
    }

    .btn-save-image {
      color: #0a4a1d;
      background-color: $darkGreenColor;
      border: 1px solid #5df1b1;
      opacity: 1;
    }
  }

  .image-cropper-toolbar-icon {
    transition: all 0.3s;
    cursor: pointer;
    padding: 8px;
    background: lighten(#1e1e1e, 10%);
    border-radius: 0.5rem;
    margin: 0 0.15rem;
    display: flex;
    align-items: center;

    span {
      transition: all 0.3s;
      font-size: $smallerFontSize;
      opacity: 0.75;
      color: $whiteColor;
      margin-left: 0.25rem;
    }

    /deep/ {
      svg {
        fill: $whiteColor;
        opacity: 0.5;
        height: auto;
        width: 1.25rem;
      }
    }

    &:hover {
      background-color: $purpleColor;
      span {
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .image-cropper-wrapper {
    height: calc(100vh - 2rem);
    width: calc(100vw - 2rem);
    transform: none;
    top: 0;
    left: 0;
  }

  .image-block-wrapper {
    height: calc(100vh - 2rem);
  }

  .image-cropper {
    width: 100%;
  }

  .image-cropper-toolbar {
    bottom: 1rem;
    flex-direction: column;

    .image-cropper-tools {
      flex: 1;
      flex-direction: row;
      align-items: stretch;
      width: 100%;
    }

    .image-cropper-toolbar-icon {
      justify-content: center;
      align-items: center;
      flex: 1;
    }

    .image-cropper-actions {
      margin-top: 1rem;
      align-items: stretch;
      flex: 1;
      flex-direction: row;
      width: 100%;
      .btn {
        width: 100%;
        display: block;
      }
    }
  }
}
</style>