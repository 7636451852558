<template>
  <div>
    <div class="column-block">
      <div>
        <!--START: Video Wrapper-->
        <div class="video-wrapper">
          <div class="fetching-video-loader" v-if="fetchingVideo">
            <div class="loader-wrapper">
              <img
                src="@/assets/images/loading-pullup.gif"
                alt="Loading animation"
              />
              <span>Fetching Video</span>
            </div>
          </div>
          <div class="video-player-wrapper" :class="{ show: !fetchingVideo }">
            <div id="video-player"></div>
          </div>
        </div>
        <!--END: Video Wrapper-->
      </div>
    </div>

    <!--START: VDO Script-->
    <script
      type="application/javascript"
      defer
      src="https://player.vdocipher.com/playerAssets/1.6.10/vdo.js"
    ></script>
    <!--END: VDO Script-->
  </div>
</template>

<script>
//Import Service
import VideoCourseService from "@/controllers/VideoCourseService";

export default {
  name: "ModulesVideoPlayer",
  props: {
    module: {
      type: Object,
      required: true,
    },
  },
  watch: {
    module: function () {
      this.getVideo();
    },
  },
  data() {
    return {
      fetchingVideo: false,
    };
  },
  async created() {
    this.getVideo();
  },
  computed: {},
  components: {},
  methods: {
    async getVideo() {
      this.fetchingVideo = true;

      const response = await VideoCourseService.GetVideo({
        videoID: this.module.videoURL,
        accountEmail: this.module.accountEmail,
      });

      this.fetchingVideo = false;
      var self = this;
      setTimeout(() => {
        self.videoPlayer = new window.VdoPlayer({
          otp: response.data.otp,
          playbackInfo: response.data.playbackInfo,
          theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
          container: document.querySelector("#video-player"),
        });
      }, 50);
    },

    playVideo() {
      this.videoPlayer.play();
    },

    pauseVideo() {
      this.videoPlayer.pause();
    },
  },
};
</script>

<style scoped lang="scss">
.video-wrapper {
  margin-bottom: 0.5rem;
}

.video-player-wrapper {
  display: none;
  text-align: center;

  &.show {
    display: block;
  }
}
#video-player {
  background-color: #ececec;
  border-radius: 0.75rem;
  overflow: hidden;
}

.fetching-video-loader {
  position: relative;
  background: #ececec;
  min-height: 20vw;
  border-radius: 1rem;
  padding: 2rem 1rem;
  text-align: center;

  .loader-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
  }

  img {
    background-color: $whiteColor;
    display: block;
    width: 3.5rem;
    padding: 0.75rem;
    border-radius: 0.5rem;
    margin: auto;
  }

  span {
    display: block;
    color: $blackColor;
    font-weight: $mediumFontWeight;
    opacity: 0.85;
    margin-top: 1rem;
  }
}
</style>