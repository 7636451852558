<template>
  <header>
    <div class="rel-wrapper">
      <img src="@/assets/images/logo.png" class="logo" alt="Logo" />

      <!--START: Menu Right -->
      <div class="header-profile-wrapper">
        <div class="btn btn-user btn-invert site-menu-dropdown-wrapper">
          <img
            src="@/assets/images/profile-picture.png"
            class="profile-image"
            alt="Logo"
          />
          <span class="username">{{ coach.displayName }}</span>
          <unicon name="angle-down"></unicon>

          <div class="site-menu-dropdown site-menu-dropdown-right">
            <span class="user-email">{{ coach.email }}</span>
            <ul class="site-menu-list">
              <li class="site-menu-list-item">
                <router-link to="/account">Account Settings</router-link>
              </li>
              <li class="site-menu-list-item">
                <span @click="logoutUser()">Sign Out</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--END: Menu Right -->
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  props: {
    coach: {
      type: Object,
      required: true,
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
header {
  background-color: $darkBlueColor;
  position: fixed;
  top: 0;
  left: 0;
  padding: 1rem 5%;
  width: 90%;
  z-index: 10;
}

.logo {
  @include vertical-center-align;
  display: block;
  left: 0;
  width: 6.25rem;
}

.main-menu {
  text-align: center;
  margin: auto;
}

.main-menu-item {
  display: inline-block;
  vertical-align: middle;
}

.main-menu-item a {
  @include transition;
  position: relative;
  display: block;
  font-size: $smallFontSize;
  color: $whiteColor;
  opacity: 0.75;
  border: none;
  padding: 0.5rem 0.5rem;
  margin: 0 0.5rem;

  &::before {
    @include transition;
    background-color: $darkGreenColor;
    content: "";
    position: absolute;
    display: block;
    bottom: -1rem;
    left: 0;
    right: 0;
    height: 0;
    width: 100%;
  }
}

.main-menu-item a:hover {
  color: lighten($darkBlueColor, 65%);
}

.main-menu-item.selected a,
.main-menu-item a.router-link-active {
  color: $darkGreenColor;
  font-weight: $mediumFontWeight;
  opacity: 1;

  &::before {
    height: 4px;
  }
}

.header-profile-wrapper {
  @include vertical-center-align;
  right: 0;
}

.btn-user {
  cursor: default;

  .profile-image {
    display: inline-block;
    vertical-align: middle;
    width: 1.15rem;
  }

  .username {
    font-size: $smallerFontSize;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.75rem;
    margin-right: 0.25rem;
  }

  .user-email {
    color: $greyColor;
    font-size: $smallerFontSize;
    padding: 1rem 1.75rem;
    display: block;
  }

  .unicon {
    margin-left: 0;
  }

  .unicon /deep/ svg {
    width: 1rem;
  }

  .site-menu-list-item span {
    color: $greyColor;
  }
}
</style>