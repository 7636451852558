<template>
  <div class="client-dashboard-wrapper" v-if="showComponent">
    <!--START: Header-->
    <Header></Header>
    <!--END: Header-->

    <!--START: Welcome Message-->
    <div class="welcome-message">
      <div>
        <h3>Namaste, {{ client.name }}</h3>
        <p>Here are your Tanjore courses</p>
      </div>
    </div>
    <!--END: Welcome Message-->
    <!--START: Client Plans-->
    <div class="client-plan-wrapper">
      <div v-for="plan in courses" :key="plan._id" class="client-plan">
        <LiveCourseCard
          v-if="plan.type === 'liveCourse'"
          :plan="plan"
          @gotoPlan="gotoLiveCourse"
        ></LiveCourseCard>
        <VideoCourseCard
          v-else
          :plan="plan"
          @gotoPlan="gotoVideoCourse"
        ></VideoCourseCard>
      </div>

      <!--START: Client Badge-->
      <div class="client-badge">
        <img
          src="@/assets/images/client/client-circle-flash.png"
          alt="Skipper Circle"
        />
        <img
          src="@/assets/images/client/client-circle.png"
          class="badge-text"
          alt="Skipper Circle"
        />
      </div>
      <!--START: Client Badge-->
    </div>
    <!--END: Client Plans-->

    <!--START: Footer-->
    <Footer></Footer>
    <!--END: Footer-->
  </div>
</template>

<script>
// Import components
import Header from "@/components/student/Header";
import Footer from "@/components/student/Footer";
import VideoCourseCard from "@/components/student/VideoCourseCard";
import LiveCourseCard from "@/components/student/LiveCourseCard";

// Import services
import StudentService from "@/controllers/StudentService";

export default {
  name: "StudentCourses",
  data() {
    return {
      showComponent: false,
      showLoader: false,
      showBackground: false,
      client: {},
      courses: [],
    };
  },
  components: {
    Header,
    Footer,
    VideoCourseCard,
    LiveCourseCard,
  },
  async created() {
    if ((await this.authenticateClient("/sign-in", false)) == false)
      this.$router.push({
        path: "/sign-in",
      });
    this.client = this.$store.state.client.client;

    this.getCourses();
    this.showComponent = true;
  },
  methods: {
    // Get courses
    async getCourses() {
      const response = await StudentService.GetCourses();
      if (!response.hasError) {
        this.courses = response.data;
        this.courses.sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
      }
    },

    // Goto video course
    gotoVideoCourse(courseID) {
      this.$router.push({
        path: `/student/courses/${courseID}/video-course/modules`,
      });
    },

    // Goto live course
    gotoLiveCourse(courseID) {
      this.$router.push({
        path: `/student/courses/${courseID}/live-course/modules`,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.client-dashboard-wrapper {
  background: lighten($lightWhiteColor, 5%);
}

.welcome-message {
  padding: 1.5rem 0 2rem 0;
  text-align: center;

  .welcome-image {
    display: block;
    font-size: 3.5rem;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }

  h3 {
    font-size: 2rem;
    font-family: $headingFont;
    margin-bottom: 0rem;
  }
  p {
    color: $greyColor;
    font-size: $mediumFontSize;
    font-size: 1.25rem;
    font-weight: 300;
  }
}

.client-plan-wrapper {
  position: relative;
  margin-bottom: -5rem;
  z-index: 10;
}

.client-plan {
  @include transition;
  width: 25rem;
  padding: 1rem;
  border-radius: 0.75rem;
  border: 1px solid darken($lightWhiteColor, 5%);
  text-align: center;
  margin: 0 auto 1rem;
  cursor: pointer;
  background-color: $whiteColor;
  box-shadow: 0 0.5rem 0.7rem -0.1rem transparent;

  &:hover {
    box-shadow: 0 0 0.7rem -0.1rem rgba(0, 0, 0, 0.15);
  }
  /deep/ {
    .plan-image {
      display: block;
      margin: auto;
      width: 100%;
      border-radius: 0.5rem;
      margin-bottom: 1rem;
    }

    .plan-name {
      font-size: $mediumFontSize;
      margin-bottom: 0;
    }

    .coach-name {
      color: $greyColor;
    }

    .plan-details-wrapper {
      margin: 0.75rem 0 1.25rem;

      span {
        font-size: $smallerFontSize;
        background-color: $lightWhiteColor;
        border: 1px solid darken($lightWhiteColor, 25%);
        color: darken($lightWhiteColor, 75%);
        font-weight: $mediumFontWeight;
        padding: 0.5rem;
        border-radius: 0.5rem;
        display: inline-block;
        margin-right: 0.5rem;
      }
    }
  }
}

.client-badge {
  position: fixed;
  display: inline-block;
  vertical-align: middle;
  width: 6rem;
  height: 6rem;
  bottom: 2rem;
  left: 3rem;
  opacity: 0.75;
}

.client-badge img {
  display: block;
  width: 80%;
  height: auto;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

img.badge-text {
  position: absolute;
  transform: rotate(0);
  top: 0;
  left: -2.5%;
  width: 105%;
  height: auto;
  transform-origin: center center;
  animation-name: rotateBadge;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes rotateBadge {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: $mobileWidth) {
  .welcome-message {
    h3 {
      font-size: 1.5rem;
      margin-bottom: -0.25rem;
      margin-top: 0.5rem;
    }
  }

  .client-plan {
    width: calc(100% - 3.5rem);
    margin: 0 0.75rem 1rem;
  }

  .client-badge {
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: -8rem;
    transform: none;
    margin: auto;
    display: block;
  }
}
</style>