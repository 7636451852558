<template>
  <div v-if="show" class="modal modal-large-size" :class="{ show: show }">
    <div class="rel-wrapper">
      <div class="modal-close" @click="closeModal()">
        <unicon name="times"></unicon>
      </div>
      <div class="modal-block-wrapper modal-scroll-wrapper">
        <h3>{{ guide.title }}</h3>
        <div class="video-player">
          <youtube
            v-if="guide.videoURL != undefined"
            :video-id="$youtube.getIdFromURL(guide.videoURL)"
            player-width="100%"
          ></youtube>
        </div>
        <div class="guide-description" v-html="guide.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GuideModal",
  data() {
    return {
      showLoader: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    guide: {
      type: Object,
      required: true,
    },
  },
  async created() {},
  methods: {
    closeModal() {
      this.$parent.showModal = false;
      this.$parent.showBackground = false;
    },
  },
};
</script>

<style scoped lang="scss">
.line-loader {
  bottom: 0;
}

.video-player {
  width: 95%;
  margin: 1rem 0;

  /deep/ iframe {
    height: auto !important;
  }
}

.modal-block-wrapper {
  h3 {
    font-family: $headingFont;
  }
}

.guide-description {
  margin-top: 1rem;

  /deep/ {
    p {
      margin-bottom: 1rem;
    }

    ul {
      margin: 0 0 0 2rem;

      li {
        position: relative;
        color: $blackColor;
        line-height: 1.4;
        word-spacing: normal;
        letter-spacing: normal;
        margin-bottom: 1.5rem;

        &::before {
          content: "";
          display: block;
          background-color: $purpleColor;
          position: absolute;
          height: 6px;
          width: 6px;
          border-radius: 50%;
          top: 0.5rem;
          left: -1.25rem;
        }
      }
    }

    ol {
      margin: 0 0 0 2rem;
      li {
        list-style: decimal !important;
        position: relative;
        color: $blackColor;
        line-height: 1.4;
        word-spacing: normal;
        letter-spacing: normal;
        margin-bottom: 1.5rem;

        &::before {
          content: "";
          display: block;
          background-color: $purpleColor;
          position: absolute;
          top: 0.5rem;
          left: -1.25rem;
        }
      }
    }
    strong {
      font-weight: bold;
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .modal {
    width: calc(90% - 4rem);
  }

  .modal-block-wrapper h3 {
    font-size: $mediumFontSize;
    width: 90%;
  }
}
</style>