<template>
  <div class="coach-settings-modal" :class="{ show: show }">
    <!--START: Header-->
    <div class="modal-header">
      <unicon name="angle-down" @click="closeModal" />
      <h3>Add Student</h3>
    </div>
    <!--END: Header-->

    <div class="scroll-wrapper">
      <!--START: Students-->
      <div v-if="students.length" class="students-wrapper">
        <div class="students-title-wrapper">
          <span>{{ students.length }} students added</span>
          <span v-if="excludedStudents > 0" class="excluded-students"
            >{{ excludedStudents }} excluded</span
          >
        </div>
        <div class="grid-table">
          <!--START: Student Header-->
          <div class="grid-header">
            <div class="name">
              <span>Name</span>
            </div>
            <div class="email">
              <span>Email</span>
            </div>
            <div class="phone">
              <span>Phone</span>
            </div>
          </div>
          <!--END: Student Header-->

          <div
            v-for="student in students"
            :key="student.id"
            class="student grid-row"
            :class="{ excluded: student.email === '' || student.name === '' }"
          >
            <div class="name">
              <span>{{ student.name }}</span>
            </div>
            <div class="email">
              <span>{{ student.email }}</span>
            </div>
            <div class="phone">
              <span>{{ student.phone }}</span>
            </div>
          </div>
        </div>
      </div>
      <!--END: Students-->

      <!--START: Form-->
      <div v-else class="form-wrapper">
        <!-- START: File Upload Fields -->
        <div class="csv-wrapper">
          <label>Upload a CSV file to add multiple students</label>
          <FormBuilder
            :fields="csvFields"
            @fieldChanged="csvAdded"
          ></FormBuilder>
        </div>
        <!-- End: File Upload Fields -->

        <form class="coach-form" @change="formChanged">
          <FormBuilder
            :fields="fields"
            @fieldChanged="formChanged"
          ></FormBuilder>
        </form>
      </div>
      <!--END: Form-->
    </div>

    <!--START: Buttons-->
    <button
      v-if="students.length"
      class="btn btn-primary"
      @click="bulkSaveBooking"
    >
      Add Students
    </button>
    <button
      v-else
      class="btn btn-primary"
      :disabled="disableButton"
      @click="saveBooking"
    >
      Add Student
    </button>
    <!--END: Buttons-->

    <!--START: Page Loader -->
    <LineLoader :showLoader="showLoader"></LineLoader>
    <!--END: Page Loader -->

    <!--START: Status Message-->
    <StatusMessage
      :show="status.show"
      :isSuccess="status.isSuccess"
      :successMessage="status.successMessage"
      :errorMessage="status.errorMessage"
    ></StatusMessage>
    <!--END: Status Message-->
  </div>
</template>
  
<script>
// Import services
import BookingService from "@/controllers/BookingService";

// Import components
import LineLoader from "@/components/loaders/LineLoader";
import StatusMessage from "@/components/modals/StatusMessage";
import FormBuilder from "@/components/form/FormBuilder";

export default {
  name: "AddStudent",
  data() {
    return {
      showLoader: false,
      disableButton: true,
      csvFields: {
        students: {
          type: "file-large",
          fileType: "csv",
          fileInstruction: "Upload a single CSV file",
          fileList: [],
          imagesList: [],
          required: false,
          hasError: false,
        },
      },
      excludedStudents: 0,
      students: [],
      fields: {
        name: {
          type: "text",
          title: "Student Name*",
          placeholder: "Enter the student name",
          required: true,
          hasError: false,
        },
        email: {
          type: "email",
          title: "Student Email*",
          placeholder: "Enter the student email",
          required: true,
          hasError: false,
        },
        phone: {
          type: "country-tel",
          title: "Student Phone*",
          placeholder: "Enter the student phone number",
          dataNumberOnly: true,
          required: true,
          hasError: false,
        },
      },
      status: {
        show: false,
        isSuccess: true,
        successMessage: "🙌 Changes saved",
        errorMessage: "😕 Something's not right. Try again",
      },
    };
  },
  computed: {},
  props: {
    plan: {
      type: Object,
      required: false,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    LineLoader,
    StatusMessage,
    FormBuilder,
  },
  async created() {
    this.resetFields(this.fields);
  },
  methods: {
    //Save booking
    async saveBooking() {
      var timeoutHandler = null;
      clearTimeout(timeoutHandler);

      this.showLoader = true;
      this.status.successMessage = "🙌 Changes saved";
      this.status.errorMessage = "😕 Something's not right. Try again";
      this.status.show = false;
      this.status.isSuccess = false;

      //Check if the form has valid input
      var formFields = {
        ...this.fields,
      };

      var formValidation = this.validateForm(formFields);

      if (formValidation.hasErrors) {
        this.status.isSuccess = false;
        this.status.show = true;
      } else if (!this.disableButton) {
        this.disableButton = true;

        const data = this.parseData(this.fields);
        data.course = { id: this.plan._id, title: this.plan.title };

        const response = await BookingService.AddLiveCourseToStudent(data);

        if (!response.hasError) {
          this.status.isSuccess = true;
          setTimeout(() => (this.$emit("bookingCompleted"), 1000));
        } else {
          this.status.isSuccess = false;
          this.status.errorMessage = response.error;
        }
      }

      this.status.show = true;
      this.disableButton = true;
      this.showLoader = false;

      //Hide the notification
      timeoutHandler = setTimeout(() => (this.status.show = false), 3000);
    },

    async bulkSaveBooking() {
      if (this.students.length > 0) {
        var timeoutHandler = null;
        clearTimeout(timeoutHandler);

        this.showLoader = true;
        this.status.successMessage = "🙌 Changes saved";
        this.status.errorMessage = "😕 Something's not right. Try again";
        this.status.show = false;
        this.status.isSuccess = false;

        const data = {};
        data.course = { id: this.plan._id, title: this.plan.title };
        data.students = this.students;

        const response = await BookingService.BulkAddLiveCourseToStudent(data);

        if (!response.hasError) {
          this.status.isSuccess = true;
          setTimeout(() => (this.$emit("bookingCompleted"), 1000));
        } else {
          this.status.isSuccess = false;
          this.status.errorMessage = response.error;
        }
      }
    },

    formChanged() {
      this.disableButton = false;
    },

    csvAdded() {
      const file = this.csvFields.students?.fileList[0];
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.$emit("load", e.target.result);
          const rows = e.target.result.split("\r");

          rows.forEach((r) => {
            const details = r.split(",");
            const student = {
              name: details[0].replace("\n", "").trim(),
              email: details[1].replace(/ /g, "").toLowerCase(),
              phone: details[2].replace(/ /g, ""),
            };
            this.students.push(student);

            if (student.name === "" || student.email === "") {
              this.excludedStudents++;
            }
          });
        };
        reader.readAsText(file);
      }
    },

    hideBackground() {
      this.showBackground = false;
    },

    showSuccessMessage() {
      this.$parent.status.isSuccess = true;
      this.$parent.status.show = true;
      setTimeout(() => (this.$parent.status.show = false), 3000);
    },

    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
  
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.coach-settings-modal {
  display: none;
  height: auto !important;
  bottom: auto !important;
  width: 40vw !important;

  .coach-form {
    padding-bottom: 0 !important;
  }
}

.csv-wrapper {
  margin: 2rem 1rem;

  label {
    display: block;
    color: $greyColor;
    margin-bottom: 1rem;
  }
}

.selected-offering-wrapper {
  span {
    display: block;
    background: lighten($darkGreenColor, 50%);
    color: darken($darkGreenColor, 20%);
    border: 1px solid darken($darkGreenColor, 5%);
    padding: 0.5rem 1rem;
    text-align: center;
    border-radius: 0.5rem;
    width: calc(100% - 3rem);
    margin-top: -1rem;
    margin-left: 0.5rem;
  }
}

.students-wrapper {
  margin: 1rem;
}

.status-message {
  left: 0 !important;
}

.grid-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.95rem;

  > div {
    &:nth-child(1) {
      width: 45%;
    }
    &:nth-child(2) {
      width: 27.5%;
    }
    &:nth-child(3) {
      width: 27.5%;
      text-align: right;
    }

    span {
      font-size: $smallerFontSize;
    }
  }
}

.students-title-wrapper {
  margin-bottom: 1rem;

  span {
    display: inline-block;
    font-size: $smallerFontSize;
    font-weight: $mediumFontWeight;
    margin-right: 0.5rem;
  }

  .excluded-students {
    background-color: $redColor;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: $smallestFontSize;
    font-weight: $mediumFontWeight;
    color: $whiteColor;
  }
}

.student {
  display: flex;
  flex-direction: row;
  align-items: center;

  > div {
    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.5;
    }

    &:nth-child(3) {
      text-align: right;
    }

    &.name {
      width: 45%;
      span {
        font-size: $smallFontSize;
        font-weight: 500;
      }
    }

    &.email,
    &.phone {
      width: 27.5%;
      span {
        font-size: $smallFontSize;
        color: $greyColor;
      }
    }
  }

  &.excluded {
    background-color: lighten($redColor, 15%);

    span {
      color: darken($redColor, 65%) !important;
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .coach-settings-modal.show {
    display: block;
    width: calc(100vw - 2rem) !important;
    left: 50% !important;
    bottom: 0 !important;
    transform: translateX(-50%) !important;
  }
}
</style>