<template>
  <div v-if="showComponent" class="workouts-module">
    <!-- START: Module Header -->
    <div class="module-header">
      <div class="module-info-wrapper">
        <h1 class="module-title">Resources</h1>
        <p class="module-description">
          These are resources for you to use during your course.<br/>
          You can always download them from here
        </p>
      </div>
    </div>
    <!-- END: Module Header -->

    <!--START: Plan Attachments-->
    <div class="plan-attachments">
      <div class="attachments-list">
        <div
          v-for="(attachment, index) in plan.attachments"
          :key="index"
          class="attachment-file"
        >
          <img src="@/assets/images/icons/image-upload.svg" alt="File Upload" />
          <div>
            <h3 class="file-name">{{ attachment.name }}</h3>
            <a :href="attachment.url" target="_blank" class="file-download"
              >Download</a
            >
          </div>
        </div>
      </div>
    </div>
    <!--END: Plan Attachments-->
  </div>
</template>

<script>
export default {
  name: "ClientAttachments",
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showComponent: false,
      showBackground: false,
      client: {},
    };
  },
  async created() {
    if ((await this.authenticateClient("sign-in", false)) == false)
      this.$router.push({
        path: "sign-in",
      });
    this.client = this.$store.state.client.client;

    this.showComponent = true;
  },

  components: {},
  methods: {},
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.module-header {
  position: relative;
  top: auto;
  left: auto;
  width: auto;
  display: block;
  padding: 2rem 2.5rem;
  background: transparent;
  text-align: center;
  z-index: 10;

  /deep/ .module-title {
    font-family: $headingFont;
    font-size: 2rem;
    margin: 2rem 1.5rem 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .module-description {
    margin: 0.5rem auto 0;
    font-size: 1.15rem;
    width: 70%;
  }

  .module-actions-wrapper,
  .module-info-wrapper {
    align-self: center;
  }

  .modal-close {
    display: none;
  }
}

.attachments-list {
  padding: 0 12%;
  margin-top: -1rem;

  .label-small {
    margin: 0 0 1.5rem;
  }
}

.attachment-file {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #dad6e5;

  img {
    display: block;
    width: 3rem;
    margin-right: 1rem;
  }

  .file-name {
    font-size: $normalFontSize;
    display: block;
  }

  span,
  a {
    font-size: $smallerFontSize;
    cursor: pointer;
    border-bottom: none;
    margin-right: 0.5rem;
  }

  a:hover {
    color: darken($blueColor, 20%);
  }

  span {
    color: $greyColor;

    &:hover {
      color: $redColor;
    }
  }
}

.module-actions-wrapper {
  display: flex;
  text-align: right;
}

@media screen and (max-width: $mobileWidth) {
  .module-header {
    position: relative;
    width: auto;
    padding: 1.5rem 2rem 2rem;
    text-align: left;
    margin-bottom: 0;

    .module-title {
      font-size: 1.25rem;
      margin: 0;
    }

    .module-description {
      font-size: $smallFontSize;
      width: auto;
    }

    .modal-close {
      display: block;
      border: none;
      top: 1rem;
      right: 1rem;
    }
  }

  .attachments-list {
    margin-top: 0.5rem;
    padding: 0 2rem;
  }

  .module-actions-wrapper {
    display: none;
  }
}
</style>