<template>
  <div>
    <!--START: Module Header-->
    <div class="module-header">
      <div class="module-info-wrapper extend">
        <h1 class="module-title">{{ moduleOptions.title }}</h1>
        <p class="module-description">{{ moduleOptions.description }}</p>
      </div>
      <div class="module-actions-wrapper">
        <button class="btn btn-primary" @click="showAddStudentModal">
          <unicon name="plus"></unicon>
          <span>{{ zeroStateOptions.buttonTitle }}</span>
        </button>
      </div>
    </div>

    <!--START: Grid Table -->
    <div class="customers-table-wrapper" v-if="students.length > 0">
      <StudentsTable
        :courseID="plan._id"
        :students="students"
        ref="studentsTable"
      ></StudentsTable>
    </div>
    <!--END: Grid Table -->

    <!--START: Zero State -->
    <ZeroState
      v-else
      :asset="zeroStateOptions.asset"
      :show="zeroStateOptions.show"
      :title="zeroStateOptions.title"
      :message="zeroStateOptions.message"
      :buttonTitle="zeroStateOptions.buttonTitle"
      @eventHandler="showAddStudentModal"
      class="zero-state"
    ></ZeroState>
    <!--END: Zero State -->

    <!--START: Add Booking Modal -->
    <AddStudent
      :plan="plan"
      :show="showAddStudent"
      @closeModal="hideBackground"
      @bookingCompleted="manualBookingAdded"
    ></AddStudent>
    <!--START: Add Booking Modal -->

    <!--START: Page Loader -->
    <LineLoader :showLoader="showLoader"></LineLoader>
    <!--END: Page Loader -->

    <div
      class="bg-overlay"
      :class="{ show: showBackground }"
      @click="hideBackground()"
    ></div>
  </div>
</template>
  
<script>
// Import libraries
import _ from "lodash";

// Service for coach
import LiveCourseService from "@/controllers/LiveCourseService";

// Import components
import LineLoader from "@/components/loaders/LineLoader";
import ZeroState from "@/components/dashboard/ZeroState";
import StudentsTable from "@/components/liveCourse/StudentsTable";
import AddStudent from "@/components/liveCourse/AddStudent";

export default {
  name: "StudentsView",
  props: {
    plan: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showComponent: false,
      showBackground: false,
      showLoader: false,
      showAddStudent: false,
      fetchingStudents: false,
      students: [],
      moduleOptions: {
        title: "Students",
        description: "A view of your students",
      },
      zeroStateOptions: {
        show: true,
        asset: "metrics.json",
        title: "Your Students",
        message: "All your students from your course will appear here",
        buttonTitle: "Add Student",
      },
    };
  },
  components: {
    ZeroState,
    StudentsTable,
    AddStudent,
    LineLoader,
  },
  async created() {
    this.showLoader = true;

    if (_.isEmpty(this.coach)) {
      await this.authenticateCoach("/sign-in", false);
      this.coach = this.$store.state.coach.coach;
    }

    await this.getStudents();

    this.showLoader = false;
    this.showComponent = true;
  },

  methods: {
    // Getting students
    async getStudents() {
      this.fetchingStudents = true;
      this.showLoader = true;

      const fields = { studentIDs: this.plan.students };

      const response = await LiveCourseService.GetStudents(fields);

      if (!response.hasError) {
        this.students = response.data;
      }

      window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchingStudents = false;
      this.showLoader = false;
    },

    // Show modal for add student
    showAddStudentModal() {
      this.toRoute = null;
      this.showBackground = true;
      this.showAddStudent = true;
    },

    hideBackground() {
      this.showBackground = false;
      this.showAddStudent = false;
    },

    manualBookingAdded() {
      this.hideBackground();
      this.getStudents();
    },
  },
};
</script>
  
  <style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.customers-table-wrapper {
  padding: 2rem 2rem 1rem;
}

.payment-header {
  display: flex;
  flex-direction: row;
}

.payment-header-wrapper {
  padding: 0 2rem 2rem;
}

.price-wrapper,
.plan-price {
  color: $purpleColor;
  font-weight: $mediumFontWeight;

  span {
    font-size: $normalFontSize;
    margin-right: 0.15rem;
  }
  em {
    font-size: $largeFontSize;
  }
}

.plan-price {
  color: $greyColor;
}

.payment-header-item {
  margin-right: 0rem;
  flex: 1;
  label {
    font-size: $smallerFontSize;
    color: $greyColor;
    display: block;
    margin-bottom: 0.5rem;
  }

  &:last-child {
    margin-right: 0;
  }
}

.zero-state {
  padding-top: 2rem;
}

.label-small {
  margin: 1rem 0;
}

@media screen and (min-width: $mobileWidth) {
  .payment-header-wrapper {
    padding: 2rem 1.5rem 2rem;
  }
}

//Mobile Styles
@media screen and (max-width: $mobileWidth) {
  .customers-table-wrapper {
    padding: 0 1rem 2rem;
  }
}
</style>