var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showComponent)?_c('div',[_c('div',{staticClass:"module-header"},[_c('div',{staticClass:"module-info-wrapper"},[_c('h1',{staticClass:"module-title"},[_vm._v(_vm._s(_vm.moduleOptions.title))]),_c('p',{staticClass:"module-description"},[_vm._v(_vm._s(_vm.moduleOptions.description))])])]),_c('div',{staticClass:"column-block course-details-main"},[_c('div',{staticClass:"cover-image-wrapper"},[_c('div',{staticClass:"cover-media-wrapper"},[_c('div',{staticClass:"image-block"},[_c('label',{staticClass:"field-title"},[_vm._v(_vm._s(_vm.coverMediaFields.coverImageURL.title))]),_c('div',{staticClass:"rel-wrapper"},[_c('FileUploadLarge',{staticClass:"image-uploader",class:{
                'has-image':
                  (_vm.plan.coverImageURL != undefined &&
                    _vm.plan.coverImageURL != false) ||
                  _vm.croppedImageURL != null,
              },attrs:{"field":_vm.coverMediaFields.coverImageURL},on:{"fileUploaded":function($event){return _vm.checkImageUpload()}}}),(
                (_vm.plan.coverImageURL != undefined &&
                  _vm.plan.coverImageURL != false) ||
                _vm.croppedImageURL != null
              )?_c('div',{staticClass:"uploaded-image-wrapper"},[_c('img',{attrs:{"src":_vm.croppedImageURL != null
                    ? _vm.croppedImageURL
                    : _vm.plan.coverImageURL,"alt":"Cover image"}}),_c('span',[_vm._v("Change Cover Image")])]):_vm._e()],1)])])]),_c('div',{staticClass:"course-details-wrapper"},[_c('FormBuilder',{staticClass:"primary-form",attrs:{"fields":_vm.fields},on:{"fieldChanged":_vm.formChanged,"input":_vm.formChanged}})],1)]),_c('LineLoader',{attrs:{"showLoader":_vm.showLoader}}),_c('StatusMessage',{attrs:{"show":_vm.status.show,"isSuccess":_vm.status.isSuccess,"successMessage":_vm.status.successMessage}}),_c('ConfirmationModal',{staticClass:"unsaved-changes-modal",attrs:{"fields":_vm.unsavedChangesFields},on:{"primaryEvent":_vm.continueRoute,"secondaryEvent":_vm.hideBackground}}),_c('ImageCropper',{staticClass:"image-cropper-module",attrs:{"show":_vm.showImageCropper,"imageURL":_vm.imageCropperURL,"cropType":['rectangle', 'tall-square']},on:{"closeImageCropper":_vm.closeImageCropper,"saveImage":_vm.saveCroppedImage}}),_c('div',{staticClass:"bg-overlay",class:{ show: _vm.showBackground },on:{"click":function($event){return _vm.hideBackground()}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }