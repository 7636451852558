<template>
  <div v-if="show" class="certificate-block">
    <div class="certificate-wrapper">
      <!--START: Guilloche Pattern-->
      <div class="guilloche-pattern"></div>
      <!--END: Guilloche Pattern-->

      <div class="info-wrapper">
        <img
          src="@/assets/images/certificate-logo.png"
          class="certificate-logo"
          alt="Logo"
        />

        <!--START: Title Wrapper-->
        <div class="title-wrapper">
          <h1>CERTIFICATE</h1>
          <span>Of Completion</span>
        </div>
        <span class="authority-label">Tanjore Collective Course</span>
        <!--END: Title Wrapper-->

        <!--START: Certification Details-->
        <div class="details-wrapper">
          <div class="student-wrapper">
            <label>This is to certify that</label>
            <h3>{{ studentName }}</h3>
            <p>
              Has successfully completed the
              <b>{{ certificate.name }}</b
              >.
            </p>
            <p>{{ certificate.description }}</p>
          </div>
        </div>
        <!--END: Certification Details-->

        <!--START: Signature Details-->
        <div class="signature-wrapper">
          <div class="signature-detail">
            <span>{{
              moment(certificate.issueDate).format("DD MMMM, YYYY")
            }}</span>
            <label>Issue Date</label>
          </div>
          <div class="signature-detail">
            <img
              src="@/assets/images/certificate-signature.png"
              class="signature-image"
              alt="Signature"
            />
            <label>Founder, Tanjore Collective</label>
          </div>
        </div>
        <!--END: Signature Details-->
      </div>

      <!--START: Pattern-->
      <img
        src="@/assets/images/certificate-pattern.png"
        class="certificate-pattern"
        alt="Pattern"
      />
      <!--START: Pattern-->
    </div>
  </div>
</template>
    
<script>
export default {
  name: "CertificateCard",
  data() {
    return {};
  },
  props: {
    studentName: String,
    certificate: {
      type: Object,
      required: true,
    },
    show: Boolean,
  },
  computed: {},
  async created() {},
  methods: {},
};
</script>
    
<style scoped lang="scss">
.certificate-block {
  position: fixed;
  text-align: center;
  top: 50%;
  left: 50%;
  width: calc(100% - 4rem);
  max-width: 26rem;
  transform: translate(-50%, -50%);
  background: #fdf6e2;
  border: 1px solid #f8de6e;
  border-radius: 0.5rem;
  padding: 1.25rem 1rem;
  z-index: 1000;
}
.certificate-wrapper {
  position: relative;
  background: #fdf6e2;
  border: 1.5px solid;
  border-image: linear-gradient(45deg, #ae7d0b, #f3d047) 1;
  padding: 1rem;

  &::after {
    display: block;
    content: "";
    position: absolute;
    height: calc(100% + 0.75rem);
    width: calc(100% - 0.5rem - 3px);
    bottom: -0.5rem;
    left: 0.25rem;
    border: 1.5px solid;
    border-image: linear-gradient(45deg, #ae7d0b, #f3d047) 1;
  }

  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100%);
    width: calc(100%);
    background: url(https://grainy-gradients.vercel.app/noise.svg);
    filter: contrast(10%) brightness(1000%);
    opacity: 0.7;
    z-index: 100;
    mix-blend-mode: overlay;
  }
}

.guilloche-pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/assets/images/guilloche-pattern.png");
  background-repeat: repeat;
  opacity: 0.15;
  z-index: 3;
}

.info-wrapper {
  position: relative;
  margin: 1rem;
  z-index: 2;

  .title-wrapper {
    line-height: 1;
    margin-top: 1.5rem;

    h1 {
      font-family: $headingFont;
      color: darken($goldColor, 40%);
      font-size: 2rem;
      margin-bottom: 0;
    }

    span {
      position: relative;
      display: inline-block;
      text-transform: uppercase;
      color: darken($goldColor, 40%);
      font-size: 1rem;
      letter-spacing: 0.5rem;
      font-weight: 400;
      margin-left: 0.5rem;
    }
  }

  .authority-label {
    position: relative;
    display: block;
    text-align: center;
    font-family: $headingFont;
    text-transform: uppercase;
    color: #c89000;
    font-weight: 500;
    margin: 1.5rem 0 0;
    padding: 0.5rem 0;
    border-top: 1px solid darken($goldColor, 5%);
    border-bottom: 1px solid darken($goldColor, 5%);

    &::before {
      display: block;
      content: "";
      position: absolute;
      top: -2px;
      left: 0;
      height: calc(100% + 4px);
      width: 3rem;
      z-index: 1;
      background: linear-gradient(to right, #fdf6e2 0%, rgba(0, 0, 0, 0) 100%);
    }

    &::after {
      display: block;
      content: "";
      position: absolute;
      top: -2px;
      right: 0;
      height: calc(100% + 4px);
      width: 3rem;
      z-index: 1;
      background: linear-gradient(to left, #fdf6e2 0%, rgba(0, 0, 0, 0) 100%);
    }
  }
}

.details-wrapper {
  margin-top: 3rem;

  .student-wrapper {
    label {
      color: $blackColor;
      font-family: $headingFont;
      font-weight: 500;
    }

    h3 {
      color: $blackColor;
      font-size: 1.75rem;
      font-weight: 700;
      margin-bottom: 1rem;
    }

    p {
      color: $blackColor;
      font-size: $smallestFontSize;
      margin-bottom: 0.75rem;
    }
  }
}

.signature-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 2.5rem 0 2rem;

  .signature-detail {
    flex: 1;
    margin: 0 0.5rem;

    span {
      display: block;
      color: $blackColor;
      font-weight: $mediumFontWeight;
      margin-bottom: 0.5rem;
    }

    label {
      display: block;
      color: $blackColor;
      font-size: $smallestFontSize;
      border-top: 1px solid #e5d7b1;
      margin-top: 0.75rem;
      padding-top: 0.75rem;

      b {
        display: block;
      }
    }

    .signature-image {
      display: block;
      margin: auto;
      width: 6rem;
    }
  }
}

.certificate-logo {
  display: block;
  width: 10rem;
  margin: 0 auto 0.75rem;
}

.certificate-pattern {
  display: block;
  width: calc(100% + 1.5rem);
  margin-left: calc(-1rem + 0.25rem);
  margin-bottom: calc(-1rem + 0rem);
}
</style>