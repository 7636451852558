<template>
  <div class="dashboard-outer-block">
    <div class="dashboard-block">
      <!--START: Coach Reports-->
      <Analytics :coach="coach"></Analytics>
      <!--END: Coach Reports-->
    </div>
    <!--END: URL Copy-->

    <!--START: Status Message-->
    <StatusMessage
      :show="status.show"
      :isSuccess="status.isSuccess"
      :successMessage="status.successMessage"
    ></StatusMessage>
    <!--END: Status Message-->
  </div>
</template>

<script>
//Import components
import StatusMessage from "@/components/modals/StatusMessage";
import Analytics from "@/components/user/analytics/Index";

export default {
  name: "CoachDashboardMain",
  data() {
    return {
      showHeaderMenu: false,
      status: {
        show: false,
        isSuccess: true,
        successMessage: "Link copied!",
      },
    };
  },
  components: {
    StatusMessage,
    Analytics,
  },
  props: {
    coach: {
      type: Object,
      required: true,
    },
  },
  methods: {
    //Save the Coach URL to clipboard
    copyCoachURL() {
      //Copy the URL
      const el = document.createElement("textarea");
      el.value = `https://${this.coach.slug}.skpr.me`;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }

      //Toggle status
      this.status.successMessage = "Link Copied!";
      this.status.show = true;
      setTimeout(() => {
        this.status.show = false;
      }, 2000);
    },
  },
};
</script>

<style scoped lang="scss">
.dashboard-outer-block {
  display: none;
  position: relative;
  height: fit-content;
  margin: 4rem 0 5rem calc(20rem + 0px);
  width: calc(100% - 20rem);
  box-shadow: none;
}

.dashboard-block {
  padding: 3rem;
}

.dashboard-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  padding: 1.5rem 1.5rem;
  border-radius: 1rem;
}

.dashboard-title {
  margin-right: 2rem;
  padding-right: 1.75rem;
  border-right: 1px solid $greyBorderColor;

  h3 {
    font-size: $largerFontSize;
    font-weight: $mediumFontWeight;
  }

  p {
    font-size: $mediumFontSize;
    color: $greyColor;
  }
}

.copy-wrapper {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  transition: all 0.3s;
  padding: 0.65rem 1.05rem;
  border-radius: 0.5rem;
  margin-top: 0rem;
  background-color: #f0fff8;
  border: 1px solid #a1eac8;
  color: darken($darkGreenColor, 20%);
  font-size: $normalFontSize;

  .coach-link {
    display: inline-block;
    em {
      font-weight: $mediumFontWeight;
    }
  }

  .unicon {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.5rem;

    /deep/ svg {
      fill: darken($darkGreenColor, 20%);
      height: auto;
      width: $mediumFontSize;
    }
  }

  &:hover {
    background-color: lighten($darkGreenColor, 40%);
  }
}

.link-label {
  position: relative;
  display: block;
  color: $greyColor;
  margin: 0 0 0.5rem;
  padding-left: 1rem;

  &::before {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: $darkGreenColor;
  }
}

.link-instructions {
  display: block;
  width: 60%;
  margin-top: 0.5rem;
  font-size: $smallestFontSize;
  color: $greyColor;
  opacity: $mediumOpacity;
}

.announcement-wrapper {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  border-bottom: 1px dashed #d8d9e5;
  padding: 0 10% 2rem;

  img {
    display: block;
    width: 16rem;
    margin-right: 3rem;
  }

  .announcement-info {
    h3 {
      font-size: $largeFontSize;
    }
    .label {
      display: inline-block;
      padding: 0.35rem 0.65rem;
      font-size: $mediumFontSize;
      font-weight: $mediumFontWeight;
      background-color: $darkGreenColor;
      margin-bottom: 0.5rem;
      color: darken($darkGreenColor, 30%);
      border-radius: 0.5rem;
    }
    p {
      color: $greyColor;
      line-height: 1.6;
    }
  }
}

/deep/ {
  .header-wrapper {
    margin-top: 0;
  }
}

@media screen and (min-width: $mobileWidth) {
  .dashboard-outer-block {
    display: block;
  }
}
</style>