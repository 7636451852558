require('dotenv').config();
import axios from 'axios';

//Importing Variable mixins
import VariablesMixins from '../mixins/Variables';
const apiURL = VariablesMixins.APIURL;

class InsightService {
    //Service to get coach bookings
    static GetInsights() {
        return new Promise((resolve, reject) => {
            resolve(
                (async() => {
                    try {
                        return await axios.get(`${apiURL}/insights/`, {
                                withCredentials: true
                            })
                            .then((response) => {
                                return response.data;
                            }, (error) => {
                                return error.response.data;
                            });
                    } catch (e) {
                        console.log('err', e);
                        reject(e);
                    }
                })()
            );
        })
    }
}

export default InsightService;