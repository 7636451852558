//Importing AuthService for Logout
import AuthService from "@/controllers/AuthService";
import CoachService from "@/controllers/CoachService";
import StudentService from "@/controllers/StudentService";

export default {
  methods: {
    validateForm(formFields) {
      var hasErrors = false;
      var validField;

      for (const field of Object.keys(formFields)) {
        if (formFields[field].type == "group") {
          var response = this.validateForm(formFields[field].fields);
          if (response.hasErrors) {
            hasErrors = true;
          }
        } else {
          validField = this.validateData(formFields[field]);
          if (!validField) {
            formFields[field].hasError = true;
            hasErrors = true;
          } else {
            formFields[field].hasError = false;
          }
        }
      }
      return {
        hasErrors: hasErrors,
        formFields: formFields,
      };
    },

    validateData(field) {
      switch (field.type) {
        case "text":
        case "textarea":
        case "text-editor":
        case "date":
        case "password":
          if (
            field.required &&
            (field.value == undefined || field.value.length == 0)
          )
            return false;
          else return true;

        case "number":
        case "money":
          if (
            field.required &&
            (field.value == undefined ||
              field.value.length == 0 ||
              isNaN(field.value))
          )
            return false;
          else return true;

        case "tel":
          if (
            field.required &&
            (field.value == undefined || field.value.length != 10)
          )
            return false;
          else return true;

        case "country-tel":
          if (field.required && field.value.number == undefined) return false;
          else return true;

        case "url":
          if (
            field.required &&
            (field.value == undefined || field.value.length == 0)
          )
            return false;
          else if (
            !field.required &&
            field.value != undefined &&
            field.value != null &&
            field.value != "" &&
            field.value.length > 0
          ) {
            var pattern = new RegExp(
              "^(https?:\\/\\/)?" +
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
                "((\\d{1,3}\\.){3}\\d{1,3}))" +
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
                "(\\?[;&a-z\\d%_.~+=-]*)?" +
                "(\\#[-a-z\\d_]*)?$",
              "i"
            );
            return !!pattern.test(field.value);
          } else return true;

        case "toggle":
        case "checkbox":
          return true;

        case "radio":
          if (
            field.required &&
            (field.modelName == undefined || field.modelName.length == 0)
          )
            return false;
          else return true;

        case "file-large":
          if (
            field.required &&
            (field.fileList == undefined || field.fileList.length == 0)
          )
            return false;
          else return true;

        case "dropdown":
          if (field.required && field.value == null) return false;
          else return true;

        case "multiple-select-dropdown":
          if (field.required && field.selectedItems.length == 0) return false;
          else return true;

        case "email":
          if (
            field.required &&
            (field.value == undefined || field.value.length == 0)
          )
            return false;
          else if (
            !field.required &&
            (field.value == undefined || field.value.length == 0)
          ) {
            return true;
          } else {
            var emailValidator = /^\w+[\w-+.]*@\w+([-.]\w+)*\.[a-zA-Z]{2,}$/;
            return field.value.match(emailValidator);
          }
        case "scheduler":
          var ele = this;
          var hasError = false;

          //Check if every day has same time
          if (field.selectAllDays.value) {
            //Every day has same time
            let hasSelectedDay = false;
            field.overallDays.days.forEach(function(day) {
              if (day.isActive) hasSelectedDay = true;
            });

            //Check if atleast a single day is selected
            if (hasSelectedDay) {
              //Check if all the slots have a value
              field.overallDays.timeSlots.forEach(function(timeSlot) {
                if (ele.validateForm(timeSlot).hasErrors) hasError = true;
              });
            } else hasError = true;
          } else if (!field.selectAllDays.value) {
            //Unique days and slots
            let hasSelectedDay = false;
            field.uniqueSlots.forEach(function(day) {
              if (day.isActive.value) hasSelectedDay = true;
              day.timeSlots.forEach(function(timeSlot) {
                if (ele.validateForm(timeSlot).hasErrors) hasError = true;
              });
            });
            if (!hasSelectedDay) hasError = true;
          }
          return !hasError;
      }
    },

    // Function to logout a user
    async logoutUser() {
      await AuthService.LogoutUser();

      this.$store.commit("updateCoach", {
        coach: null,
        loggedIn: false,
      });

      this.$store.commit("updateClient", {
        client: null,
        loggedIn: false,
      });

      this.$router.push({ path: "/" });
    },

    //Extract first name from string
    getFirstName(displayName) {
      if (displayName.indexOf(" ") >= 0) {
        displayName = displayName.split(" ");
      }
      return displayName[0];
    },

    //Reset form fields
    resetFields(formFields) {
      for (const field of Object.keys(formFields)) {
        if (formFields[field].type == "group")
          this.resetFields(formFields[field].fields);
        else if (formFields[field].type == "file-large") {
          formFields[field].fileList.splice(0);
          formFields[field].imagesList.splice(0);
        } else {
          formFields[field].value = null;
        }
      }
    },

    //Check if coach is logged in
    async authenticateCoach(authenticatedRoute, redirectRoute) {
      //Check if coach variable is stored in VueEx
      if (this.isCoachLoggedIn()) {
        if (redirectRoute == undefined || redirectRoute == true)
          this.$router.push({
            path: authenticatedRoute,
          });
        else return true;
      }
      //Check if coach has logged in but not saved in VueEx
      else if (await this.getCoach()) {
        if (redirectRoute == undefined || redirectRoute == true)
          this.$router.push({
            path: authenticatedRoute,
          });
        else return true;
      }
      //Return coach not authenticated
      else {
        return false;
      }
    },

    //Check if client is logged in
    async authenticateClient(authenticatedRoute, redirectRoute) {
      //Check if client variable is stored in VueEx
      if (this.isClientLoggedIn()) {
        if (redirectRoute == undefined || redirectRoute == true)
          this.$router.push({
            path: authenticatedRoute,
          });
        else return true;
      }
      //Check if client has logged in but not saved in VueEx
      else if (await this.getClient()) {
        if (redirectRoute == undefined || redirectRoute == true)
          this.$router.push({
            path: authenticatedRoute,
          });
        else return true;
      }
      //Return client not authenticated
      else {
        return false;
      }
    },

    // Create JSON object for data transfer
    parseData(formFields, allowEmptyValues = false) {
      var parsedData = {};
      var tempData;

      //Loop through all the fields
      for (const field of Object.keys(formFields)) {
        if (formFields[field] != null && formFields[field].show !== false) {
          //Check if it's a group of fields
          if (formFields[field].type == "group") {
            var groupFields = formFields[field].fields;
            tempData = this.parseData(groupFields);

            //Add the fields in a nested object
            if (
              formFields[field].nestFields == true &&
              Object.keys(tempData).length !== 0
            ) {
              parsedData[field] = tempData;
            } else {
              parsedData = { ...parsedData, ...tempData };
            }
          }
          //Check if it's a variants type
          else if (formFields[field].type == "variants") {
            var variantFields = formFields[field].value;

            let variantsArray = [];
            variantFields.forEach((variant) => {
              variantsArray.push({
                _id: variant.variantID,
                ...this.parseData({ ...variant.fields }),
              });
            });
            tempData = variantsArray;

            //Add the fields in a nested object
            if (
              formFields[field].nestFields == true &&
              Object.keys(tempData).length !== 0
            ) {
              parsedData[field] = tempData;
            } else {
              parsedData = { ...parsedData };
              parsedData[field] = tempData;
            }
          }
          //If it's a multiple select dropdown
          else if (
            formFields[field].type == "multiple-select-dropdown" &&
            formFields[field].selectedItems.length != 0
          ) {
            parsedData[field] = formFields[field].selectedItems;
          }

          //If it's a multiple select dropdown
          else if (
            formFields[field].type == "dropdown" &&
            formFields[field].value != "" &&
            formFields[field].value != null &&
            formFields[field].value.length !== 0
          ) {
            parsedData[field] = formFields[field].value[1];
          }

          //If it's a multiple toggle button
          else if (
            formFields[field].type == "radio" &&
            formFields[field].value != undefined
          ) {
            const object = formFields[field].options.find(
              (o) => o.name === formFields[field].value
            );
            parsedData[field] = object.value;
          }

          //If it's a multiple toggle button
          else if (formFields[field].type == "gallery-images") {
            if (formFields[field].fileList.length)
              parsedData.uploadedFiles = formFields[field].fileList;
            if (formFields[field].value.length)
              parsedData[field] = formFields[field].value;
          }

          //If it's a scheduler type
          else if (formFields[field].type == "scheduler") {
            var days = [],
              timeSlots = [];
            var slotsLength;

            const ele = this;
            formFields[field].days.forEach(function(day) {
              slotsLength = day.timeSlots.length;
              if (day.isActive.value) {
                timeSlots = [];
                for (let i = 0; i < slotsLength; i++) {
                  timeSlots.push(ele.parseData(day.timeSlots[i]));
                }
              }

              days.push({
                day: day.day.substring(0, 3),
                isActive: day.isActive.value,
                timeSlots: day.isActive.value ? timeSlots : [],
              });
            });
            parsedData[field] = days;
          }

          //If it's a phone type
          else if (formFields[field].type == "country-tel") {
            if (
              formFields[field]?.dataNumberOnly &&
              formFields[field]?.value?.number
            ) {
              parsedData[field] =
                "+" +
                formFields[field]?.value.countryCallingCode +
                formFields[field]?.value?.number;
            } else if (formFields[field]?.value?.number)
              parsedData[field] = formFields[field].value;
          }

          //If it's a money type
          else if (formFields[field].type == "money") {
            if (parseInt(formFields[field].value)) {
              parsedData[field] = parseInt(formFields[field].value);
            }
          }

          //Add field if it's a normal field
          else if (
            formFields[field].value !== undefined &&
            formFields[field].value !== null &&
            (allowEmptyValues || formFields[field].value !== "") &&
            (allowEmptyValues || formFields[field].value.length !== 0)
          ) {
            parsedData[field] = formFields[field].value;
          }
        }
      }

      return parsedData;
    },

    isCoachLoggedIn() {
      return this.$store.state.coach.loggedIn;
    },

    isClientLoggedIn() {
      return this.$store.state.client.loggedIn;
    },

    async getCoach() {
      const coach = await CoachService.GetCoach();

      //Check the coach doesn't exist and redirect
      if (coach.hasError) {
        this.$store.commit("updateCoach", {
          coach: null,
          loggedIn: false,
        });
        return false;
      } else {
        this.$store.commit("updateCoach", {
          coach: coach.data,
          loggedIn: true,
        });
        return true;
      }
    },

    async getClient() {
      const client = await StudentService.GetStudent();

      //Check the coach doesn't exist and redirect
      if (client.hasError) {
        this.$store.commit("updateClient", {
          client: null,
          loggedIn: false,
        });
        return false;
      } else {
        this.$store.commit("updateClient", {
          client: client.data,
          loggedIn: true,
        });
        return true;
      }
    },

    updateGlobalCoach(coach) {
      this.$store.commit("updateCoach", {
        coach: coach,
        loggedIn: true,
      });
    },

    updateGlobalClient(client) {
      this.$store.commit("updateClient", {
        client: client,
        loggedIn: true,
      });
    },

    titleCase(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },

    //Function to go through response data and construct a JSON object
    parseForm(formFields) {
      var parsedData = {};

      //Loop through all the fields
      for (const field of Object.keys(formFields)) {
        if (formFields[field] != null) {
          //Check if it's a group of fields
          if (formFields[field].type == "group") {
            var groupFields = formFields[field].fields;
            var tempData = this.parseForm(groupFields);

            //Add the fields in a nested object
            if (
              formFields[field].nestFields == true &&
              Object.keys(tempData.parsedData).length !== 0
            ) {
              parsedData[field] = tempData.parsedData;
            } else {
              parsedData = { ...parsedData, ...tempData.parsedData };
            }
          }
          //If it's a multiple select dropdown
          else if (
            formFields[field].type == "multiple-select-dropdown" &&
            formFields[field].selectedItems.length != 0
          ) {
            parsedData[field] = formFields[field].selectedItems;
          }

          //If it's a multiple select dropdown
          else if (
            formFields[field].type == "dropdown" &&
            formFields[field].value != undefined
          ) {
            parsedData[field] = formFields[field].value[1];
          }

          //If it's a multiple toggle button
          else if (
            formFields[field].type == "radio" &&
            formFields[field].value != undefined
          ) {
            parsedData[field] = formFields[field].value;
          }

          //If it's a scheduler type
          else if (formFields[field].type == "scheduler") {
            var days = [],
              timeSlots = [];
            var slotsLength;

            if (formFields[field].selectAllDays.value) {
              slotsLength = formFields[field].overallDays.timeSlots.length;
              for (var i = 0; i < slotsLength; i++) {
                timeSlots.push(
                  this.parseForm(formFields[field].overallDays.timeSlots[i])
                    .parsedData
                );
              }

              formFields[field].overallDays.days.forEach(function(day) {
                days.push({
                  day: day.name,
                  isActive: day.isActive,
                  timeSlots: day.isActive ? timeSlots : [],
                });
              });
            } else {
              const ele = this;
              formFields[field].uniqueSlots.forEach(function(day) {
                slotsLength = day.timeSlots.length;
                if (day.isActive.value) {
                  timeSlots = [];
                  for (i = 0; i < slotsLength; i++) {
                    timeSlots.push(ele.parseForm(day.timeSlots[i]).parsedData);
                  }
                }

                days.push({
                  day: day.day,
                  isActive: day.isActive.value,
                  timeSlots: day.isActive.value ? timeSlots : [],
                });
              });
            }
            parsedData[field] = {
              hasFixedDays: formFields[field].selectAllDays.value,
              days: days,
            };
          }

          //Add field if it's a normal field
          else if (
            formFields[field].value !== undefined &&
            formFields[field].value !== "" &&
            formFields[field].value !== null
          ) {
            parsedData[field] = formFields[field].value;
          }
        }
      }

      return {
        parsedData: parsedData,
      };
    },

    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },

    getCurrency(currency) {
      if (currency == undefined || currency == null) return "₹";
      else {
        var currencySymbols = {
          USD: "$", // US Dollar
          EUR: "€", // Euro
          CRC: "₡", // Costa Rican Colón
          GBP: "£", // British Pound Sterling
          ILS: "₪", // Israeli New Sheqel
          INR: "₹", // Indian Rupee
          JPY: "¥", // Japanese Yen
          KRW: "₩", // South Korean Won
          NGN: "₦", // Nigerian Naira
          PHP: "₱", // Philippine Peso
          PLN: "zł", // Polish Zloty
          PYG: "₲", // Paraguayan Guarani
          THB: "฿", // Thai Baht
          UAH: "₴", // Ukrainian Hryvnia
          VND: "₫", // Vietnamese Dong
        };

        if (currencySymbols[currency] !== undefined)
          return currencySymbols[currency];
        else return currency;
      }
    },

    getCoachCurrency() {
      let currency = this.$store.state.coach.coach.currency;
      if (currency == undefined) return "₹";
      else {
        var currencySymbols = {
          USD: "$", // US Dollar
          EUR: "€", // Euro
          CRC: "₡", // Costa Rican Colón
          GBP: "£", // British Pound Sterling
          ILS: "₪", // Israeli New Sheqel
          INR: "₹", // Indian Rupee
          JPY: "¥", // Japanese Yen
          KRW: "₩", // South Korean Won
          NGN: "₦", // Nigerian Naira
          PHP: "₱", // Philippine Peso
          PLN: "zł", // Polish Zloty
          PYG: "₲", // Paraguayan Guarani
          THB: "฿", // Thai Baht
          UAH: "₴", // Ukrainian Hryvnia
          VND: "₫", // Vietnamese Dong
        };

        if (currencySymbols[currency] !== undefined)
          return currencySymbols[currency];
        else return currency;
      }
    },

    //Check if it's a valid Email Address
    validateEmail(email) {
      var emailValidator = /^\w+[\w-+.]*@\w+([-.]\w+)*\.[a-zA-Z]{2,}$/;
      return email.match(emailValidator);
    },
  },
};
