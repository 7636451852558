<template>
  <div>
    <!--START: Dashboard Header-->
    <DashboardHeader
      v-if="!$route.meta.hideHeader"
      :coach="$store.state.coach"
    ></DashboardHeader>
    <!--END: Dashboard Header-->

    <!--START: App Router View-->
    <router-view v-if="$route.meta.isClientApp"></router-view>
    <keep-alive v-else-if="$store.state.coach.loggedIn">
      <router-view :key="$route.params.id" />
    </keep-alive>
    <router-view v-else></router-view>
    <!--END: App Router View-->
  </div>
</template>

<script>
//Cohere Setup
import Cohere from "cohere-js";
Cohere.init("gjgJzz9EWb8CvLdihSUwJBa0");

//Importing components
import DashboardHeader from "@/components/dashboard/Header";

export default {
  name: "TanjoreCollective",
  components: {
    DashboardHeader,
  },
  created() {},
};
</script>

<style>
/* Global Fonts */
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@300;400;500;700&display=swap");
</style>