<template>
  <div v-if="offerings != undefined">
    <h2 class="title">Course Insights</h2>
    <!--START: Offerings Sales Table-->
    <div v-if="offerings.length != 0">
      <ul class="grid-table">
        <li class="grid-header">
          <div class="grid-item">Name</div>
          <div class="grid-item">Type</div>
          <div class="grid-item">Total Views</div>
          <div class="grid-item">Unique Views</div>
          <div class="grid-item">Total Sales</div>
          <div class="grid-item">Total Amount</div>
        </li>
        <li v-for="offering in offerings" :key="offering.id" class="grid-row">
          <div class="grid-item offering-title">
            <span>
              {{ offering.offeringInfo.title }}
            </span>
          </div>
          <div class="grid-item offering-type">
            <span>
              {{ getOfferingTitle(offering.offeringInfo.offeringType) }}
            </span>
          </div>
          <div class="grid-item offering-sales label-total-views">
            <span>{{ offering.analytics.totalViews }}</span>
          </div>

          <div class="grid-item offering-sales label-unique-views">
            <span>{{ offering.analytics.uniqueViews }}</span>
          </div>

          <div class="grid-item offering-sales label-total-sales">
            <span>{{ offering.sales.totalSales }}</span>
          </div>

          <div class="grid-item offering-sales label-total-amount">
            <span>{{ getCoachCurrency() }}{{ offering.sales.totalAmount }}</span>
          </div>
        </li>
      </ul>
    </div>
    <!--END: Offerings Sales Table-->
  </div>
</template>

<script>
export default {
  name: "MonthlySalesTable",
  props: {
    offerings: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showBackground: false,
    };
  },
  methods: {
    getOfferingTitle(offeringType) {
      switch (offeringType) {
        case "personal-training":
          return "Service Based Course";
        case "e-book":
          return "E-Book";
        case "diy-plan":
          return "Fitness Course";
        case "video-course":
          return "Video Course";
        case "online-course":
          return "Online Course";
        case "consultation-call":
          return "Appointment";
        default:
          return null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
$gridTableRadius: 0.75rem;
.title {
  opacity: 0.75;
  font-size: $mediumFontSize;
  font-weight: $mediumFontWeight;
  margin: 2rem 0 1rem;
}

.grid-table {
  margin: 0;
  border: 1px solid #edeae7;
  border-radius: $gridTableRadius;
}

.grid-header {
  padding: 0.95rem 0.95rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  background-color: $lightBlueColor;
  border-top-left-radius: $gridTableRadius;
  border-top-right-radius: $gridTableRadius;

  .grid-item {
    color: #696d8d;
    font-size: $smallerFontSize;
  }
}

.grid-row {
  z-index: 1;
  background-color: transparent;
  border: 1px solid transparent;
  background-color: $whiteColor;
  border-top: 1px solid #e6e8f1;
  box-shadow: 0 0 0.7rem -0.15rem rgba(0, 0, 0, 0);
  position: relative;
  border-radius: 0;
  padding: 1rem 0.95rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

.grid-row.selected,
.grid-row:hover {
  z-index: 10;
  background-color: #f3f5ff;
  border: 1px solid #efefef;
  box-shadow: 0 0 0.7rem -0.15rem rgba(0, 0, 0, 0.015);
  & + .grid-row {
    border-top: 1px solid transparent;
  }
}

.grid-row.selected {
  z-index: 11;

  .client-group .btn-border {
    background-color: lighten($purpleColor, 0%);
    color: lighten($purpleColor, 45%);
    border: 1px solid $purpleColor;

    .unicon /deep/ svg {
      fill: lighten($purpleColor, 45%);
    }
  }
}

.grid-row:last-child {
  border-bottom: none;
  border-bottom-left-radius: $gridTableRadius;
  border-bottom-right-radius: $gridTableRadius;
}

.grid-item {
  align-self: center;
  width: 20%;

  &:nth-child(1) {
    width: 30%;
  }

  &:nth-child(2) {
    width: 15%;
  }

  &:nth-child(n + 3) {
    text-align: center;
    width: 13.75%;
  }
}

.offering-title {
  span {
    color: $darkBlueColor;
    font-weight: $mediumFontWeight;
  }
}

.offering-date,
.offering-type {
  span {
    color: $greyColor;
    font-size: $smallerFontSize;
  }
}

.offering-type {
  span {
    color: $purpleColor;
    font-weight: $mediumFontWeight;
  }
}

//Mobile Styles
@media screen and (max-width: $mobileWidth) {
  .grid-header {
    display: none;
  }

  .grid-table {
    border-radius: 0;
  }

  .grid-table {
    border: none;
  }

  .grid-row {
    position: relative;
    text-align: right;
    border: 1px solid $greyBorderColor !important;
    margin-bottom: 1rem;
    border-radius: 1rem;
    padding-bottom: 0.5rem;
    display: block;

    &:hover {
      border: 1px solid transparent;
      background-color: $whiteColor;
    }
  }

  .offering-title,
  .offering-type {
    display: block;
    width: 100% !important;
    font-size: $mediumFontSize;
    text-align: left;
    margin-bottom: 0.3rem;
  }

  .offering-type {
    margin-bottom: 2rem;
  }

  .offering-sales {
    display: inline-block;
    width: 50% !important;
    text-align: left !important;
    margin-bottom: 1.5rem;

    span {
      font-weight: $boldFontWeight;
      font-size: $largeFontSize;
      padding-left: 0.5rem;
    }

    &::after {
      font-size: $smallFontSize;
      margin-top: 0.3rem;
      padding-left: 0.5rem;
      display: block;
      color: #444;
    }

    &.label-total-views::after {
      content: "Total Views";
    }

    &.label-unique-views::after {
      content: "Unique Views";
    }

    &.label-total-sales::after {
      content: "Total Sales";
    }

    &.label-total-amount::after {
      content: "Total Amount";
    }
  }
}
</style>