<template>
  <div class="client-dashboard" v-if="showComponent && !planExpired">
    <div class="client-dashboard-wrapper">
      <!--START: Menu-->
      <div class="client-dashboard-menu">
        <div class="plan-details">
          <router-link class="modal-close btn-back" to="/client/plans">
            <unicon name="angle-left-b"></unicon>
          </router-link>
          <div class="plan-details-info">
            <h3>{{ plan.title }}</h3>
          </div>
        </div>

        <div class="client-menu-wrapper">
          <div class="client-menu">
            <router-link
              class="menu-item"
              :to="`/student/courses/${plan._id}/${courseType}/modules`"
            >
              <unicon name="play-circle" class="menu-icon" />
              <span>Videos</span>
              <unicon name="angle-right-b" class="angle-icon" />
            </router-link>
            <router-link
              v-if="courseType !== 'live-course'"
              class="menu-item"
              :to="`/student/courses/${plan._id}/${courseType}/guides`"
            >
              <unicon name="schedule" class="menu-icon" />
              <span>Guides</span>
              <unicon name="angle-right-b" class="angle-icon" />
            </router-link>

            <router-link
              class="menu-item"
              :to="`/student/courses/${plan._id}/${courseType}/faqs`"
            >
              <unicon name="users-alt" class="menu-icon" />
              <span>FAQs</span>
              <unicon name="angle-right-b" class="angle-icon" />
            </router-link>

            <router-link
              v-if="courseType !== 'live-course'"
              class="menu-item"
              :to="`/student/courses/${plan._id}/${courseType}/attachments`"
            >
              <unicon name="paperclip" class="menu-icon" />
              <span>Resources</span>
              <unicon name="angle-right-b" class="angle-icon" />
            </router-link>
          </div>
        </div>

        <div class="menu-footer">
          <img
            src="@/assets/images/logomark.png"
            class="logo"
            alt="Tanjore Collective Logo"
          />
          <span
            >&copy; Tanjore Collective<br />
            All Rights Reserved 2023.</span
          >
        </div>
      </div>
      <!--END: Menu-->

      <!--START: Router Wrapper-->
      <div class="modal-route show">
        <div class="modal-content">
          <router-view :plan="plan" class="modal-block"></router-view>
        </div>
      </div>
      <!--END: Router Wrapper-->
    </div>

    <!--START: Page Loader -->
    <PageLoader :showLoader="showLoader"></PageLoader>
    <!--END: Page Loader -->

    <!--START: Loaders-->
    <LineLoader :showLoader="showLineLoader"></LineLoader>
    <div
      class="bg-overlay"
      :class="{ show: showBackground }"
      @click="hideBackground()"
    ></div>
    <!--END: Loaders-->
  </div>
  <div v-else-if="planExpired" class="plan-expired">
    <unicon name="lock"></unicon>
    <h3>Your Access Has Expired</h3>
    <p>Thank you so much for being a part of our course</p>
    <router-link to="/student/courses" class="btn btn-primary"
      >Back to Courses</router-link
    >
  </div>
</template>

<script>
// Import services
import StudentService from "@/controllers/StudentService";

// Import components
import PageLoader from "@/components/loaders/PageLoader";
import LineLoader from "@/components/loaders/LineLoader";

export default {
  name: "ClientPlanDashboard",
  data() {
    return {
      showComponent: false,
      showZeroState: false,
      showBackground: false,
      showLoader: false,
      showLineLoader: false,
      showMessageModal: false,
      saveModule: null,
      planExpired: false,
      courseType: "video-course",
      plan: {},
      status: {
        show: false,
        isSuccess: true,
        successMessage: "🙌 Changes saved",
        errorMessage: "😕 Something's not right. Try again",
      },
    };
  },
  computed: {
    hasLimitedAccess() {
      if (
        this.plan.accessSettings != undefined &&
        !this.plan.accessSettings.lifetimeAccess
      )
        return true;
      else return false;
    },
  },
  components: {
    PageLoader,
    LineLoader,
  },
  async created() {
    this.showLoader = true;

    if ((await this.authenticateClient("/sign-in", false)) == false)
      this.$router.push({
        path: "/sign-in",
      });
    this.client = this.$store.state.client.client;

    await this.getPlan();

    this.showLoader = false;
    this.showComponent = true;
  },
  methods: {
    async getPlan() {
      const { courseID, courseType } = this.$route.params;
      this.courseType = courseType;

      if (courseID) {
        let response;
        if (courseType === "video-course") {
          response = await StudentService.GetVideoCourse({ courseID });
        } else {
          response = await StudentService.GetLiveCourse({ courseID });
        }

        if (!response.hasError) {
          if (response.data.planExpired) this.planExpired = true;
          else this.plan = response.data;
        }
      }
    },

    showMessage() {
      this.showMessageModal = true;
      this.showBackground = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.client-dashboard {
  min-height: 100vh;
  background: lighten($lightWhiteColor, 5%);
}

.client-dashboard-menu {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $whiteColor;
  border-right: 1px solid #eeece5;
  height: calc(100%);
  width: 20rem;
  z-index: 11;
}

.plan-details {
  width: 80%;
  padding: 1.5rem 0;
  margin: 0.5rem 1.5rem 2rem;
  border-bottom: 1px solid #ececec;

  .plan-details-info {
    width: 70%;
  }

  h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $mediumFontSize;
    line-height: 1.1;
    margin-bottom: 0.5rem;
  }

  span {
    font-size: $smallFontSize;
    color: $greyColor;
    b {
      color: $darkBlueColor;
    }
  }
}

.modal-route {
  margin-left: 20rem;
}

.menu-item {
  display: block;
  position: relative;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  border-radius: 0.5rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin: 1rem 1rem 1rem 0;
  text-decoration: none;
  border: none;

  .angle-icon,
  .menu-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1.5rem;

    /deep/ svg {
      fill: $greyColor;
      height: auto;
      width: $normalFontSize;
    }
  }

  .angle-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    /deep/ svg {
      height: auto;
      width: $smallFontSize;
    }
  }

  span {
    display: inline-block;
    vertical-align: middle;
    font-size: $normalFontSize;
    color: $greyColor;
  }

  &.router-link-active {
    background-color: $lightWhiteColor;
    border: 1px solid darken($lightWhiteColor, 25%);

    .unicon /deep/ svg,
    span {
      color: darken($lightWhiteColor, 75%);
      fill: darken($lightWhiteColor, 75%);
    }
  }
}

.btn-back {
  // display: none;
  border-bottom: none;
  top: 1.5rem;
  right: 1rem;
}

.btn-message {
  position: absolute;
  bottom: 2rem;
  left: 1rem;
  width: calc(100% - 2rem);
  border-color: lighten($purpleColor, 40%);
  background-color: $purpleColor;
  color: lighten($purpleColor, 40%);
  padding: 0.75rem 1rem;

  /deep/ .unicon svg {
    fill: lighten($purpleColor, 40%);
    margin-right: 0.5rem;
  }

  span {
    vertical-align: inherit;
    margin-left: 0.25rem;
  }

  &:hover {
    background-color: darken($purpleColor, 20%);
    border-color: lighten($purpleColor, 40%);
    color: lighten($purpleColor, 40%);
  }
}

.menu-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 3rem 1.5rem 2rem;

  .logo {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
    width: 2.5rem;
  }

  span {
    font-size: $smallerFontSize;
    line-height: 1.4;
    display: inline-block;
    vertical-align: middle;
    color: $greyColor;
  }
}

.plan-expired {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  h3 {
    font-family: $headingFont;
    margin: 1.5rem 0 0;
  }

  p {
    font-size: $mediumFontSize;
    opacity: 0.75;
    margin: 0.5rem 0 2rem;
  }

  .unicon /deep/ {
    svg {
      height: auto;
      width: 3rem;
      fill: darken($lightWhiteColor, 55%);
      background: darken($lightWhiteColor, 5%);
      border: 1px solid darken($lightWhiteColor, 15%);
      padding: 0.5rem;
      border-radius: 0.5rem;
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .client-dashboard-menu {
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    background-color: transparent;
  }

  .plan-details {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .btn-back {
    display: block;
    position: relative;
    border: none;
    top: auto;
    right: auto;
    margin-right: 1rem;
  }

  .btn-message {
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    width: 25%;

    span {
      display: none;
    }
  }

  .client-menu-wrapper {
    background-color: $blackColor;
    position: fixed;
    z-index: 9;
    left: 0;
    bottom: 0;
    border-top: 1px solid #ececec;
    width: calc(100% - 1rem);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.75rem 0.5rem 0.5rem;

    .client-menu {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    }

    .menu-item {
      flex: 1;
      text-align: center;
      padding: 0.25rem 0;
      margin: 0;
      opacity: 0.45;

      span {
        font-size: $smallerFontSize;
        color: $whiteColor;
      }

      .menu-icon {
        display: block;
        margin: 0 auto 0.25rem;

        /deep/ svg {
          fill: $whiteColor;
          height: 1.15rem;
          width: auto;
        }
      }

      .angle-icon {
        display: none;
      }

      &.router-link-active {
        border: none;
        background-color: transparent;
        opacity: 1;

        span {
          color: lighten($darkBlueColor, 80%);
        }

        /deep/ svg {
          fill: lighten($darkBlueColor, 80%);
        }
      }
    }
  }

  .btn-message {
    color: $greyColor;
    font-size: $smallerFontSize;
    color: $whiteColor;
    font-weight: 400;
    text-align: center;
    opacity: 0.45;

    .menu-icon {
      display: block;
      margin: 0 auto 0.75rem;

      /deep/ svg {
        height: 1.15rem;
        width: auto;
        fill: $whiteColor;
        margin-right: 0;
      }
    }

    &:hover {
      background-color: transparent;
    }
  }

  .plan-details {
    background-color: $whiteColor;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    width: calc(100% - 2rem);
    padding: 1rem;
  }

  .modal-route {
    margin: 4.5rem 0 0;
    padding-bottom: 5rem;
  }

  .menu-footer {
    display: none;
  }
}
</style>