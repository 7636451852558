<template>
  <div v-if="showComponent" class="attachments-block">
    <!--START: Module Header-->
    <div class="module-header">
      <div class="module-info-wrapper">
        <h1 class="module-title">{{ moduleOptions.title }}</h1>
        <p class="module-description">{{ moduleOptions.description }}</p>
      </div>
    </div>
    <!--END: Module Header-->

    <!--START: Plan Attachments-->
    <div class="plan-attachments">
      <div class="attachments-list">
        <label class="label-small">Your Plan Attachments</label>
        <div
          v-for="attachment, index in plan.attachments"
          :key="index"
          class="attachment-file"
        >
          <img src="@/assets/images/icons/image-upload.svg" alt="File Upload" />
          <div>
            <h3 class="file-name">{{ attachment.name }}</h3>
            <a :href="attachment.url" target="_blank" class="file-download"
              >Download</a
            >
            <span class="file-remove" @click="removeAttachment(index)"
              >Remove File</span
            >
          </div>
        </div>
      </div>

      <FormBuilder v-if="!hasAttachment" :fields="$parent.attachmentsFields"></FormBuilder>
    </div>
    <!--END: Plan Attachments-->

    <!--START: Status Message-->
    <StatusMessage
      :show="status.show"
      :isSuccess="status.isSuccess"
      :successMessage="status.successMessage"
    ></StatusMessage>
    <!--END: Status Message-->

    <!--START: Unsaved Changes Modal -->
    <ConfirmationModal
      :fields="unsavedChangesFields"
      class="unsaved-changes-modal"
      @primaryEvent="continueRoute"
      @secondaryEvent="hideBackground"
    ></ConfirmationModal>
    <!--END: Unsaved Changes Modal -->

    <div
      class="bg-overlay"
      :class="{ show: showBackground }"
      @click="hideBackground()"
    ></div>
  </div>
</template>

<script>
//Import components
import FormBuilder from "@/components/form/FormBuilder";
import StatusMessage from "@/components/modals/StatusMessage";
import ConfirmationModal from "@/components/modals/ConfirmationModal";

export default {
  name: "CoachPlanAttachments",
  props: {
    plan: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showComponent: false,
      showBackground: false,
      moduleOptions: {
        title: "Attachments",
        description: "Add attachments for this course",
      },
      hasAttachment: false,
      toRoute: null,
      unsavedChangesFields: {
        show: false,
        title: "You Have Unsaved Changes",
        message:
          "Your changes will be lost. Are you sure you want to continue?",
        type: "delete",
        primaryBtn: {
          title: "Yes, Continue",
        },
        secondaryBtn: {
          title: "Cancel",
        },
      },
      status: {
        show: false,
        isSuccess: true,
        successMessage: "🙌 Changes saved",
        errorMessage: "😕 Something's not right. Try again",
      },
    };
  },
  computed: {
    filesUploaded: function () {
      if (this.$parent.attachmentsFields.attachments.fileList.length > 0) return true;
      else return false;
    },
  },
  watch: {
    filesUploaded: {
      handler: function () {
        if (this.filesUploaded) this.compareState();
      },
    },
  },
  components: {
    FormBuilder,
    StatusMessage,
    ConfirmationModal,
  },
  async created() {
    this.showComponent = true;
  },
  methods: {
    removeAttachment(index) {
      this.$parent.plan.attachments.splice(index, 1);
      this.compareState();
    },

    compareState() {
      this.$parent.planAttachments = this.$parent.attachmentsFields.attachments.fileList;
      this.$parent.saveModule = "attachments";
      this.$emit("compareState");
    },

    hideBackground() {
      this.showBackground = false;
      this.unsavedChangesFields.show = false;
    },

    continueRoute() {
      this.$parent.disableButton = true;
      if (this.toRoute != null) this.$router.push({ path: this.toRoute.path });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.$parent.disableButton) {
      this.toRoute = to;
      this.showBackground = true;
      this.unsavedChangesFields.show = true;
    } else {
      this.$emit("resetPlan");
      next();
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.coach-form {
  padding: 1.5rem 1.5rem;

  /deep/ .field-main-item:last-child {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 4rem;
  }
}

.plan-attachments {
  padding: 1rem 2rem 2rem;

  /deep/ .file-item {
    border: 1px solid darken($greenColor, 20%);
    padding: 0.75rem 1.25rem;

    span {
      font-size: $normalFontSize;
    }
  }

  /deep/ .file-upload-large label {
    background-color: $whiteColor;
  }

  .attachments-list {
    .label-small {
      margin: 0 0 1.5rem;
    }
  }

  .attachment-file {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;

    img {
      display: block;
      width: 3rem;
      margin-right: 1rem;
    }

    .file-name {
      font-size: $normalFontSize;
      display: block;
    }

    span,
    a {
      font-size: $smallerFontSize;
      cursor: pointer;
      border-bottom: none;
      margin-right: 0.5rem;
    }

    a:hover {
      color: darken($blueColor, 20%);
    }

    span {
      color: $greyColor;

      &:hover {
        color: $redColor;
      }
    }
  }
}

@media screen and (min-width: $mobileWidth) {
}
</style>