<template>
  <div v-if="showComponent" class="authentication-component">
    <div class="authentication-wrapper">
      <img src="@/assets/images/logo.png" class="logo" alt="Logo" />

      <!--START: Password Block-->
      <div class="form-wrapper">
        <h1>Create New Password</h1>
        <p>Save your new password and login to your account</p>

        <!--START: Status Message-->
        <StatusMessage
          :show="status.show"
          :isSuccess="status.isSuccess"
          :errorMessage="status.errorMessage"
          :successMessage="status.successMessage"
        ></StatusMessage>
        <!--END: Status Message-->

        <!--START: Form Wrapper-->
        <form v-on:submit.prevent="saveNewPassword()">
          <FormBuilder
            :fields="fields"
            @fieldChanged="fieldChanged()"
          ></FormBuilder>
          <button type="submit" class="btn btn-primary">
            Create Password
            <unicon name="angle-right-b"></unicon>
          </button>
        </form>
        <!--END: Form Wrapper-->
      </div>
      <!--END: Password Block-->
    </div>

    <!--START: Line Loader-->
    <LineLoader :showLoader="showLoader"></LineLoader>
    <!--END: Line Loader-->
  </div>
</template>

<script>
//Importing services
import AuthService from "@/controllers/AuthService";

//Importing components
import LineLoader from "@/components/loaders/LineLoader";
import FormBuilder from "@/components/form/FormBuilder";
import StatusMessage from "@/components/modals/StatusMessage";

export default {
  name: "ResetPassword",
  data() {
    return {
      showComponent: false,
      showLoader: false,
      parameters: {
        email: null,
        token: null,
      },
      status: {
        show: false,
        isSuccess: false,
        errorMessage: "Enter a valid e-mail address",
        successMessage: "Password changed! Use your new password to login",
      },
      fields: {
        password: {
          type: "password",
          placeholder: "Password",
          required: true,
          hasError: false,
        },
      },
    };
  },
  components: {
    LineLoader,
    FormBuilder,
    StatusMessage,
  },
  async created() {
    if (!(await this.authenticateClient("/client/plans")))
      this.showComponent = true;

    this.getParameters();
    if (this.parameters.token == null || this.parameters.email == null)
      this.$router.push({ path: "sign-in" });
  },
  methods: {
    async saveNewPassword() {
      //Hide status message and show line loader
      this.status.show = false;
      this.showLoader = true;

      //Use the service to authenticate the user
      const passwordSaved = await AuthService.CreateNewPassword({
        email: this.parameters.email,
        password: this.fields.password.value,
        token: this.parameters.token,
      });
      if (passwordSaved.hasError == false) {
        this.status.isSuccess = true;
        setTimeout(() => this.$router.push({ path: "sign-in" }), 1500);
      } else {
        this.status.errorMessage = passwordSaved.error;
        this.status.isSuccess = false;
      }

      //Hide line loader
      this.status.show = true;
      this.showLoader = false;
    },

    getParameters() {
      if (
        this.$route.query.token != undefined &&
        this.$route.query.email != undefined
      ) {
        this.parameters.token = this.$route.query.token;
        this.parameters.email = this.$route.query.email;
      }
    },

    fieldChanged() {
      this.status.show = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/authentication.scss";
</style>