require("dotenv").config();
import axios from "axios";

//Importing Variable mixins
import VariablesMixins from "../mixins/Variables";
const apiURL = VariablesMixins.APIURL;

class LiveCourseService {
  //Service to get coach workout templates
  static GetCourses() {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .get(`${apiURL}/live-course/all`, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to get coach workout template
  static GetCourse(courseID) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/live-course/`,
                {
                  courseID: courseID,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to delete coach workout template
  static DeleteCourse(courseID) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/live-course/delete`,
                {
                  courseID,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to save a workouts
  static SaveSettings(formData) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/live-course/settings/save`, formData, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to save a workouts
  static SaveModules(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/live-course/modules/save`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to save an FAQ
  static SaveFAQ(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/live-course/faqs/save`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to save an FAQ
  static UpdateFAQs(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/live-course/faqs/update`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to save a Guide
  static SaveGuide(formData) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/live-course/guide/save`, formData, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to save Guides changes
  static UpdateGuides(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/live-course/guides/update`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to save attachments
  static SaveAttachments(formData) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/live-course/attachments/save`, formData, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to save certificate
  static UpdateCertificate(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/live-course/certificate/update`, fields, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to assign a workout template to clients
  static AssignPlan(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/live-course/assign`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to get Upload Credentials
  static PrepareUpload(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/live-course/prepare-video-upload`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to get status of uplaoded video
  static GetVideoStatus(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/live-course/get-video-status`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to get video
  static GetVideo(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/live-course/get-video`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to delete video
  static DeleteVideo(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/live-course/delete-video`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Service to get students
  static GetStudents(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/live-course/students/get`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Service to remove students
  static RemoveStudent(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/live-course/students/remove`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }
}

export default LiveCourseService;
