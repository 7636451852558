<template>
  <div>
    <header>
      <div class="menu-btn" @click="toggleMobileMenu">
        <span></span><span></span><span></span>
      </div>
      <img src="@/assets/images/logo.png" class="logo" alt="Logo" />
      <menu :class="{ show: showMobileMenu }">
        <router-link to="/student/courses">Courses</router-link>
        <router-link to="/student/certificates">Certificates</router-link>
        <button @click="logoutUser()" class="btn btn-border sign-out-btn">
          Sign Out
        </button>
      </menu>
    </header>

    <div
      class="bg-overlay"
      :class="{ show: showMobileMenu }"
      @click="toggleMobileMenu"
    ></div>
  </div>
</template>


<script>
export default {
  name: "StudentHeader",
  data() {
    return {
      showMobileMenu: false,
    };
  },
  components: {},
  async created() {},
  methods: {
    // Toggle mobile menu
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
  },
};
</script>

<style scoped lang="scss">
header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fdf6e2;
  border-bottom: 1px solid #f7eac5;
  padding: 1rem 1.5rem;

  .logo-container {
    flex: 1;
  }

  img {
    display: block;
    width: 10rem;
  }

  .menu-btn {
    width: 1.5rem;
    margin-right: 1.5rem;
    cursor: pointer;

    span {
      display: block;
      width: 100%;
      height: 1.5px;
      border-radius: 0.5rem;
      background: darken(#fdf6e2, 55%);

      &:nth-child(2) {
        margin: 0.5rem 0;
      }
    }
  }

  menu {
    flex: 1;
    text-align: right;

    a {
      text-decoration: none;
      color: $blackColor;
      border-bottom: none;
      margin-right: 2rem;
      font-weight: $normalFontWeight;
      opacity: $mediumOpacity;
    }
  }
}

.menu-btn {
  display: none;
}

@media screen and (max-width: $mobileWidth) {
  header menu {
    display: block;
    text-align: center;
    position: fixed;
    padding: 2rem;
    background: #fff;
    border: 1px solid #ececec;
    bottom: 0rem;
    left: 1rem;
    width: calc(100% - 6rem);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    box-shadow: 0 0 0.7rem -0.15rem rgba(0, 0, 0, 0.25);
    z-index: 1000;
    transition: all 0.3s;
    transform: translateY(100%);

    &.show {
      transform: translateY(0%);
    }

    a {
      display: block;
      margin-right: 0;
      font-size: $mediumFontSize;
      margin-bottom: 1rem;
    }
  }

  .menu-btn {
    display: block;
  }
}
</style>