<template>
  <div>
    <div class="plan-card" @click="gotoPlan(plan._id)">
      <img
        v-if="plan.coverImageURL"
        :src="plan.coverImageURL"
        class="plan-image"
        alt="Client Plan"
      />
      <!-- START: Time Left -->
      <div class="access-wrapper">
        <div
          v-if="plan.accessSettings.lifetimeAccess != null"
          class="limited-access"
        >
          <unicon name="unlock-alt"></unicon>
          <span>Plan Access - {{ calculateAccessDays }} days left</span>
        </div>
        <div
          v-else-if="plan.accessSettings.lockedPlan"
          class="limited-access no-access"
        >
          <unicon name="lock"></unicon>
          <span>You no longer have access</span>
        </div>
        <div v-else class="limited-access lifetime-access">
          <unicon name="unlock-alt"></unicon>
          <span>Lifetime Access</span>
        </div>
      </div>
      <!-- END: Time Left -->

      <div class="plan-name-wrapper">
        <h3 class="plan-name">{{ plan.title }}</h3>

        <div class="plan-details-wrapper" v-if="!isPlanLocked()">
          <span
            >{{ plan.modules }}
            {{ plan.modules == 1 ? "Class" : "Classes" }} Available</span
          >
        </div>

        <button
          v-if="!isPlanLocked()"
          class="btn btn-primary"
          @click="gotoPlan(plan._id)"
        >
          Access Course
        </button>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "LiveCourseCard",
  data() {
    return {};
  },
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  computed: {
    calculateAccessDays: function () {
      let expiryDate = this.plan.createdAt;
      let daysLeft = this.plan.accessSettings.days;
      let today = this.moment();

      expiryDate = this.moment(expiryDate);
      expiryDate = expiryDate.add(daysLeft, "days");
      var daysRemaining = expiryDate.diff(today, "days");

      return daysRemaining;
    },
  },
  async created() {},
  methods: {
    gotoPlan(planID) {
      this.$emit("gotoPlan", planID, "video-course");
    },
    isPlanLocked() {
      // if (this.plan.accessSettings != undefined)
      //   return this.plan.accessSettings.lockedPlan == undefined ? false : true;
      // else return false;
      return false;
    },
  },
};
</script>
  
  <style scoped lang="scss">
.access-wrapper {
  position: relative;
}
.limited-access {
  display: none;
  text-align: center;
  background-color: $yellowColor;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  margin: 0.5rem 0 0.5rem;

  span {
    color: darken($yellowColor, 50%);
    font-weight: $mediumFontWeight;
    display: inline-block;
    vertical-align: middle;
  }

  .unicon /deep/ {
    svg {
      display: inline-block;
      vertical-align: middle;
      fill: darken($yellowColor, 50%);
      height: auto;
      width: 1rem;
      margin-right: 0.5rem;
    }
  }

  &.no-access {
    background-color: $redColor;

    span {
      color: lighten($redColor, 50%);
    }

    .unicon /deep/ {
      svg {
        fill: lighten($redColor, 50%);
      }
    }
  }
}

.lifetime-access {
  background-color: $darkGreenColor;

  span {
    color: darken($darkGreenColor, 50%);
  }

  .unicon /deep/ {
    svg {
      fill: darken($darkGreenColor, 50%);
    }
  }
}
</style>