<template>
  <div class="field-wrapper">
    <div class="field-money-item">
      <span class="money-label">{{ getCoachCurrency() }}</span>
      <input
        type="number"
        :name="field.name"
        :placeholder="field.placeholder"
        :required="field.required"
        :disabled="field.disabled != undefined ? field.disabled : false"
        :class="{ error: field.hasError }"
        @focus="field.hasError = false"
        @input="fieldChanged"
        v-model="field.value"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "MoneyField",
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  methods: {
    fieldChanged() {
      this.$emit("fieldChanged");
    },
  },
};
</script>