<template>
  <div class="coach-settings-modal" :class="{ show: show }">
    <!--START: Header-->
    <div class="modal-header">
      <unicon name="angle-down" @click="closeModal" />
      <h3>Add Student</h3>
    </div>
    <!--END: Header-->

    <div class="scroll-wrapper">
      <!--START: Form-->
      <form class="coach-form" @change="formChanged">
        <FormBuilder :fields="fields" @fieldChanged="formChanged"></FormBuilder>
      </form>
      <!--END: Form-->
    </div>

    <button
      class="btn btn-primary"
      :disabled="disableButton"
      @click="saveBooking"
    >
      Add Student
    </button>

    <!--START: Page Loader -->
    <LineLoader :showLoader="showLoader"></LineLoader>
    <!--END: Page Loader -->

    <!--START: Status Message-->
    <StatusMessage
      :show="status.show"
      :isSuccess="status.isSuccess"
      :successMessage="status.successMessage"
      :errorMessage="status.errorMessage"
    ></StatusMessage>
    <!--END: Status Message-->
  </div>
</template>

<script>
// Import services
import BookingService from "@/controllers/BookingService";
import VideoCourseService from "@/controllers/VideoCourseService";

// Import components
import LineLoader from "@/components/loaders/LineLoader";
import StatusMessage from "@/components/modals/StatusMessage";
import FormBuilder from "@/components/form/FormBuilder";

export default {
  name: "CoachAddBooking",
  data() {
    return {
      showLoader: false,
      disableButton: true,
      courses: [],
      selectedOfferingOption: null,
      selectedOffering: null,
      fields: {
        courses: {
          type: "dropdown",
          title: "Select Course",
          placeholder: "Select a course",
          required: true,
          hasError: false,
          fields: [],
          value: null,
        },
        name: {
          type: "text",
          title: "Client Name*",
          placeholder: "Enter the client name",
          required: true,
          hasError: false,
        },
        email: {
          type: "email",
          title: "Client Email*",
          placeholder: "Enter the client email",
          required: true,
          hasError: false,
        },
        phone: {
          type: "country-tel",
          title: "Client Phone*",
          placeholder: "Enter the client phone number",
          required: true,
          hasError: false,
        },
      },
      status: {
        show: false,
        isSuccess: true,
        successMessage: "🙌 Changes saved",
        errorMessage: "😕 Something's not right. Try again",
      },
    };
  },
  computed: {},
  props: {
    coach: {
      type: Object,
      required: false,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    LineLoader,
    StatusMessage,
    FormBuilder,
  },
  async created() {
    this.resetFields(this.fields);
    this.initCoursesDropdown();
  },
  methods: {
    //Save booking
    async saveBooking() {
      var timeoutHandler = null;
      clearTimeout(timeoutHandler);

      this.showLoader = true;
      this.status.successMessage = "🙌 Changes saved";
      this.status.errorMessage = "😕 Something's not right. Try again";
      this.status.show = false;
      this.status.isSuccess = false;

      //Check if the form has valid input
      var formFields = {
        ...this.fields,
      };

      var formValidation = this.validateForm(formFields);

      if (formValidation.hasErrors) {
        this.status.isSuccess = false;
        this.status.show = true;
      } else if (!this.disableButton) {
        this.disableButton = true;

        //Send the data and show message
        const response = await BookingService.AddCourseToStudent(this.fields);

        if (!response.hasError) {
          this.status.isSuccess = true;
          setTimeout(() => (this.$emit("bookingCompleted"), 1000));
        } else {
          this.status.isSuccess = false;
          this.status.errorMessage = response.error;
        }
      }

      this.status.show = true;
      this.disableButton = true;
      this.showLoader = false;

      //Hide the notification
      timeoutHandler = setTimeout(() => (this.status.show = false), 3000);
    },

    async initCoursesDropdown() {
      const response = await VideoCourseService.GetCourses();
      if (!response.hasError) {
        const coursesFields = [];
        this.courses = response.data;

        this.courses.forEach(function (course) {
          coursesFields.push({
            name: course.title,
            value: { id: course._id, slug: course.title },
          });
        });

        this.fields.courses.fields = coursesFields;
      }
    },

    formChanged() {
      this.disableButton = false;
    },

    hideBackground() {
      this.showBackground = false;
    },

    showSuccessMessage() {
      this.$parent.status.isSuccess = true;
      this.$parent.status.show = true;
      setTimeout(() => (this.$parent.status.show = false), 3000);
    },

    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.coach-settings-modal {
  display: none;
  height: auto !important;
  bottom: auto !important;
  width: 40vw !important;

  .coach-form {
    padding-bottom: 0 !important;
  }
}

.selected-offering-wrapper {
  span {
    display: block;
    background: lighten($darkGreenColor, 50%);
    color: darken($darkGreenColor, 20%);
    border: 1px solid darken($darkGreenColor, 5%);
    padding: 0.5rem 1rem;
    text-align: center;
    border-radius: 0.5rem;
    width: calc(100% - 3rem);
    margin-top: -1rem;
    margin-left: 0.5rem;
  }
}

.status-message {
  left: 0 !important;
}

@media screen and (max-width: $mobileWidth) {
  .coach-settings-modal.show {
    display: block;
    width: calc(100vw - 2rem) !important;
    left: 50% !important;
    bottom: 0 !important;
    transform: translateX(-50%) !important;
  }
}
</style>