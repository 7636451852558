<template>
  <div v-if="showComponent" class="authentication-component">
    <div class="authentication-wrapper">
      <img src="@/assets/images/logo.png" class="logo" alt="Logo" />

      <div class="form-wrapper">
        <h1>Admin Login</h1>
        <p>Please login to your dashboard</p>

        <!--START: Status Message-->
        <StatusMessage
          :show="status.show"
          :isSuccess="status.isSuccess"
          :errorMessage="status.errorMessage"
        ></StatusMessage>
        <!--END: Status Message-->

        <!--START: Email Form Wrapper-->
        <form v-on:submit.prevent="loginUser()">
          <FormBuilder
            :fields="fields"
            @fieldChanged="fieldChanged()"
          ></FormBuilder>
          <button type="submit" class="btn btn-primary">
            Sign In
            <unicon name="angle-right-b"></unicon>
          </button>
        </form>
        <!--END: Form Wrapper-->
      </div>
    </div>

    <!--START: Line Loader-->
    <LineLoader :showLoader="showLoader"></LineLoader>
    <!--END: Line Loader-->
  </div>
</template>

<script>
//Importing services
import AuthService from "@/controllers/AuthService";

//Importing components
import LineLoader from "@/components/loaders/LineLoader";
import FormBuilder from "@/components/form/FormBuilder";
import StatusMessage from "@/components/modals/StatusMessage";

export default {
  name: "Login",
  data() {
    return {
      showComponent: false,
      showLoader: false,
      showEmailLogin: true,
      showOTPFields: false,
      status: {
        show: false,
        isSuccess: false,
        errorMessage: "The e-mail and password don't match",
      },
      fields: {
        email: {
          type: "email",
          placeholder: "E-mail address",
          required: true,
          hasError: false,
        },
        password: {
          type: "password",
          placeholder: "Password",
          required: true,
          hasError: false,
        },
      },
    };
  },
  components: {
    LineLoader,
    FormBuilder,
    StatusMessage,
  },
  async created() {
    if (!(await this.authenticateCoach("/dashboard")))
      this.showComponent = true;
  },
  methods: {
    async loginUser() {
      //Hide status message and show line loader
      this.status.show = false;
      this.showLoader = true;

      //Check if the form has valid input
      var formValidation = this.validateForm(this.fields);
      if (!formValidation.hasErrors) {
        //Use the service to authenticate the user
        const coach = await AuthService.LoginUser(this.fields);
        if (coach.hasError) {
          this.status.show = true;
          this.status.errorMessage = coach.error;
        } else if (!coach.hasError) {
          this.redirectUser(coach.data);
        }
      }
      //Hide line loader
      this.showLoader = false;
    },

    redirectUser(coach) {
      this.$store.commit("updateCoach", {
        coach: coach,
        loggedIn: true,
      });
      this.$router.push({ path: "/dashboard" });
    },

    fieldChanged() {
      this.status.show = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/authentication.scss";

.btn-client {
  position: fixed;
  top: 2rem;
  right: 2rem;
  color: #d8d9e5;
}

.login-disclaimer {
  margin: 1rem 0 0;
  em {
    font-size: 1.25rem;
    margin-right: 0.5rem;
  }
  p {
    font-size: $smallerFontSize;
    color: $greyColor;
    flex: 1;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
    font-size: $smallestFontSize;
    font-weight: $mediumFontWeight;
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;
    line-height: $smallestFontSize;
    background-color: lighten($yellowColor, 0%);
    color: darken($yellowColor, 45%);
  }
}

@media screen and (max-width: $mobileWidth) {
  .btn-client {
    top: auto;
    right: auto;
    bottom: 1.5rem;
    left: 1.5rem;
  }
}
</style>