<template>
  <div class="dashboard" v-if="showComponent">
    <div class="course-wrapper">
      <!-- START: Module Header -->
      <div class="module-header dashboard-header">
        <div class="course-builder-title-wrapper">
          <h3>Live Course Builder</h3>
          <!--START: Back Button-->
          <router-link
            to="/dashboard/live-courses"
            class="btn btn-border btn-small btn-icon"
          >
            <unicon name="angle-left-b"></unicon>
            <span>Go Back</span>
          </router-link>
          <!--END: Back Button-->
        </div>

        <div class="module-header-wrapper">
          <div class="module-info-wrapper"></div>
        </div>

        <!--START: Plan Menu-->
        <div class="plan-menu">
          <router-link
            :to="`/dashboard/live-course-builder/${
              plan._id != undefined ? plan._id + '/' : ''
            }details`"
            class="plan-menu-item"
          >
            <unicon name="setting"></unicon>
            <span>Details</span>
          </router-link>
          <router-link
            :to="`/dashboard/live-course-builder/${
              plan._id != undefined ? plan._id + '/' : ''
            }course`"
            class="plan-menu-item"
          >
            <unicon name="dumbbell"></unicon>
            <span>Course Builder</span>
          </router-link>
          <router-link
            :to="`/dashboard/live-course-builder/${
              plan._id != undefined ? plan._id + '/' : ''
            }guides`"
            class="plan-menu-item"
          >
            <unicon name="analysis"></unicon>
            <span>Guides</span>
          </router-link>
          <router-link
            :to="`/dashboard/live-course-builder/${
              plan._id != undefined ? plan._id + '/' : ''
            }faqs`"
            class="plan-menu-item"
          >
            <unicon name="question"></unicon>
            <span>FAQs</span>
          </router-link>
          <router-link
            :to="`/dashboard/live-course-builder/${
              plan._id != undefined ? plan._id + '/' : ''
            }attachments`"
            class="plan-menu-item"
          >
            <unicon name="paperclip"></unicon>
            <span>Attachments</span>
          </router-link>
          <span class="menu-seperator"></span>
          <router-link
            :to="`/dashboard/live-course-builder/${
              plan._id != undefined ? plan._id + '/' : ''
            }students`"
            class="plan-menu-item"
          >
            <unicon name="user"></unicon>
            <span>Students</span>
          </router-link>
          <router-link
            :to="`/dashboard/live-course-builder/${
              plan._id != undefined ? plan._id + '/' : ''
            }certificate`"
            class="plan-menu-item"
          >
            <unicon name="award"></unicon>
            <span>Certificate</span>
          </router-link>
        </div>
        <!--END: Plan Menu-->

        <!--START: Actions Wrapper-->
        <div class="module-actions-wrapper">
          <button
            class="btn btn-primary tooltip-wrapper"
            :disabled="disableButton"
            @click="saveVideoCourse"
          >
            <unicon name="save"></unicon>
            <span>Save Course</span>
            <span class="tooltip" v-if="disableButton"
              >Add a workout to enable</span
            >
          </button>
        </div>
        <!--END: Actions Wrapper-->
      </div>
      <!-- END: Module Header -->

      <!--START: Router Wrapper-->
      <div class="modal-route show">
        <div class="modal-content">
          <router-view
            :plan="plan"
            class="modal-block modal-builder"
            @compareState="compareState"
            @updatePlan="updatePlan"
            @resetPlan="resetCourse"
            @saveCourse="saveVideoCourse"
          ></router-view>
        </div>
      </div>
      <!--END: Router Wrapper-->
    </div>

    <!--START: Status Message-->
    <StatusMessage
      :show="status.show"
      :isSuccess="status.isSuccess"
      :successMessage="status.successMessage"
      :errorMessage="status.errorMessage"
    ></StatusMessage>
    <!--END: Status Message-->

    <!--START: Page Loader -->
    <PageLoader :showLoader="showLoader"></PageLoader>
    <!--END: Page Loader -->

    <!--START: Loaders-->
    <LineLoader :showLoader="showLineLoader"></LineLoader>
    <div
      class="bg-overlay"
      :class="{ show: showBackground }"
      @click="hideBackground()"
    ></div>
    <!--END: Loaders-->
  </div>
</template>

<script>
import _ from "lodash";

//Import service to get Plan details
import LiveCourseService from "@/controllers/LiveCourseService";

//Importing dashboard components
import PageLoader from "@/components/loaders/PageLoader";
import LineLoader from "@/components/loaders/LineLoader";
import StatusMessage from "@/components/modals/StatusMessage";

export default {
  name: "PlanDashboard",
  data() {
    return {
      showComponent: false,
      showZeroState: false,
      showBackground: false,
      showLoader: false,
      showLineLoader: false,
      disableButton: true,
      disableAssignPlanButton: false,
      saveModule: null,
      moduleOptions: {
        title: "Your Plan",
        description: "Build your online plan",
      },
      plan: {
        modules: [],
      },
      initPlan: {},
      attachmentsFields: {
        attachments: {
          type: "file-large",
          fileType: "any",
          fileInstruction: "Upload a single file - PDF, XLS, DOCX etc.",
          fileList: [],
          imagesList: [],
          required: false,
          hasError: false,
        },
      },
      showOptions: {
        assignWorkout: false,
      },
      status: {
        show: false,
        isSuccess: true,
        successMessage: "🙌 Changes saved",
        errorMessage: "😕 Something's not right. Try again",
      },
    };
  },
  components: {
    PageLoader,
    LineLoader,
    StatusMessage,
  },
  async created() {
    this.showLoader = true;

    if (_.isEmpty(this.coach)) {
      await this.authenticateCoach("/sign-in", false);
      this.coach = this.$store.state.coach.coach;
    }

    await this.getVideoCourse();

    this.showLoader = false;
    this.showComponent = true;
  },
  methods: {
    async getVideoCourse() {
      this.plan.id = this.$route.params.courseID;

      if (this.plan.id != null && this.plan.id != undefined) {
        const response = await LiveCourseService.GetCourse(this.plan.id);

        if (!response.hasError) {
          this.status.isSuccess = true;

          this.plan = response.data;
          this.initPlan = _.cloneDeep(this.plan);
        } else {
          this.status.isSuccess = false;
          this.status.errorMessage = response.error;
        }

        this.moduleOptions.title = this.plan.title;
        this.disableAssignPlanButton = false;
      }
    },

    async saveVideoCourse() {
      if (!this.disableButton) {
        var response = {};
        this.showLineLoader = true;

        switch (this.saveModule) {
          case "settings":
            response = await this.saveSettings();
            break;
          case "modules":
            response = await this.saveModules();
            break;
          case "guides":
            response = await this.saveGuides();
            break;
          case "faqs":
            response = await this.saveFAQs();
            break;
          case "attachments":
            response = await this.saveAttachments();

            //Clear Attachments
            this.attachmentsFields.attachments.fileList = [];
            this.attachmentsFields.attachments.imagesList = [];
            break;
          case "certificate":
            response = await this.saveCertificate();
            break;
          default:
            this.disableButton = true;
        }

        if (!_.isEmpty(response)) {
          this.status.show = false;
          this.statusFlag = true;

          if (!response.hasError) {
            this.status.isSuccess = true;
            const plan = response.data;
            this.plan = plan;
            this.initPlan = _.cloneDeep(this.plan);
          } else {
            this.status.isSuccess = false;
            this.status.errorMessage = response.error;
          }

          this.disableButton = true;
          this.disableAssignPlanButton = false;
          this.showLineLoader = false;
          this.status.show = true;
          setTimeout(() => {
            this.status.show = false;
          }, 2000);
        }

        this.showLineLoader = false;
      }
    },

    updatePlan(newPlan) {
      this.plan = newPlan;
      this.initPlan = _.cloneDeep(this.plan);
    },

    resetCourse() {
      this.plan = _.cloneDeep(this.initPlan);
      //Clear Attachments
      this.attachmentsFields.attachments.fileList = [];
      this.attachmentsFields.attachments.imagesList = [];
    },

    async saveSettings() {
      var formData = new FormData();
      formData.append("courseID", this.plan._id);
      formData.append("title", this.plan.title);
      formData.append(
        "accessSettings",
        JSON.stringify(this.plan.accessSettings)
      );

      // Check for cover image
      if (!_.isEmpty(this.plan.coverImage)) {
        formData.append("coverImageUploaded", "true");
        const imageFile = new File(
          this.plan.coverImage.blob,
          this.plan.coverImage.name,
          {
            type: "image/jpg",
            lastModified: new Date().getTime(),
          }
        );

        formData.append("images", imageFile);
      }

      return await LiveCourseService.SaveSettings(formData);
    },

    async saveModules() {
      var fields = {
        course: {
          id: this.plan._id,
          modules: this.plan.modules,
        },
      };
      return await LiveCourseService.SaveModules(fields);
    },

    async saveGuides() {
      var fields = {
        course: {
          id: this.plan._id,
          guides: this.plan.guides,
        },
      };

      return await LiveCourseService.UpdateGuides(fields);
    },

    async saveFAQs() {
      var fields = {
        course: {
          id: this.plan._id,
          faqs: this.plan.faqs,
        },
      };

      return await LiveCourseService.UpdateFAQs(fields);
    },

    async saveAttachments() {
      var formData = new FormData();
      //Add newly uploaded images
      for (const i of Object.keys(
        this.attachmentsFields.attachments.fileList
      )) {
        formData.append(
          "attachments",
          this.attachmentsFields.attachments.fileList[i]
        );
      }
      formData.append("courseID", this.plan._id);
      formData.append(
        "courseAttachments",
        JSON.stringify(this.plan.attachments)
      );
      return await LiveCourseService.SaveAttachments(formData);
    },

    async saveCertificate() {
      var fields = {
        course: {
          id: this.plan._id,
          certificate: this.plan.certificate,
        },
      };

      return await LiveCourseService.UpdateCertificate(fields);
    },

    compareState() {
      if (
        !_.isEqual(this.initPlan, this.plan) ||
        this.attachmentsFields.attachments.fileList.length > 0
      ) {
        this.disableButton = false;
      } else {
        this.disableButton = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.dashboard {
  position: fixed;
  top: 4rem;
  left: 0;
  width: 100%;
  height: calc(100% - 4rem);
  z-index: 1000;

  .status-message {
    top: 11rem;
    z-index: 11;
  }

  .modal-builder {
    /deep/ .module-header {
      .module-title {
        font-size: $mediumFontSize;
        margin-bottom: 0;
      }
    }

    /deep/ .status-message.show {
      z-index: 11;
    }
  }

  .modal-content {
    margin-top: 5rem;
  }
}

.course-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: calc(100vh - 4rem);
  align-items: stretch;
  align-content: center;
}

.course-builder-title-wrapper {
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #ececec;
  display: flex;
  align-items: center;
  flex-direction: row;

  h3 {
    font-size: $normalFontSize;
    font-weight: $mediumFontWeight;
    color: $greyColor;
    flex: 1;
  }
}

.modal-route {
  position: relative;
  flex: 1;
  max-height: calc(100vh - 4rem);
  overflow-y: scroll;
}

.dashboard-header.module-header {
  position: relative;
  display: block;
  z-index: 11 !important;
  top: auto;
  left: auto;
  height: auto;
  width: 17.5rem;
  padding: 0.75rem 1rem 0.75em 1.5rem;
  margin: 0;

  .module-header-wrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }

  .module-actions-wrapper {
    position: absolute;
    background-color: $whiteColor;
    padding: 1rem;
    top: auto;
    bottom: 3rem;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0;
    flex: none;

    button {
      margin-top: 0.5rem;
      display: block;
      width: calc(100% - 2rem);
    }
  }
}

.plan-menu {
  padding: 0.65rem 0;
  margin: 0.5rem 0 -1rem -1.5rem;
  width: calc(100% + 2.5rem);

  .plan-menu-item {
    margin: 0 1.5rem;
    padding: 0.35rem 0.75rem;
    border-radius: 0.5rem;
    border-bottom: none;
    color: $blackColor;
    border: 1px solid transparent;
    opacity: 0.75;
    font-size: $normalFontSize;
    padding: 0.65rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    .unicon /deep/ svg {
      width: 0.9rem;
      height: auto;
      margin-right: 0.5rem;
      fill: $darkBlueColor;
    }

    &.router-link-active {
      opacity: 1;

      background-color: #faf4e1;
      border: 1px solid #e8cc74;
      color: #736434;
      font-weight: $mediumFontWeight;

      .unicon /deep/ svg {
        fill: #736434;
      }
    }
  }
}

/deep/ {
  .modal-block {
    &.guides-block,
    &.faqs-block,
    &.attachments-block {
      margin: 7rem 15% 2rem;
    }

    .block-wrapper {
      border-radius: 1rem;
      border: 1px solid #ececec;
    }
  }
}

.menu-seperator {
  display: block;
  border-top: 1px dashed #ccc;
  margin: 1rem 2.5rem;
}
</style>