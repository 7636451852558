<template>
  <div class="certificates-list">
    <div
      v-for="certificate in certificates"
      :key="certificate.id"
      class="certificate"
      @click="showCertificate(certificate)"
    >
      <img src="@/assets/images/certificate-icon.png" class="icon" alt="Logo" />
      <h3>{{ certificate.name }}</h3>
      <div class="level">
        <span>{{ certificate.level }}</span>
      </div>
      <div class="issue-date">
        <span>{{ moment(certificate.issueDate).format("DD MMMM, YYYY") }}</span>
        <label>Date of Issue</label>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "CertificatesTable",
  props: {
    certificates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  components: {},
  methods: {
    showCertificate(certificate) {
      this.$emit("showCertificate", certificate);
    },
  },
};
</script>
    
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.certificates-list {
  margin: 0 auto -5rem;
  max-width: 25rem;
  position: relative;
  z-index: 10;
}
.certificate {
  @include transition;
  text-align: center;
  background: $whiteColor;
  border-radius: 1rem;
  border: 1px solid #ececec;
  padding: 1rem;
  cursor: pointer;
  box-shadow: 0 0.5rem 0.7rem -0.1rem transparent;

  &:hover {
    box-shadow: 0 0 0.7rem -0.1rem rgba(0, 0, 0, 0.15);
  }
  .icon {
    display: block;
    width: 7rem;
    margin: 1rem auto 0.5rem;
  }

  h3 {
    font-size: $largestFontSize;
    flex: 1;
  }

  .level,
  .issue-date {
    text-align: center;

    label {
      display: block;
      opacity: 0.75;
      font-size: $smallestFontSize;
      margin-top: 0.25rem;
    }
  }

  .level {
    span {
      font-size: $smallestFontSize;
      display: inline-block;
      background-color: lighten($greenColor, 15%);
      border: 1px solid lighten($greenColor, 5%);
      padding: 0.5rem 0.75rem;
      color: darken($greenColor, 75%);
      font-weight: $mediumFontWeight;
      border-radius: 0.5rem;
      margin-bottom: 1rem;
    }
  }

  .issue-date {
    span {
      font-size: $smallerFontSize;
    }
  }
}
</style>