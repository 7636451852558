require("dotenv").config();
import axios from "axios";

//Importing Variable mixins
import VariablesMixins from "../mixins/Variables";
const apiURL = VariablesMixins.APIURL;

class CoachService {
  //Service to get coach
  static GetCoach() {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .get(`${apiURL}/user/`, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to update coach
  static UpdateCoach(formData) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/coach/update`, formData, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to update coach settings
  static UpdateCoachSettings(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/coach/update/settings`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to update coach
  static UpdateCoachTheme(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/coach/update/theme`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to update coach social handles
  static UpdateCoachSocialHandles(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/coach/update/social`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  if (response.status == 200) {
                    return response.data;
                  } else return false;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to add coach certification
  static AddCoachCertification(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/coach/add/certification`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to update coach certifications
  static UpdateCoachCertifications(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/coach/update/certifications`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to add coach promotion
  static AddCoachPromotionalLink(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/coach/add/promotional-link`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to update coach promotion
  static UpdateCoachPromotionalLinks(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/coach/update/promotional-links`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to add coach testimonial
  static AddCoachTestimonial(formData) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/coach/add/testimonial`, formData, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to add coach gallery
  static UpdateCoachGallery(formData) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/coach/add/gallery`, formData, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  if (response.status == 200) {
                    return response.data;
                  } else return false;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to update coach testimonials
  static UpdateCoachTestimonials(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/coach/update/testimonials`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to add coach plan
  static AddCoachPlan(formData) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/coach/add/plan`, formData, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to add coach plan
  static ReorderOfferings(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/coach/reorder/offerings`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to add coach plan
  static DeletePlan(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/coach/delete/plan`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Get Bank details of coach
  static GetBankDetails() {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .get(`${apiURL}/coach/bank-details`, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to add bank details
  static AddBankDetails(formData) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/coach/add/bank-details`, formData, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to add bank details
  static TogglePayments(formData) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/coach/toggle/payments`, formData, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to create a new group
  static SaveCoachGroup(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/coach/group/save`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  if (response.status == 200) {
                    return response.data;
                  } else return false;
                },
                () => {
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to delete a coach group
  static DeleteCoachGroup(groupID) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/coach/group/delete`,
                {
                  groupID,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  if (response.status == 200) {
                    return true;
                  } else return false;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to assign workout to a plan
  static AssignWorkout(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/coach/assign-workout`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to check slug
  static CheckSlug(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/coach/check-slug`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  if (response.status == 200) {
                    return response.data;
                  } else return false;
                },
                (error) => {
                  console.log(error);
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to check email
  static CheckEmail(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/coach/check-email`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  if (response.status == 200) {
                    return response.data;
                  } else return false;
                },
                (error) => {
                  console.log(error);
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to check email
  static CheckPhone(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/coach/check-phone`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  if (response.status == 200) {
                    return response.data;
                  } else return false;
                },
                (error) => {
                  console.log(error);
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Disable Onboarding
  static DisableOnboarding() {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/coach/disable-onboarding`,
                {},
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Enable Onboarding
  static EnableOnboarding(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/coach/enable-onboarding`,
                {
                  fields,
                },
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  //Service to get customers
  static GetStudents() {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .get(`${apiURL}/students/all`, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }
}

export default CoachService;
