<template>
  <div>
    <div class="title-wrapper"></div>
    <div class="column-block">
      <div>
        <!--START: Video Wrapper with Dropzone-->
        <div class="video-wrapper">
          <label class="field-title">Module Video</label>

          <div class="fetching-video-loader" v-if="fetchingVideo">
            <img
              src="@/assets/images/loading-pullup.gif"
              alt="Loading animation"
            />
            <span>Fetching Video</span>
          </div>
          <div v-else-if="hasVideo" class="video-player-wrapper">
            <div id="video-player"></div>
            <button
              type="button"
              class="btn btn-text btn-border"
              @click="deleteVideo"
            >
              Delete Video
            </button>
          </div>

          <!--START: Video Processing-->
          <div v-if="videoProcessing" class="video-processing-wrapper">
            <unicon name="check"></unicon>
            <h3>Video Processing</h3>
            <span
              >Your video has been uploaded and is now processing, you don't
              need to stay on this page for it to complete</span
            >
            <LineLoader :showLoader="true"></LineLoader>
          </div>
          <!--END: Video Processing-->
        </div>
        <div v-if="!hasVideo" class="video-id-input">
          <FormBuilder
            :fields="videoIDFields"
            @fieldChanged="videoIDAdded"
            @input="videoIDAdded"
          ></FormBuilder>
        </div>
        <!--END: Video Wrapper with Dropzone-->

        <!--START: Basic Fields-->
        <FormBuilder
          :fields="fields"
          @fieldChanged="formChanged"
          @input="formChanged"
        ></FormBuilder>
        <!--END: Basic Fields-->
      </div>
    </div>

    <div class="column-block attachments-wrapper customer-settings">
      <div class="block-info-wrapper">
        <div class="block-info">
          <span class="block-label">Customer Access Settings</span>
          <p class="block-description">
            Choose after how many days or weeks the customer gets access to this
            content. <br /><b>Note: </b>This is calculated from when the
            customer starts their course
          </p>
        </div>
      </div>

      <!--START: Visibility Settings-->
      <FormBuilder
        class="visibility-form"
        :fields="visibilityFields"
        @fieldChanged="formChanged"
      ></FormBuilder>
      <!--END: Visibility Settings-->

      <!--START: Access Days Settings-->
      <div v-if="!visibilityFields.isVisible.value" class="access-wrapper">
        <div class="block-info">
          <span class="block-label">Buffer Settings</span>
          <p class="block-description">
            Specify the duration, after which a customer gets access to this
            module
          </p>
        </div>
        <FormBuilder
          class="access-form"
          :fields="daysFields"
          @fieldChanged="formChanged"
        ></FormBuilder>
      </div>
      <!--END: Access Days Settings-->
    </div>

    <!--START: VDO Script-->
    <script
      type="application/javascript"
      defer
      src="https://player.vdocipher.com/playerAssets/1.6.10/vdo.js"
    ></script>
    <!--END: VDO Script-->
  </div>
</template>

<script>
//Importing Components
import LineLoader from "@/components/loaders/LineLoader";
import FormBuilder from "@/components/form/FormBuilder";

//Import Service
import VideoCourseService from "@/controllers/VideoCourseService";

export default {
  name: "ModulesTree",
  props: {
    module: {
      type: Object,
      required: true,
    },
  },
  watch: {
    module: function () {
      this.initModule();
    },
  },
  data() {
    return {
      updatedModule: {},
      videoIDFields: {
        fields: {
          type: "group",
          nestFields: false,
          fields: {
            videoID: {
              type: "text",
              title: "VdoCipher Video ID",
              placeholder: "Add the video ID",
              required: false,
              hasError: false,
              value: "",
            },
            accountEmail: {
              type: "dropdown",
              placeholder: "Select an account",
              title: "VdoCipher Account",
              required: true,
              hasError: false,
              fields: [
                {
                  name: "tanjorecollective@gmail.com",
                  value: "tanjorecollective@gmail.com",
                },
                {
                  name: "contact@tanjorecollective.com",
                  value: "contact@tanjorecollective.com",
                },
              ],
              value: null,
              readOnly: false,
            },
          },
        },
      },
      fields: {
        title: {
          type: "text",
          title: "Title*",
          additionalClass: "large-field",
          placeholder: "Add your title",
          required: true,
          hasError: false,
          value: this.module.title,
        },
        description: {
          type: "text-editor",
          title: "Description*",
          placeholder: "Add your description",
          required: true,
          hasError: false,
          value: null,
        },
      },
      visibilityFields: {
        isVisible: {
          type: "toggle",
          value: true,
          placeholder: "Allow immediate access",
        },
      },
      daysFields: {
        days: {
          type: "number",
          title: "Number",
          placeholder: "0",
          required: false,
          hasError: false,
        },
        unit: {
          type: "dropdown",
          title: "Type",
          placeholder: "Choose Type",
          required: true,
          hasError: false,
          fields: [
            { name: "Days", value: "days" },
            { name: "Weeks", value: "weeks" },
          ],
          value: ["Days", "days"],
        },
      },
      awsOptions: {},
      options: {},
      videoID: null,
      videoStatusInterval: null,
      videoProcessing: false,
      fetchingVideo: false,
      hasVideo: false,
    };
  },
  async created() {
    this.initModule();
  },
  computed: {},
  components: {
    FormBuilder,
    LineLoader,
  },
  methods: {
    initModule() {
      this.fields.title.value =
        this.module.title != undefined ? this.module.title : null;
      this.fields.description.value =
        this.module.description != undefined ? this.module.description : null;

      this.hasVideo = false;
      this.videoProcessing = false;
      this.fetchingVideo = false;

      this.daysFields.days.value =
        this.module.visibility.numberOfDays != undefined
          ? this.module.visibility.numberOfDays
          : null;

      this.visibilityFields.isVisible.value =
        this.module.visibility.isVisible != undefined
          ? this.module.visibility.isVisible
          : false;

      this.videoID =
        this.module.videoURL != undefined ? this.module.videoURL : null;
      if (this.module.accountEmail)
        this.videoIDFields.fields.fields.accountEmail.value = [
          this.module.accountEmail,
          this.module.accountEmail,
        ];

      clearInterval(this.videoStatusInterval);
      if (this.videoID != null) {
        this.fetchingVideo = true;
        this.hasVideo = true;
        this.videoStatusInterval = setInterval(
          () => this.getVideoStatus(),
          5000
        );
      }
    },

    //Get video credentials
    async getCredentials(videoTitle) {
      return await VideoCourseService.PrepareUpload({
        videoTitle: videoTitle,
      });
    },

    async videoUploaded() {
      this.videoProcessing = true;

      //Get status of the uploaded video
      clearInterval(this.videoStatusInterval);
      this.videoStatusInterval = setInterval(() => this.getVideoStatus(), 5000);
    },

    async getVideoStatus() {
      if (this.videoID != null) {
        const response = await VideoCourseService.GetVideoStatus({
          videoID: this.videoID,
          accountEmail: this.module.accountEmail,
        });

        if (response.data.status == "ready") {
          clearInterval(this.videoStatusInterval);
          this.videoProcessing = false;
          this.getVideo();
        }
      } else clearInterval(this.videoStatusInterval);
    },

    async getVideo() {
      this.hasVideo = true;

      const response = await VideoCourseService.GetVideo({
        videoID: this.videoID,
        accountEmail: this.module.accountEmail,
      });

      this.fetchingVideo = false;
      setTimeout(() => {
        new window.VdoPlayer({
          otp: response.data.otp,
          playbackInfo: response.data.playbackInfo,
          theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
          container: document.querySelector("#video-player"),
        });
      }, 50);
    },

    async deleteVideo() {
      await VideoCourseService.DeleteVideo({
        videoID: this.videoID,
        accountEmail: this.module.accountEmail,
      });
      this.hasVideo = false;
      this.videoProcessing = false;
    },

    prepareModuleData() {
      let selectedUnit = this.daysFields.unit.value;
      let numberOfDays = this.daysFields.days.value;

      if (selectedUnit[1] == "weeks") selectedUnit = 7;
      else selectedUnit = 1;

      this.updatedModule = {
        videoURL: this.videoID,
        accountEmail: this.videoIDFields.fields.fields.accountEmail.value[0],
        title: this.fields.title.value,
        description: this.fields.description.value,
        visibility: {
          isVisible: this.visibilityFields.isVisible.value,
          numberOfDays: numberOfDays * selectedUnit,
        },
      };
    },

    formChanged() {
      this.prepareModuleData();
      this.$emit("moduleUpdated", this.updatedModule);
    },

    videoIDAdded() {
      this.videoID = this.videoIDFields.fields.fields.videoID.value;
      this.formChanged();
    },
  },
};
</script>

<style scoped lang="scss">
.column-block {
  background-color: $whiteColor;
  padding: 1rem;
  border: 1px solid #e2e2e2;
  border-radius: 1rem;
  margin-bottom: 2rem;

  .block-label {
    font-size: 1rem;
    color: $blackColor;
    font-weight: $mediumFontWeight;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  .block-description {
    color: $greyColor;
    margin: 1rem 0 1.25rem;
  }
}

.title-wrapper {
  margin-bottom: 1.5rem;

  .editor-title {
    color: $darkBlueColor;
    text-transform: capitalize;
    font-size: $largeFontSize;
    margin: 0 1.5rem 0 0.5rem;
  }
}

.video-wrapper {
  margin-bottom: 1.5rem;
}

.file-size-max {
  display: block;
  margin-top: 0.5rem;
}

.attachments-wrapper {
  .block-description {
    > span {
      display: table;
      color: darken($darkGreenColor, 45%);
      font-size: $smallestFontSize;
      background-color: $darkGreenColor;
      font-weight: $mediumFontWeight;
      padding: 0.2rem 0.35rem;
      margin: 1rem 0 0.25rem -0.15rem;
      border-radius: 0.25rem;
    }
  }
  /deep/ .file-item {
    border: 1px solid darken($greenColor, 20%);
    padding: 0.75rem 1.25rem;

    span {
      font-size: $normalFontSize;
    }
  }

  .attachments-list {
    .label-small {
      margin: 0 0 1.5rem;
    }
  }

  .attachment-file {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;

    img {
      display: block;
      width: 3rem;
      margin-right: 1rem;
    }

    .file-name {
      font-size: $normalFontSize;
      display: block;
    }

    span,
    a {
      font-size: $smallerFontSize;
      cursor: pointer;
      border-bottom: none;
      margin-right: 0.5rem;
    }

    a:hover {
      color: darken($blueColor, 20%);
    }

    span {
      color: $greyColor;

      &:hover {
        color: $redColor;
      }
    }
  }
}

.visibility-form {
  margin-top: 2rem;
  padding-top: 0.5rem;
  border-top: 1px dashed #cecece;
  /deep/ {
    .field-toggle-item {
      flex-direction: row !important;
    }

    label {
      margin-left: 1rem !important;
      width: auto;
    }
  }
}

.customer-settings {
  display: none !important;
}

.video-processing-wrapper {
  padding: 1rem 15% 2rem;
  border-radius: 1rem;
  background-color: lighten($purpleColor, 41%);
  border: 2px dashed lighten($purpleColor, 30%);
  text-align: center;

  .unicon {
    /deep/ {
      svg {
        background-color: lighten($darkGreenColor, 35%);
        fill: darken($darkGreenColor, 20%);
        border: 1px solid lighten($darkGreenColor, 20%);
        height: auto;
        width: 2rem;
        padding: 0.15rem;
        border-radius: 50%;
      }
    }
  }

  h3 {
    color: $purpleColor;
    font-size: $mediumFontSize;
  }

  span {
    color: $greyColor;
    font-size: $smallFontSize;
    line-height: 1.3;
  }

  /deep/ .line-loader {
    position: relative;
    margin: 1rem 0 -0.5rem;
    border-radius: 1rem;
    height: 0.5rem;
  }
}

.video-player-wrapper {
  text-align: center;
}
#video-player {
  margin: 0 0 1rem;
  border-radius: 0.75rem;
  overflow: hidden;
}

.access-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  /deep/ {
    .field-main-item {
      flex: 1;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.fetching-video-loader {
  text-align: center;

  img {
    display: block;
    margin: 0 auto 1rem;
    width: 4.5rem;
  }

  span {
    display: block;
    color: $greyColor;
  }
}

.video-compress-wrapper p {
  margin-top: 1rem;
  color: $greyColor;
}

/deep/ .field-group-wrapper {
  .field-main-item {
    margin-bottom: 0 !important;
  }
}
</style>