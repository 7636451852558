<template>
  <div class="client-dashboard-wrapper" v-if="showComponent">
    <!--START: Header-->
    <Header></Header>
    <!--END: Header-->

    <!--START: Welcome Message-->
    <div class="welcome-message">
      <div>
        <h3>Namaste, {{ client.name }}</h3>
        <p>Here are your certificates</p>
      </div>
    </div>
    <!--END: Welcome Message-->

    <!--START: Certificates List-->
    <CertificatesTable
      :certificates="client.certificates"
      @showCertificate="showCertificateModal"
    ></CertificatesTable>
    <!--END: Certificates List-->

    <!--START: Certificate-->
    <Certificate
      :show="showCertificate"
      :studentName="client.name"
      :certificate="selectedCertificate"
    ></Certificate>
    <div
      class="bg-overlay"
      :class="{ show: showCertificate }"
      @click="hideCertificateModal"
    ></div>
    <!--END: Certificate-->

    <!--START: Footer-->
    <Footer></Footer>
    <!--END: Footer-->
  </div>
</template>
  
<script>
// Import components
import Header from "@/components/student/Header";
import Footer from "@/components/student/Footer";
import CertificatesTable from "@/components/student/CertificatesTable";
import Certificate from "@/components/student/Certificate";

export default {
  name: "StudentCertificates",
  data() {
    return {
      showComponent: false,
      showLoader: false,
      showBackground: false,
      showCertificate: false,
      selectedCertificate: {},
      client: {},
    };
  },
  components: {
    Header,
    Footer,
    Certificate,
    CertificatesTable,
  },
  async created() {
    if ((await this.authenticateClient("/sign-in", false)) == false)
      this.$router.push({
        path: "/sign-in",
      });
    this.client = this.$store.state.client.client;

    this.showComponent = true;
  },
  methods: {
    showCertificateModal(certificate) {
      this.selectedCertificate = certificate;
      this.showCertificate = true;
    },

    hideCertificateModal() {
      this.showCertificate = false;
    },
  },
};
</script>
  
<style scoped lang="scss">
.client-dashboard-wrapper {
  background: lighten($lightWhiteColor, 5%);
}

.welcome-message {
  padding: 1.5rem 0 2rem 0;
  text-align: center;

  .welcome-image {
    display: block;
    font-size: 3.5rem;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }

  h3 {
    font-size: 2rem;
    font-family: $headingFont;
    margin-bottom: 0rem;
  }
  p {
    color: $greyColor;
    font-size: $mediumFontSize;
    font-size: 1.25rem;
    font-weight: 300;
  }
}

.client-badge {
  position: fixed;
  display: inline-block;
  vertical-align: middle;
  width: 6rem;
  height: 6rem;
  bottom: 2rem;
  left: 3rem;
  opacity: 0.75;
}

.client-badge img {
  display: block;
  width: 80%;
  height: auto;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

img.badge-text {
  position: absolute;
  transform: rotate(0);
  top: 0;
  left: -2.5%;
  width: 105%;
  height: auto;
  transform-origin: center center;
  animation-name: rotateBadge;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes rotateBadge {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: $mobileWidth) {
  .welcome-message {
    h3 {
      font-size: 1.5rem;
      margin-bottom: -0.25rem;
      margin-top: 0.5rem;
    }
  }

  .client-plan {
    width: calc(100% - 3.5rem);
    margin: 0 0.75rem 1rem;
  }

  .client-badge {
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: -8rem;
    transform: none;
    margin: auto;
    display: block;
  }
}
</style>