import Vue from "vue";
import App from "@/App.vue";
import store from "@/store";
import VueYoutube from "vue-youtube";
import "@/filters";

//Importing the main routes for the App
import router from "@/router";

//Importing Master CSS
import "@/assets/styles/_reset.css";
import "@/assets/styles/_global.scss";
import "@/assets/styles/_framework.scss";

//Importing Mixin Functions
import GlobalFunctions from "@/mixins/Functions";
Vue.mixin(GlobalFunctions);

//Adding Vue Headful for custom headers
import vueHeadful from "vue-headful";
Vue.component("vue-headful", vueHeadful);

//Adding Font Awesome for icon picker
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component("font-awesome-icon", FontAwesomeIcon);

//Add Font Awesome Icon Picker
import VueIconPicker from "vue-icon-picker";
Vue.component("vue-icon-loader", VueIconPicker);

//Adding YouTube functionality
Vue.use(VueYoutube);

//Importing Font Awesome icons
import Unicon from "vue-unicons/dist/vue-unicons-vue2.umd";
import {
  uniTrashAlt,
  uniEllipsisH,
  uniPlus,
  uniMinus,
  uniAngleRightB,
  uniAngleDown,
  uniTimes,
  uniCheck,
  uniAnalysis,
  uniUsersAlt,
  uniUser,
  uniHeartbeat,
  uniUtensils,
  uniImages,
  uniSetting,
  uniInvoice,
  uniTransaction,
  uniCalendarAlt,
  uniAngleLeftB,
  uniPen,
  uniCopyAlt,
  uniDraggabledots,
  uniFilePlus,
  uniImport,
  uniVideo,
  uniChartLine,
  uniBars,
  uniDumbbell,
  uniStar,
  uniBoltAlt,
  uniLink,
  uniMoon,
  uniPricetagAlt,
  uniTrophy,
  uniBrightnessEmpty,
  uniFire,
  uniSpinnerAlt,
  uniMultiply,
  uniVectorSquare,
  uniCropAltRotateRight,
  uniCropAlt,
  uniPanoramaHAlt,
  uniPaperclip,
  uniSchedule,
  uniEye,
  uniQuestion,
  uniSave,
  uniCommentAlt,
  uniPhone,
  uniEnvelope,
  uniMoneyInsert,
  uniEstate,
  uniAnalytics,
  uniLock,
  uniPlay,
  uniUnlockAlt,
  uniPercentage,
  uniPlayCircle,
  uniLayerGroup,
  uniAward,
} from "vue-unicons/dist/icons";
Unicon.add([
  uniTrashAlt,
  uniEllipsisH,
  uniPlus,
  uniMinus,
  uniAngleRightB,
  uniAngleDown,
  uniAngleLeftB,
  uniAngleDown,
  uniTimes,
  uniCheck,
  uniAnalysis,
  uniUser,
  uniUsersAlt,
  uniHeartbeat,
  uniUtensils,
  uniImages,
  uniSetting,
  uniInvoice,
  uniTransaction,
  uniCalendarAlt,
  uniPen,
  uniCopyAlt,
  uniDraggabledots,
  uniFilePlus,
  uniImport,
  uniVideo,
  uniChartLine,
  uniBars,
  uniDumbbell,
  uniStar,
  uniBoltAlt,
  uniLink,
  uniMoon,
  uniPricetagAlt,
  uniTrophy,
  uniBrightnessEmpty,
  uniCopyAlt,
  uniFire,
  uniSpinnerAlt,
  uniUsersAlt,
  uniMultiply,
  uniVectorSquare,
  uniPanoramaHAlt,
  uniCropAltRotateRight,
  uniCropAlt,
  uniPaperclip,
  uniSchedule,
  uniEye,
  uniQuestion,
  uniSave,
  uniCommentAlt,
  uniPhone,
  uniEnvelope,
  uniMoneyInsert,
  uniEstate,
  uniAnalytics,
  uniLock,
  uniPlay,
  uniUnlockAlt,
  uniEye,
  uniPercentage,
  uniPlayCircle,
  uniLayerGroup,
  uniAward
]);
Vue.use(Unicon);

//Adding YouTube player
import VueYouTubeEmbed from "vue-youtube-embed";
Vue.use(VueYouTubeEmbed);

//Importing moment.js for time based functions
import moment from "moment-timezone";
Vue.prototype.moment = moment;

Vue.config.productionTip = false;
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
