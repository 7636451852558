import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        coach: {
            coach: {},
            loggedIn: false
        },
        client: {
            client: {},
            loggedIn: false
        },
    },
    mutations: {
        updateCoach(state, fields) {
            state.coach.coach = fields.coach;
            state.coach.loggedIn = fields.loggedIn;
        },
        updateClient(state, fields) {
            state.client.client = fields.client;
            state.client.loggedIn = fields.loggedIn;
        },
    },
});