<template>
  <div>
    <h2 class="title">Overall Monthly Insights</h2>
    <div class="bookings-summary-wrapper">
      <div class="bookings-stat-block">
        <div class="bookings-stat-wrapper">
          <div class="bookings-stat">
            <unicon name="user"></unicon>
            <div class="booking-stat-info">
              <em>{{ totalSales }}</em>
              <label>Bookings</label>
            </div>
          </div>
          <div class="bookings-stat">
            <unicon name="money-insert"></unicon>
            <div class="booking-stat-info">
              <em
                ><span>{{ getCoachCurrency() }}</span
                >{{ parseInt(totalAmount).toLocaleString("en-IN") }}</em
              >
              <label>Monthly Earnings</label>
            </div>
          </div>
          <div class="bookings-stat">
            <unicon name="eye"></unicon>
            <div class="booking-stat-info">
              <em>{{ parseInt(totalViews).toLocaleString("en-IN") }}</em>
              <label>Total Views</label>
            </div>
          </div>
          <div class="bookings-stat">
            <unicon name="users-alt"></unicon>
            <div class="booking-stat-info">
              <em>{{ parseInt(uniqueViews).toLocaleString("en-IN") }}</em>
              <label>Unique Visitors</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MonthlyInsights",
  props: {
    totalSales: Number,
    totalAmount: Number,
    totalViews: Number,
    uniqueViews: Number,
  },
};
</script>

<style scoped lang="scss">
.title {
  opacity: 0.75;
  font-size: $mediumFontSize;
  font-weight: $mediumFontWeight;
  margin: 2rem 0 1rem;
}

.bookings-summary-wrapper {
  display: flex;
  flex-direction: row;
  align-items: bottom;
  margin: 1rem 0 2rem;
}

.label-small {
  display: block;
  font-size: $smallestFontSize;
  color: $greyColor;
  margin-bottom: 1rem;

  b {
    font-weight: $mediumFontWeight;
  }
}

.bookings-stat-wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.bookings-stat {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 3rem;
  background-color: $whiteColor;
  border: 1px solid darken($whiteColor, 10%);
  padding: 1rem 1.5rem;
  border-radius: 1rem;

  em {
    display: block;
    font-size: 1.5rem;
    font-weight: $mediumFontWeight;
    color: $blackColor;
    margin-bottom: 0.5rem;

    span {
      font-size: $largeFontSize;
    }
  }

  label {
    font-size: $normalFontSize;
    font-weight: $mediumFontWeight;
    color: $greyColor;
  }

  .unicon {
    margin-right: 1rem;
    /deep/ svg {
      background: lighten($greenColor, 25%);
      fill: darken($darkGreenColor, 10%);
      width: 1rem;
      height: auto;
      padding: 0.5rem;
      border: 1px solid lighten($greenColor, 20%);
      border-radius: 0.5rem;
    }
  }
}

//Mobile Styles
@media screen and (max-width: $mobileWidth) {
  .bookings-summary-wrapper {
    display: block;
  }

  .bookings-stat-block {
    margin-left: -1rem;
    width: calc(100% + 2rem);
  }

  .bookings-stat-wrapper {
    display: block;
    white-space: nowrap;
    overflow-x: scroll;
    padding: 0 1rem;
    padding-bottom: 1rem;

    .bookings-stat {
      white-space: normal;
      display: inline-block;
      margin-right: 1rem;

      .booking-stat-info {
        display: inline-block;
        vertical-align: top;
      }

      label {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>