<template>
  <div class="field-wrapper">
    <div
      class="field-country-telephone-item"
      :class="{ error: field.hasError }"
    >
      <VuePhoneNumberInput
        v-model="phone"
        v-bind="props"
        :no-example="true"
        :required="field.required"
        :disabled="field.readOnly != undefined ? field.readOnly : false"
        @phone-number-focused="field.hasError = false"
        @update="fieldChanged"
      />
    </div>
  </div>
</template>

<script>
//Importing libraries
import _ from "lodash";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  name: "CountryTelephoneField",
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fieldInit: false,
      initPhone: {},
      phone: null,
      props: {
        noExample: true,
        defaultCountryCode: null,
        translations: {
          countrySelectorLabel: "Country code",
          countrySelectorError: "Error",
          phoneNumberLabel: "Enter your phone",
        },
      },
    };
  },
  components: {
    VuePhoneNumberInput,
  },
  created() {
    if (!_.isEmpty(this.field.value)) {
      this.phone = this.field.value.number;
      this.props.defaultCountryCode = this.field.value.countryCode;
    } else {
        delete this.props.defaultCountryCode;
        this.props.fetchCountry= true;
    }
  },
  methods: {
    fieldChanged(phone) {
      if (this.fieldInit) {
        this.field.value = {
          countryCode: phone.countryCode,
          countryCallingCode: phone.countryCallingCode,
          number: phone.nationalNumber,
        };
        this.$emit("fieldChanged", phone.isValid);
      }
      this.fieldInit = true;
    },
  },
};
</script>

<style scoped lang="scss">
input[readonly] {
  cursor: default;
  border: 1px solid #e1dfd9;
}

/deep/ {
  .country-selector,
  .input-tel {
    height: auto;
    min-height: auto;
  }

  .input-tel__label {
    top: 6px;
    left: 32px;
  }
  .country-selector__input,
  .input-tel__input {
    font-family: $bodyFont;
    transition: all $transitionSpeed;
    display: block;
    font-size: 1rem;
    font-weight: 500;
    outline: none;
    height: auto;
    color: darken($purpleColor, 25%);
    background-color: #fbfbfb;
    padding: 0.75rem 1.15rem;
    border: 1px solid #e1dfd9;
    border-radius: 0.75rem !important;
    -webkit-appearance: none;
    margin-left: 1rem;
    width: calc(100% - 1rem);

    &:not(.no-country-selector) {
      border-top-left-radius: 0.75rem !important;
      border-bottom-left-radius: 0.75rem !important;
    }

    &::placeholder {
      font-size: $normalFontSize;
      font-weight: 400;
      color: #9b9384;
    }
  }

  .country-selector__input {
    margin-left: 0;
    width: calc(100%);
  }

  .country-selector__label {
    top: 6px;
  }

  .country-selector__country-flag,
  .country-selector__toggle {
    top: 50%;
    transform: translateY(-50%);
  }

  .country-selector__country-flag {
    margin-top: 4px;
  }
}

.error /deep/ {
  .country-selector__input,
  .input-tel__input {
    border-color: $redColor;
  }
}
</style>