<template>
  <header :class="{ show: showHeaderMenu }" v-click-outside="hideBackground">
    <div class="rel-wrapper header-block-wrapper">
      <router-link to="/dashboard" class="logo"
        ><img src="@/assets/images/logo.png" alt="Logo"
      /></router-link>

      <!--START: Coach Hero-->
      <div class="user-wrapper block-wrapper">
        <div class="profile-image-wrapper">
          <ProfileAvatar
            v-if="coach.logoURL == null"
            :name="coach.displayName"
          ></ProfileAvatar>
          <div v-else class="profile-picture-image">
            <img :src="coach.logoURL" alt="Profile Picture" />
          </div>
        </div>
      </div>
      <!--END: Coach Hero-->

      <div class="rel-wrapper">
        <unicon class="menu-icon" name="bars" @click="showMenu" />
      </div>

      <!--START: Menu Main -->
      <ul class="main-menu" :class="{ show: showHeaderMenu }">
        <li class="site-menu-list-item">
          <span @click="logoutUser()" class="signout-link">Sign Out</span>
        </li>
      </ul>
      <!--END: Menu Main -->
    </div>
    <div
      class="bg-overlay"
      :class="{ show: showHeaderMenu }"
      @click="hideBackground()"
    ></div>
  </header>
</template>

<script>
//Importing libraries
import vClickOutside from "v-click-outside";

//Import components
import ProfileAvatar from "@/components/misc/ProfileAvatar";

export default {
  name: "CoachHeader",
  data() {
    return {
      showHeaderMenu: false,
      status: {
        show: false,
        isSuccess: true,
        successMessage: "Link copied!",
      },
    };
  },
  components: {
    ProfileAvatar,
  },
  props: {
    coach: {
      type: Object,
      required: true,
    },
  },
  watch: {
    $route() {
      this.hideBackground();
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    currentRouteName() {
      if (this.$route.name != undefined) return this.$route.name.toLowerCase();
      else return false;
    },
  },
  methods: {
    showMenu() {
      this.showHeaderMenu = true;
    },
    hideBackground() {
      this.showHeaderMenu = false;
    },
  },
};
</script>

<style scoped lang="scss">
header {
  background-color: #131008;
  position: fixed;
  top: 0.75rem;
  left: 0.75rem;
  padding: 2rem 1.5rem;
  width: calc(100% - 4.5rem);
  border-bottom: 1px solid #f5f2e9;
  box-shadow: 0 0 0.5rem -0.15rem rgba(223, 216, 195, 1);
  border-radius: 0.75rem;
  z-index: 1;

  &.show {
    z-index: 2000000001;
  }
}

.bg-overlay {
  display: none;

  &.show {
    display: block;
  }
}

.logo {
  @include vertical-center-align;
  text-decoration: none;
  border: none;
  img {
    display: block;
    left: 0;
    width: 8rem;
  }
}

.main-menu {
  position: fixed;
  bottom: 0.5rem;
  left: 0.5rem;
  width: calc(100% - 1rem);
  margin: auto;
  padding: 1rem 0;
  text-align: center;
  border-radius: 1rem;
  background-color: $whiteColor;
  transform: translateY(100vh);
  transition: all 0.3s;
  z-index: 101;

  &.show {
    transform: translateY(0);
  }
}
.menu-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  /deep/ svg {
    fill: $whiteColor;
    height: auto;
    width: $extraLargeFontSize;
    opacity: $lightOpacity;
  }
}

.site-menu-list-item a,
.site-menu-list-item span {
  font-size: 1.1rem;
}

.signout-link {
  color: $redColor;
}

.user-wrapper {
  display: none;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  margin-right: 2rem;
}

.profile-image-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.profile-picture-image {
  position: relative;
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  overflow: hidden;
  border-radius: 50%;
  background-color: #131008;
  border: 1px solid $buttonColor;
  padding: 2px;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: calc(2rem - 2px);
  }
}
.coach-name {
  font-size: $smallFontSize;
  color: $whiteColor;
  font-weight: $normalFontWeight;
  opacity: $lightOpacity;
}

.copy-wrapper {
  position: relative;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  transition: all 0.3s;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  background-color: #353a73;
  color: #bbbee6;
  font-size: $smallerFontSize;

  .coach-link {
    display: inline-block;
    em {
      font-size: $smallerFontSize;
      font-weight: $mediumFontWeight;
    }
  }

  .unicon {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.5rem;

    /deep/ svg {
      fill: #bbbee6;
      height: auto;
      width: 1rem;
    }
  }

  &:hover {
    color: $whiteColor;
    background-color: $purpleColor;
  }
}

.btn-preview {
  border-color: #a0a5d6;
  color: #a0a5d6;
  margin-right: 1rem;
  opacity: $lightOpacity;
}

.tooltip-menu {
  right: -0.75rem;
  top: calc(100% + 2rem);
}

.subscription-btn {
  margin-right: 1rem;
}

@media screen and (min-width: $mobileWidth) {
  header {
    top: 0;
    left: 0;
    z-index: 10;
    border-radius: 0;
    padding: 0.85rem 2rem;
    width: calc(100% - 4rem);
  }

  .tooltip-menu {
    top: calc(100% + 1rem);
  }

  .header-block-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .user-wrapper {
    display: flex;
  }

  .link-message {
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }

  .menu-icon,
  .logo {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }

  .menu-icon /deep/ svg {
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .main-menu {
    display: none;
    position: absolute;
    width: auto;
    bottom: auto;
    left: auto;
    right: 0;
    top: 2.5rem;
    transform: none;
    text-align: left;
    border: 1px solid #ececec;
    box-shadow: 0 0 0.7rem -0.15rem rgba(179, 179, 179, 0.25);

    .signout-link,
    a {
      font-size: $smallFontSize;
    }

    &.show {
      display: block;
    }
  }

  .bg-overlay {
    display: none !important;
  }
}

/deep/ .profile-image-wrapper {
  margin-left: 1rem;

  .avatar-wrapper {
    height: 2.25rem;
    width: 2.25rem;
  }
}
</style>