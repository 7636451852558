<template>
  <div>
    <ul class="grid-table">
      <li class="grid-header">
        <div class="grid-item">Details</div>
        <div class="grid-item">Students</div>
        <div class="grid-item">Access End Date</div>
        <div class="grid-item">Status</div>
      </li>
      <li
        v-for="course in courses"
        :key="course.id"
        class="grid-row"
        @click="gotoCourse(course._id)"
      >
        <div class="grid-item course-media">
          <div>
            <h3 class="course-title">{{ course.title }}</h3>
          </div>
        </div>

        <div class="grid-item">
          <span class="course-student-count"
            >{{ course.students.length }} students</span
          >
        </div>

        <div class="grid-item">
          <span class="course-end-date">{{
            moment(course.accessSettings.endDate).format("DD MMMM YYYY")
          }}</span>
        </div>

        <div class="grid-item">
          <span
            class="status"
            :class="{ active: course.accessSettings.isActive }"
            >{{
              course.accessSettings.isActive ? "Published" : "Inactive"
            }}</span
          >
        </div>

        <div class="grid-item course-actions">
          <button
            class="btn btn-icon btn-delete-client"
            @click.prevent="showDeleteModal(course._id, course.title)"
          >
            <unicon name="trash-alt"></unicon>
          </button>
          <button class="btn btn-icon">
            <unicon name="angle-right-b"></unicon>
          </button>
        </div>
      </li>
    </ul>

    <span class="course-count">Showing {{ courses.length }} video courses</span>

    <ConfirmationModal
      :fields="deleteFields"
      @primaryEvent="deleteCourse"
      @secondaryEvent="closeModal"
    ></ConfirmationModal>
    <LineLoader :showLoader="showLoader"></LineLoader>
  </div>
</template>
  
  <script>
//Import service to get course details
import LiveCourseService from "@/controllers/LiveCourseService";

//Importing Components
import ConfirmationModal from "@/components/modals/ConfirmationModal";
import LineLoader from "@/components/loaders/LineLoader";

export default {
  name: "CoursesTable",
  props: {
    courses: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showLoader: false,
      groupIndex: -1,
      deleteCourseID: null,
      deleteFields: {
        show: false,
        title: null,
        type: "delete",
        message: "You can't undo this action",
        primaryBtn: {
          title: "Yes. Delete",
        },
        secondaryBtn: {
          title: "Cancel",
        },
      },
    };
  },
  components: {
    ConfirmationModal,
    LineLoader,
  },
  methods: {
    gotoCourse(courseID) {
      this.$router.push({
        path: `/dashboard/live-course-builder/${courseID}/details`,
      });
    },

    showDeleteModal(courseID, courseTitle) {
      this.$parent.showBackground = true;
      this.deleteCourseID = courseID;
      this.deleteFields.show = true;
      this.deleteFields.title = `Delete ${courseTitle}?`;
    },

    async deleteCourse() {
      this.showLoader = true;
      await LiveCourseService.DeleteCourse(this.deleteCourseID);

      this.$parent.courses.splice(
        this.courses.findIndex(({ _id }) => _id == this.deleteCourseID),
        1
      );
      this.showLoader = false;
      this.closeModal();
    },

    closeModal() {
      this.deleteFields.show = false;
      this.$parent.showBackground = false;
    },
  },
};
</script>
  
  <style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.line-loader {
  bottom: 0;
}

.grid-item {
  align-self: center;

  &:nth-child(1) {
    width: calc(100% - 37rem);
  }
  &:nth-child(2) {
    width: 10rem;
  }
  &:nth-child(3) {
    width: 10rem;
  }
  &:nth-child(4) {
    width: 10rem;
    text-align: right;
  }
  &:nth-child(5) {
    width: 7rem;
  }
}

.course-title {
  font-size: $smallFontSize;
  font-weight: $mediumFontWeight;
  width: 85%;
}

.course-actions {
  text-align: right;
}

.btn-assign-clients {
  margin-right: 1rem;
}

.btn-delete-client {
  @include transition;

  &:hover {
    background-color: lighten($redColor, 30%);

    /deep/ .unicon svg {
      fill: darken($redColor, 10%);
    }
  }
}
.course-media {
  display: flex;
  flex-direction: row;
  align-items: center;
  .cover-img {
    border-radius: 0.5rem;
    width: 5rem;
    margin-right: 1.5rem;
  }

  div {
    flex: 1;
  }
}

.course-student-count {
  display: table;
  font-size: $smallerFontSize;
  font-weight: $mediumFontWeight;
  padding: 0.5rem 0.55rem;
  line-height: 0.7rem;
  border-radius: 0.5rem;
  background-color: #ececec;
  color: #858585;
  margin-top: 0.25rem;
}

.course-end-date {
  font-size: $smallerFontSize;
  font-weight: $mediumFontWeight;
  opacity: $lightOpacity;
}


.course-count {
  display: block;
  font-size: $smallerFontSize;
  opacity: $mediumOpacity;
  color: $greyColor;
  margin-top: 2rem;
}

.status {
  font-size: $smallestFontSize;
  font-weight: $mediumFontWeight;
  background-color: darken($whiteColor, 10%);
  color: darken($whiteColor, 60%);
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-align: center;
  width: 5rem;
  &.active {
    background-color: #e2ffe2;
    color: #098925;
    border: 1px solid #baefb9;
  }
}
</style>